import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { withTheme } from '@emotion/react'

export const NavBar = withTheme(
    styled.nav(({ theme }: { theme: AdminTheme }) => ({
        width: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        flex: '0 0 auto',
        borderBottom: `1px solid ${theme.BorderPrimary}`,
        display: 'flex',
        padding: `0 ${theme.Spacing(2)}`,
        marginBottom: theme.Spacing(2),
        gap: `${theme.Spacing(4)}`,
        '&.inline-nav': {
            marginBottom: 0,
        },
        [theme.BreakPoint]: {
            padding: `0 ${theme.Spacing(8)}`,
            marginBottom: theme.Spacing(4),
            '&.inline-nav': {
                padding: `0 ${theme.Spacing(3)}`,
            },
        },
    }))
)
