import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAction2 } from '../../store/utils/create-action'
import { AdminActionTypes } from '../../store/admin/actions'
import { AdminState } from '../../store/admin/types'

const ADMIN_MENU_COLLAPSED_KEY = 'admin-menu-collapsed'

export function useSetAdminMenuCollapsed() {
    const dispatch = useDispatch()
    return useCallback(
        (collapsed: boolean) => {
            localStorage.setItem(ADMIN_MENU_COLLAPSED_KEY, `${collapsed}`)
            dispatch(createAction2({ type: AdminActionTypes.SET_ADMIN_MENU_COLLAPSED, payload: collapsed }))
        },
        [dispatch]
    )
}

export function useAdminMenuCollapsed() {
    return useSelector<{ admin: AdminState }>((state) => state.admin.adminMenuCollapsed) as boolean
}

export function isSavedAdminMenuCollapsed() {
    return localStorage.getItem(ADMIN_MENU_COLLAPSED_KEY) === 'true'
}
