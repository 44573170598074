import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LoadingScreen } from '../../../components/ui-kit/comopnents/LoadingScreen'
import { SuccessScreen } from '../components/SuccessScreen'
import {
    BookingPageContainer,
    BookingPageFooterContainer,
    BookingPageOuterContainer,
    BookingPageWithStickyFooter,
    ResultPageCard,
    ResultPageCards,
    ResultPageContainer,
} from '../components/BookingPageLayout'
import { Callout } from '../../../components/ui-kit/comopnents/Callout'
import { Trans } from 'react-i18next'
import { Icon } from '../../../components/ui-kit/comopnents/Icon'
import { faExclamationCircle, faShoppingBasket, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Flex } from '../../../components/helpers/Flex'
import { useToast } from '../../../hooks/use-toast'
import { useGetErrorMessage } from '../../../services/getErrorMessage'
import { Notifications } from '../../admin/Notifications'
import { useTheme } from '../../../theme/ThemeProvider'
import { BusinessPublicDto } from '../../../bookingpage/types/business'
import { SuccessAnimation } from '../components/SuccessAnimation'
import ErrorAnimation from '../../../icons/lottie/PaymentFailedAnimation.lottie'
import { faHourglass } from '@fortawesome/free-regular-svg-icons'
import { Currency } from '../../../components/helpers/Currency'
import i18n, { changeLanguage } from '../../../i18n'
import { PrimaryButton, PrimaryButtonNavLink } from '../../../components/ui-kit/button/primary'
import { TextButton } from '../../../components/ui-kit/button/TextButton'
import { Counter } from '../../../components/ui-kit/comopnents/Counter'
import { useHistory } from 'react-router-dom'
import { Column } from '../../../components/admin/layout/Column'
import { SimplePayResultStatus } from '../../../common/types/simplepay'
import styled from '@emotion/styled'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'

const ErrorHeader = withTheme(
    styled.b(({ theme }: { theme: AdminTheme }) => ({
        [theme.BreakPoint]: {
            marginTop: theme.Spacing(0.5),
        },
    }))
)

export const SimplePayReturnPage: FC = () => {
    const [params, setParams] = useState<{ r: string; s: string }>()
    const [ready, setReady] = useState(false)
    const [languageReady, setLanguageReady] = useState(false)

    const [cancelling, setCancelling] = useState(false)
    const [retrying, setRetrying] = useState(false)

    const loading = cancelling || retrying

    const [details, setDetails] = useState<{
        status: string
        errorCode: number
        transactionId: string
        googleCalendarLink: string
        business: BusinessPublicDto
        approvalRequired: boolean
        clientEmail: string
        total: number
        currency: string
        timeout: string | null
        canRetry: boolean
    }>()

    const businessUrl = useMemo(() => {
        return details ? `/book/${details.business.urlHandle}` : undefined
    }, [details])

    const toast = useToast()
    const getErrorMessage = useGetErrorMessage()
    const history = useHistory()
    const firstRef = useRef(true)

    const cancelBooking = useCallback(async () => {
        setCancelling(true)
        const response = await fetch('/api/simplepay/cancelBooking', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                r: params?.r,
                s: params?.s,
            }),
        })
        setCancelling(false)
        if (response.ok) {
            history.push(businessUrl)
        } else {
            toast.error(await getErrorMessage(response))
        }
    }, [businessUrl, getErrorMessage, history, params?.r, params?.s, toast])

    const retryPayment = useCallback(async () => {
        setRetrying(true)
        const response = await fetch('/api/simplepay/retryPayment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                r: params?.r,
                s: params?.s,
            }),
        })
        setRetrying(false)
        if (response.ok) {
            const data = await response.json()
            window.location.href = data.paymentUrl
        } else {
            toast.error(await getErrorMessage(response))
        }
    }, [getErrorMessage, params?.r, params?.s, toast])

    const getDetails = useCallback(
        async (r: string, s: string) => {
            if (languageReady) {
                try {
                    const query = new URLSearchParams()
                    query.append('r', r)
                    query.append('s', s)
                    query.append('q', firstRef.current ? 'false' : 'true')
                    const response = await fetch(`/api/simplepay/checkpayment?${query.toString()}`)
                    if (!response.ok) {
                        throw new Error(`Error while loading data: ${response.status} ${await response.text()}`)
                    }
                    var data = await response.json()
                    setDetails(data)
                    setReady(true)
                    firstRef.current = false
                } catch (e) {
                    toast.error(await getErrorMessage(e))
                }
            }
        },
        [getErrorMessage, languageReady, toast]
    )

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        Promise.all([changeLanguage(params.get('lang') || 'hu'), i18n.loadNamespaces('bookingpage')]).then(() =>
            setLanguageReady(true)
        )
    }, [])

    const init = useCallback(
        async (r: string, s: string) => {
            await getDetails(r, s)
        },
        [getDetails]
    )

    useTheme(details?.business?.theme || 'minup', details?.business?.customTheme)

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const r = params.get('r') || ''
        const s = params.get('s') || ''
        setParams({
            r,
            s,
        })
        if (!r || !s) {
            setReady(true)
        } else {
            init(r, s)
        }
    }, [getDetails, init])

    useEffect(() => {
        if (details?.status === SimplePayResultStatus.Pending && params?.r && params?.s) {
            const timeout = setTimeout(() => getDetails(params.r, params.s), 3000)
            return () => clearTimeout(timeout)
        }
    }, [details, getDetails, params?.r, params?.s])

    return languageReady ? (
        <BookingPageOuterContainer>
            <BookingPageContainer>
                <BookingPageWithStickyFooter>
                    {ready && details?.status !== SimplePayResultStatus.Pending ? (
                        !params?.r || !params?.s || !details ? (
                            <Callout variant="Secondary4">
                                <Icon className="icon" icon={faExclamationCircle} />
                                <Trans ns="bookingpage">Invalid payment result parameters</Trans>
                            </Callout>
                        ) : details?.status === SimplePayResultStatus.Success ? (
                            <SuccessScreen
                                googleCalendarLink={details.googleCalendarLink}
                                clientEmail={details.clientEmail}
                                business={details.business}
                                canCancelUntil={details.business.canCancelUntil}
                                approvalRequired={details.approvalRequired}
                                transactionMessage={
                                    <>
                                        <span>
                                            <Trans ns="bookingpage">Successful transaction.</Trans>
                                        </span>{' '}
                                        <span>
                                            <Trans ns="bookingpage">
                                                SimplePay transaction ID:{' '}
                                                <b>{{ transactionId: details.transactionId }}</b>
                                            </Trans>
                                        </span>
                                    </>
                                }
                            />
                        ) : (
                            <>
                                <ResultPageContainer grow={1} justifyContent="flex-start">
                                    <SuccessAnimation src={ErrorAnimation} />
                                    <h2>
                                        <Trans ns="bookingpage" i18nKey={`simplepay_error_title_${details.status}`} />
                                    </h2>
                                    <ResultPageCards>
                                        <ResultPageCard variant="Danger" icon={faExclamationCircle}>
                                            {details?.status === SimplePayResultStatus.Fail ? (
                                                <Column spacing={1}>
                                                    <ErrorHeader>
                                                        <Trans ns="bookingpage">Transaction failed</Trans>
                                                    </ErrorHeader>
                                                    <span>
                                                        <Trans ns="bookingpage">
                                                            SimplePay transaction ID:{' '}
                                                            <b>{{ transactionId: details?.transactionId }}</b>
                                                        </Trans>
                                                    </span>
                                                    <span>
                                                        <Trans ns="bookingpage">
                                                            Please check the values you entered during the transaction.
                                                            If all the values are correct, please contact your bank for
                                                            further information about the reason for rejecting the
                                                            transaction.
                                                        </Trans>
                                                    </span>
                                                </Column>
                                            ) : details?.status === SimplePayResultStatus.Cancel ? (
                                                <Trans ns="bookingpage">Transaction canceled</Trans>
                                            ) : details.status === SimplePayResultStatus.Timeout ? (
                                                <Trans ns="bookingpage">Transaction timeout</Trans>
                                            ) : null}
                                            {/* {getSimplePayErrorMessage(details.errorCode)} */}
                                        </ResultPageCard>
                                        {details.canRetry ? (
                                            <>
                                                <ResultPageCard icon={faShoppingBasket}>
                                                    <Trans ns="bookingpage">
                                                        Total amount:{' '}
                                                        <b>
                                                            <Currency
                                                                value={details.total}
                                                                languageCode={details.business.bookingPageLanguage}
                                                                currency={details.currency}
                                                            />
                                                        </b>
                                                    </Trans>
                                                </ResultPageCard>
                                                {details.timeout ? (
                                                    <ResultPageCard icon={faHourglass}>
                                                        <Trans ns="bookingpage">
                                                            We can hold your appointment for this long:{' '}
                                                            <b>
                                                                <Counter timeout={details.timeout} />
                                                            </b>
                                                            .
                                                        </Trans>
                                                    </ResultPageCard>
                                                ) : null}
                                            </>
                                        ) : null}
                                    </ResultPageCards>
                                </ResultPageContainer>
                                <BookingPageFooterContainer>
                                    {details.canRetry ? (
                                        <Flex gap={1} grow={1} justifyContent="space-between">
                                            <TextButton
                                                className="neutral"
                                                type="button"
                                                disabled={loading}
                                                onClick={cancelBooking}
                                            >
                                                {cancelling ? <Icon icon={faSpinner} spin /> : null}
                                                <Trans ns="bookingpage">Cancel booking</Trans>
                                            </TextButton>
                                            <PrimaryButton type="button" disabled={loading} onClick={retryPayment}>
                                                {retrying ? <Icon icon={faSpinner} spin /> : null}
                                                <Trans ns="bookingpage">Try payment again</Trans>
                                            </PrimaryButton>
                                        </Flex>
                                    ) : (
                                        <Flex grow={1} alignItems="stretch" flexDirection="column">
                                            <PrimaryButtonNavLink to={businessUrl}>
                                                <Trans ns="bookingpage">Back to the main page</Trans>
                                            </PrimaryButtonNavLink>
                                        </Flex>
                                    )}
                                </BookingPageFooterContainer>
                            </>
                        )
                    ) : (
                        <ResultPageContainer>
                            <Column grow centered>
                                <h2>
                                    <Trans ns="bookingpage">Processing payment</Trans>
                                </h2>
                                <p className="text-centered">
                                    <Trans ns="bookingpage">
                                        We are checking the results of your transaction. Please do not close this tab.
                                        The page will automatically refresh once the transaction is complete.
                                    </Trans>
                                </p>
                                <LoadingScreen inline />
                                <PrimaryButton type="button" onClick={() => window.location.reload()}>
                                    <Trans ns="bookingpage">Refresh page</Trans>
                                </PrimaryButton>
                            </Column>
                        </ResultPageContainer>
                    )}
                </BookingPageWithStickyFooter>
            </BookingPageContainer>
            <Notifications />
        </BookingPageOuterContainer>
    ) : (
        <LoadingScreen />
    )
}
