import {
    ComponentProps,
    FormEvent,
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PrimaryButton } from '../../../../components/ui-kit/button/primary'
import { Input } from '../../../../components/ui-kit/comopnents/Input'
import { InputGroup } from '../../../../components/ui-kit/comopnents/InputGroup'
import { useBusinessApi } from '../../../../hooks/apis/use-business-api'
import { AuthActionTypes } from '../../../../store/auth/actions'
import { createAction } from '../../../../store/utils/create-action'
import { LoadingScreenContext } from '../LoadingScreenContext'
import { WizardStepContent } from '../WizardStepContent'
import { WizardStepFooter } from '../WizardStepFooter'
import { InputPlaceholder } from '../../../../components/ui-kit/comopnents/InputPlaceholder'
import { normalizeHandle } from '../../../../services/normalizeHandle'
import { DontWorry } from '../DontWorry'
import { Flex } from '../../../../components/helpers/Flex'
import { WizardProgressContext } from '../WizardPage'
import { Trans, useTranslation } from 'react-i18next'
import { useAuthorizedApi } from '../../../../hooks/apis/use-authorized-api'
import { ErrorMessage } from '../../../../components/ui-kit/comopnents/ErrorMessage'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { AVAILABLE_LANGUAGES, LanguageCodes, LanguageFlags, LanguageNames } from '../../../../i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useBusiness } from '../../../../hooks/use-business'
import { useToast } from '../../../../hooks/use-toast'
import { captureException } from '../../../../appInsights'
import { Column } from '../../../../components/admin/layout/Column'
import { TextButton } from '../../../../components/ui-kit/button/TextButton'
import { useModal } from '../../../../hooks/use-modal'
import { getErrorMessage } from '../../../../services/getErrorMessage'
import { getLockInstance } from '../../../../services/lock'
import { AutocompleteInput } from '../../../../components/ui-kit/comopnents/AutocompleteInput'
import { CountryCodeSelector } from '../../../../components/ui-kit/comopnents/CountryCodeSelector'
import { usePhoneNumberPlaceholders } from '../../../../hooks/use-phone-number-placeholders'

var timeout: NodeJS.Timeout | undefined = undefined

export const SETUP_IN_PROGRESS_KEY = 'setup_in_progress'

export const LanguageSelector: FunctionComponent<
    Omit<
        ComponentProps<typeof AutocompleteInput>,
        'options' | 'disableAutocomplete' | 'value' | 'onChange' | 'containerStyle'
    > & {
        languageCode: string
        onChange: (item: any) => void
    }
> = ({ languageCode, onChange, ...rest }) => {
    const languageOptions = useMemo(
        () =>
            AVAILABLE_LANGUAGES.map((lang) => ({
                label: (
                    <Flex justifyContent="flex-start" gap={1}>
                        {LanguageFlags[lang]}
                        <span>{LanguageNames[lang]}</span>
                    </Flex>
                ),
                value: lang,
            })),
        []
    )

    return (
        <AutocompleteInput
            {...rest}
            disableAutocomplete
            options={languageOptions as any}
            value={languageCode}
            onChange={(item: any) => {
                onChange(item.value)
            }}
            containerStyle={{ flexShrink: 0, flexGrow: 0 }}
            dropdownContainerStyle={{ width: 'max-content', right: 0 }}
        />
    )
}

export const Step1: FunctionComponent<{ languageCode: string; onChangeLanguage: (language: string) => void }> = ({
    languageCode,
    onChangeLanguage,
}) => {
    const business = useBusiness()
    const loader = useContext(LoadingScreenContext)
    const businessApi = useBusinessApi()
    const dispatch = useDispatch()
    const [countryCode, setCountryCode] = useState(business?.countryCode || 'HU')
    const [name, setName] = useState(business?.name || '')
    const [firstName, setFirstName] = useState(business?.firstName || '')
    const [lastName, setLastName] = useState(business?.lastName || '')
    const [phone, setPhone] = useState(business?.phone || '')
    const [urlHandle, setUrlHandle] = useState(business?.urlHandle || '')
    const [generateHandle, setGenerateHandle] = useState(true)
    const [generateName, setGenerateName] = useState(true)
    const history = useHistory()
    const progress = useContext(WizardProgressContext)
    const { t } = useTranslation('admin')
    const [checking, setChecking] = useState(false)
    const [urlValid, setUrlValid] = useState<boolean | undefined>(true)
    const { get } = useAuthorizedApi()
    const lastNameFirst = useMemo(() => languageCode === LanguageCodes.Hu, [languageCode])
    const toast = useToast()
    const phoneNumberPlaceholder = usePhoneNumberPlaceholders(countryCode)

    useEffect(() => {
        progress.setCurrentStep(1)
    }, [progress])

    const checkUrl = useCallback(
        (urlHandle) => {
            if (timeout) {
                clearTimeout(timeout)
            }
            timeout = setTimeout(async () => {
                setChecking(true)
                try {
                    const response = await get(
                        `/api/business/checkurlhandle?urlHandle=${encodeURIComponent(urlHandle)}`
                    )
                    setUrlValid(response.status === 200)
                } catch {
                    setUrlValid(false)
                }
                setChecking(false)
            }, 300)
        },
        [get]
    )

    const onNameChange = (e: FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
        if (generateHandle) {
            const newHandle = normalizeHandle(e.currentTarget.value)
            setUrlHandle(newHandle)
            checkUrl(newHandle)
        }
        if (e.currentTarget.value) {
            setGenerateName(false)
        } else {
            setGenerateName(true)
        }
    }

    const onFirstNameChange = (e: FormEvent<HTMLInputElement>) => {
        setFirstName(e.currentTarget.value)
        if (generateName) {
            const newName = lastNameFirst
                ? lastName + ' ' + e.currentTarget.value
                : e.currentTarget.value + ' ' + lastName
            setName(newName)
            const newHandle = normalizeHandle(newName)
            setUrlHandle(newHandle)
            checkUrl(newHandle)
        }
    }

    const onLastNameChange = (e: FormEvent<HTMLInputElement>) => {
        setLastName(e.currentTarget.value)
        if (generateName) {
            const newName = lastNameFirst
                ? e.currentTarget.value + ' ' + firstName
                : firstName + ' ' + e.currentTarget.value
            setName(newName)
            const newHandle = normalizeHandle(newName)
            setUrlHandle(newHandle)
            checkUrl(newHandle)
        }
    }

    const onHandleChange = (e: FormEvent<HTMLInputElement>) => {
        setUrlHandle(normalizeHandle(e.currentTarget.value, false))
        if (e.currentTarget.value) {
            setGenerateHandle(false)
        } else {
            setGenerateHandle(true)
        }
        checkUrl(e.currentTarget.value)
    }

    const onHandleBlur = (e: FormEvent<HTMLInputElement>) => {
        if (!urlHandle) {
            setUrlHandle(normalizeHandle(name))
        }
    }

    const onPhoneChange = (e: FormEvent<HTMLInputElement>) => {
        setPhone(e.currentTarget.value)
    }

    const submit = useCallback(
        async (e) => {
            e.preventDefault()
            if (!business) {
                return
            }
            try {
                loader.show(true)
                const response = await businessApi.create({
                    name,
                    firstName,
                    lastName,
                    urlHandle,
                    phone,
                    languageCode: languageCode,
                    timezone: business.timezone,
                    countryCode: countryCode,
                    currencyCode: business.currencyCode,
                })
                dispatch(createAction(AuthActionTypes.UPDATE_BUSINESS, response.data))
                loader.show(false)
                localStorage.setItem(SETUP_IN_PROGRESS_KEY, '1')
                history.push('/admin/wizard/2')
            } catch (e) {
                loader.show(false)
                toast.error(t('Something went wrong, please try again'))
                captureException(e)
            }
        },
        [
            business,
            loader,
            businessApi,
            name,
            firstName,
            lastName,
            urlHandle,
            phone,
            languageCode,
            countryCode,
            dispatch,
            history,
            toast,
            t,
        ]
    )

    const confirmation = useModal()
    const onCancel = useCallback(async () => {
        if (
            await confirmation.show({
                title: t('Cancel registration?'),
                text: t(
                    'If you cancel, we’ll not register a booking page for your business. Are you sure it’s want you want?'
                ),
                dismissable: true,
                primaryButtonClass: 'danger',
                primaryButtonText: (
                    <>
                        <span className="only-desktop">
                            <Trans ns="admin">Cancel registration</Trans>
                        </span>
                        <span className="only-mobile">
                            <Trans ns="admin">Cancel registration short_version</Trans>
                        </span>
                    </>
                ),
                cancelButtonText: t('Back'),
            })
        ) {
            try {
                await businessApi.cancelRegistration()
                const lock = await getLockInstance()
                lock.logout({
                    returnTo: 'https://minup.io/',
                })
            } catch (e) {
                toast.error(await getErrorMessage(e, t))
            }
        }
    }, [businessApi, confirmation, t, toast])

    return (
        <form onSubmit={submit}>
            <WizardStepContent id="wizard-step-1" className="pb0">
                <Column>
                    <Flex gap={2} justifyContent="space-between">
                        <h1 className="heading small">
                            <Trans ns="admin">Welcome to Minup!</Trans>
                        </h1>
                        <LanguageSelector
                            className="only-desktop"
                            id="language"
                            languageCode={languageCode}
                            onChange={onChangeLanguage}
                        />
                    </Flex>
                    <p>
                        <Trans ns="admin">Enter your name and we’ll create a booking page for your business.</Trans>
                    </p>
                    <Flex flexDirection={lastNameFirst ? 'row-reverse' : 'row'} gap={1}>
                        <InputGroup style={{ flex: '1 1 0', width: 0 }}>
                            <label htmlFor="firstName">
                                <Trans ns="admin">First name</Trans>
                            </label>
                            <Input
                                tabIndex={lastNameFirst ? 2 : 1}
                                placeholder={t('Eg Jane')}
                                type="text"
                                name="firstName"
                                id="firstName"
                                value={firstName}
                                onChange={onFirstNameChange}
                                required
                            />
                        </InputGroup>
                        <InputGroup style={{ flex: '1 1 0', width: 0 }}>
                            <label htmlFor="lastName">
                                <Trans ns="admin">Last name</Trans>
                            </label>
                            <Input
                                tabIndex={lastNameFirst ? 1 : 2}
                                placeholder={t('Eg Doe')}
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={lastName}
                                onChange={onLastNameChange}
                                required
                            />
                        </InputGroup>
                    </Flex>
                    <InputGroup>
                        <label htmlFor="name">
                            <Trans ns="admin">Business name</Trans>
                        </label>
                        <Input
                            tabIndex={2}
                            placeholder={t('Eg Jane Doe or Acme Co')}
                            type="text"
                            name="name"
                            id="name"
                            value={name}
                            onChange={onNameChange}
                            required
                        />
                    </InputGroup>
                    <InputGroup>
                        <label htmlFor="handle">
                            <Trans ns="admin">Booking page URL</Trans>
                        </label>
                        <Flex gap={1}>
                            <InputPlaceholder disabled value="app.minup.io/book/" />
                            <Input
                                tabIndex={2}
                                className={urlValid === false ? 'error' : ''}
                                type="text"
                                name="handle"
                                id="handle"
                                value={urlHandle}
                                onChange={onHandleChange}
                                onBlur={onHandleBlur}
                                required
                            />
                        </Flex>
                        {checking ? (
                            <div>
                                <FontAwesomeIcon icon={faSpinner} spin />
                                &nbsp;
                                <Trans ns="admin">Checking url handle availabilty</Trans>
                            </div>
                        ) : urlValid === false ? (
                            <ErrorMessage>
                                <Trans ns="admin">This url handle is already taken, please choose an other one</Trans>
                            </ErrorMessage>
                        ) : null}
                    </InputGroup>
                    <InputGroup>
                        <label id="country-label">
                            <Trans ns="admin">Country</Trans>
                        </label>
                        <CountryCodeSelector
                            id="country"
                            aria-labelledby="country-label"
                            value={countryCode}
                            onChange={(value) => {
                                setCountryCode(value)
                            }}
                        />
                    </InputGroup>
                    <InputGroup>
                        <label htmlFor="name">
                            <Trans ns="admin">Phone number</Trans>
                        </label>
                        <Input
                            tabIndex={2}
                            placeholder={t('Eg {{example}}', { example: phoneNumberPlaceholder })}
                            type="text"
                            name="phone"
                            id="phone"
                            value={phone}
                            onChange={onPhoneChange}
                            required
                        />
                    </InputGroup>
                    <DontWorry />
                    <Flex>
                        <p className="text-centered">
                            <Trans ns="admin">Only want to book an appointment?</Trans>
                            <TextButton type="button" onClick={onCancel} className="mlxs inline-flex">
                                <Trans ns="admin">Cancel registration</Trans>
                            </TextButton>
                        </p>
                    </Flex>
                </Column>
            </WizardStepContent>
            <WizardStepFooter justifyContent="flex-end">
                <Flex grow={1}>
                    <PrimaryButton className="w100" tabIndex={2} disabled={checking || !urlValid}>
                        <Trans ns="admin">Next</Trans>
                    </PrimaryButton>
                </Flex>
            </WizardStepFooter>
        </form>
    )
}
