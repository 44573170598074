import { Moment } from 'moment'
import { Service } from '../services/types'
import { RepeatRules } from '../../pages/admin/calendar/components/RepeatEditor'

export enum AppointmentStatus {
    PaymentPending = -10,
    New = 0,
    Approved = 1,
    CancelledByClient = 2,
    CancelledByBusiness = 3,
    Rejected = 4,
    Deleted = 5,
}

export enum EventType {
    Appointment = 'appointment',
    Break = 'break',
    Holiday = 'holiday',
    Event = 'event',
}

export interface ClientInfo {
    name: string
    email: string
    phone: string
}

export interface CustomerBillingDetails {
    invoiceRequested: boolean
    billingName: string
    billingCountry: string
    billingCountryCode: string
    billingCity: string
    billingZipCode: string
    billingAddress: string
    taxID: string
    isBusiness: boolean
}

export interface WithInvoice {
    invoiceNumber?: string
    invoiceLink?: string
}

export interface TransactionAdminDto {
    externalId: string
    paymentProvider: string
    status: string
    timeout: string
}

export interface Appointment extends CustomerBillingDetails, WithInvoice {
    id?: number
    type: EventType
    title: string
    from: string
    to: string
    allDay: boolean
    serviceName: string
    serviceId?: number
    clientEmail: string
    clientName: string
    clientNote?: string
    clientPhone: string
    inSeries: boolean
    repeatRules: RepeatRules | null
    source: string
    status: AppointmentStatus
    note: string
    noteToSelf: string
    breakAfter: number
    priceText: string
    priceValue?: number | null
    location: string
    description: string
    free: boolean
    fromDateTime?: Moment
    toDateTime?: Moment
    answers?: string
    services?: Service[]
    transactions: TransactionAdminDto[] | null
    releaseAfter: string | null
}

export type Answer = {
    id: string
    answer: string
    question: string
    textAnswer?: string
    textified?: string
}
