import { CSSProperties, DetailedHTMLProps, FC, InputHTMLAttributes, ReactNode } from 'react'
import styled from '@emotion/styled'
import { FormikInput, Input } from './Input'
import { FieldProps } from 'formik'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'
import { classNames } from '../../../services/class-names'
import { asClickable } from '../../helpers/as-clickable'

const InputWithAddonContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        flex: '0 1 auto',
        display: 'inline-flex',
        alignItems: 'center',
        input: {
            display: 'inline-block',
            borderRadius: 0,
        },

        '>:first-child': {
            '.input-addon, &.input-addon': {
                borderRadius: `${theme.Spacing(1)} 0 0 ${theme.Spacing(1)}`,
                borderRight: 'none',
            },
            '.input, &.input': {
                borderRadius: `${theme.Spacing(1)} 0 0 ${theme.Spacing(1)} `,
            },
        },

        '>:last-child': {
            '.input-addon, &.input-addon': {
                borderRadius: `0 ${theme.Spacing(1)} ${theme.Spacing(1)} 0`,
                borderLeft: 'none',
            },
            '.input, &.input': {
                borderRadius: `0 ${theme.Spacing(1)} ${theme.Spacing(1)} 0`,
            },
        },

        '.input-addon': {
            display: 'inline-flex',
            flex: '0 0 auto',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${theme.Neutral_400}`,
            height: 42,
            padding: `0 ${theme.Spacing(2.5)}`,
            '&.disabled': {
                background: theme.Neutral_100,
                borderColor: theme.Neutral_500,
                color: theme.ContentSecondary,
            },
        },
    }))
)

export const ClickableInputAddon = asClickable(
    withTheme(
        styled.div(({ theme }: { theme: AdminTheme }) => ({
            zIndex: 1,
            '&:focus-visible': {
                border: `1px solid ${theme.ThemeColor} !important`,
                outline: 'none',
                boxShadow: `0 0 0 4px ${theme.FocusOutline}`,
            },
        }))
    )
)

export const InputWithAddon: FC<
    {
        isFormik?: boolean
        after?: string | ReactNode
        containerStyles?: CSSProperties
        addonStyle?: CSSProperties
        leftAddonClass?: string
        leftAddon?: ReactNode | string
        rightAddonClass?: string
        rightAddon?: ReactNode | string
        onAddonClick?: () => void
        children?: never
    } & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = ({
    isFormik,
    after,
    disabled,
    containerStyles,
    addonStyle,
    onAddonClick,
    leftAddon,
    leftAddonClass,
    rightAddon,
    rightAddonClass,
    className,
    ...props
}) => {
    return (
        <InputWithAddonContainer style={containerStyles}>
            {leftAddon ? (
                <div
                    className={classNames(
                        leftAddonClass === undefined ? 'input-addon' : leftAddonClass,
                        disabled ? 'disabled' : null
                    )}
                >
                    {leftAddon}
                </div>
            ) : null}
            {isFormik ? (
                <FormikInput className={classNames(className, 'input')} disabled={disabled} {...(props as any)} />
            ) : (
                <Input className={classNames(className, 'input')} disabled={disabled} {...props} />
            )}
            {rightAddon ? (
                <div
                    style={addonStyle}
                    className={classNames(
                        rightAddonClass === undefined ? 'input-addon' : rightAddonClass,
                        disabled ? 'disabled' : null
                    )}
                >
                    {rightAddon}
                </div>
            ) : null}
        </InputWithAddonContainer>
    )
}

export const FormikInputWithAddon: FC<
    FieldProps & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { children?: never }
> = ({ ...props }) => {
    return <InputWithAddon {...props} isFormik />
}
