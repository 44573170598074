import { FC } from 'react'
import { BusinessPublicDto } from '../../bookingpage/types/business'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../theme/theme'
import styled from '@emotion/styled'
import { UpcomingEventsColumn } from './events/UpcomingEventsColumn'
import { ServiceSelector } from './ServiceSelector'
import { BookingPageWithStickyFooter } from './components/BookingPageLayout'
import { GroupServicesTimetable } from './timetable-view/GroupServicesTimetable'
import { EventSelector } from './events/EventSelector'
import { OccurrencePublicDto } from '../../store/events/types'

const ServicesContainerInTimetableView = withTheme(
    styled('div')(({ theme }: { theme: AdminTheme }) => ({
        height: 'max-content',
        gridArea: 'right-column',
        '> div': {
            width: '100%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        backgroundColor: theme.SurfaceColor,
        color: theme.ContentPrimary,
        '&.embedded': {
            padding: 0,
            boxShadow: 'none',
        },
        [theme.BookingPageBreakPointReverse]: {
            borderBottom: `1px solid ${theme.BorderSecondary}`,
        },
        [theme.BookingPageBreakPoint]: {
            ...theme.BookingPageBorderStyle,
            borderRadius: theme.Spacing(1),
            flexGrow: 1,
            width: 380,
            marginLeft: theme.Spacing(3),
            marginTop: 0,
            '&.embedded': {
                marginLeft: 0,
            },
        },
    }))
)

const EventsContainerInCardView = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        gridArea: 'right-column',
        [theme.BookingPageBreakPoint]: {
            width: 380,
        },
    }))
)

export const BookingPageServicesViewDesktop: FC<{
    events: OccurrencePublicDto[]
    business: BusinessPublicDto
    timetableView: boolean
}> = ({ events, business, timetableView }) => {
    return (
        <>
            {events.length && business?.services?.length ? (
                timetableView ? (
                    <ServicesContainerInTimetableView>
                        <ServiceSelector
                            business={business}
                            hasEvents={events.length > 0}
                            searchBarFullWidth
                            disabledScrollOnAppear
                        />
                    </ServicesContainerInTimetableView>
                ) : (
                    <EventsContainerInCardView>
                        <UpcomingEventsColumn business={business} events={events} />
                    </EventsContainerInCardView>
                )
            ) : null}
            <BookingPageWithStickyFooter noHeight>
                {timetableView ? (
                    events.length ? (
                        <GroupServicesTimetable business={business} events={events} />
                    ) : (
                        <ServiceSelector business={business} hasEvents={events.length > 0} />
                    )
                ) : events.length && !business.services.length ? (
                    <EventSelector business={business} events={events} />
                ) : (
                    <ServiceSelector business={business} hasEvents={events.length > 0} />
                )}
            </BookingPageWithStickyFooter>
        </>
    )
}
