import { Reducer } from 'react'
import { CalendarAction, CalendarActionTypes } from './actions'
import { CalendarEntry, CalendarState } from './types'

const INITIAL_CALENDAR_STATE: CalendarState = {
    isLoading: false,
    entries: [],
    events: [],
    isLoadingEvents: false,
}

export const calendarReducer: Reducer<CalendarState, CalendarAction> = (state, action) => {
    let nextState = state ? state : INITIAL_CALENDAR_STATE

    switch (action.type) {
        case CalendarActionTypes.PATCH:
            return {
                ...nextState,

                entries: nextState.entries.map((entry) =>
                    entry.objectId === action.payload.id ? { ...entry, ...action.payload.patch } : entry
                ),
            }
        case CalendarActionTypes.CREATE:
            return {
                ...nextState,
                entries: Array.isArray(action.payload)
                    ? [...nextState.entries, ...(action.payload as CalendarEntry[])]
                    : [...nextState.entries, action.payload],
            }

        case CalendarActionTypes.UPDATE:
            return {
                ...nextState,
                entries: nextState.entries.map((entry) =>
                    entry.objectId === action.payload.objectId ? action.payload : entry
                ),
            }

        case CalendarActionTypes.DELETE:
            return {
                ...nextState,
                // eslint-disable-next-line eqeqeq
                entries: nextState.entries.filter((entry) => entry.objectId != action.payload),
            }

        case CalendarActionTypes.SET_ENTRIES:
            return {
                ...nextState,
                entries: action.payload,
            }
        case CalendarActionTypes.SET_EVENTS:
            return {
                ...nextState,
                events: action.payload,
            }

        case CalendarActionTypes.SET_IS_LOADING:
            return {
                ...nextState,
                isLoading: action.payload,
            }
        case CalendarActionTypes.SET_IS_LOADING_EVENTS:
            return {
                ...nextState,
                isLoadingEvents: action.payload,
            }

        default:
            return nextState
    }
}
