import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { OccurrencePublicDto } from '../../../store/events/types'
import { UpcomingEventCard } from './UpcomingEventCard'
import { AdminTheme } from '../../../theme/theme'
import { NoServices } from '../ServiceSelector'
import { Flex } from '../../../components/helpers/Flex'
import { SearchToggleInput } from '../../../components/ui-kit/comopnents/InputWithIconsAndAnimation'
import { BusinessPublicDto } from '../../../bookingpage/types/business'

const UpcomingEventsContainer = withTheme(
    styled('div')(({ theme }: { theme: AdminTheme }) => ({
        backgroundColor: theme.SurfaceColor,
        color: theme.ContentPrimary,
        padding: theme.Spacing(2.5),
        flexGrow: 1,
        [theme.BookingPageBreakPoint]: {
            padding: theme.Spacing(3),
        },
        width: '100%',
        '.list': {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '24px',
            [theme.BookingPageBreakPoint]: {
                flexDirection: 'row',
                alignItems: 'flex-start',
            },
        },
    }))
)

const EventSelectorTitleContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        width: '100%',
        minHeight: 42,
    }))
)

export const EventSelector: FC<{
    business: BusinessPublicDto
    events: OccurrencePublicDto[]
    hideTitle?: boolean
    search?: string
}> = ({ business, events, hideTitle, search: externalSearch }) => {
    const [search, setSearch] = useState('')

    const filteredEvents = useMemo(
        () =>
            events.filter((event) =>
                event.title?.toLocaleLowerCase().includes((externalSearch || search).toLocaleLowerCase())
            ),
        [events, externalSearch, search]
    )

    return (
        <UpcomingEventsContainer>
            {!hideTitle ? (
                <EventSelectorTitleContainer justifyContent="space-between" className="pb">
                    <h2>
                        <Trans ns="bookingpage">Book an appointment</Trans>
                    </h2>
                    <SearchToggleInput searchTerm={search} setSearchTerm={setSearch} fullWidth />
                </EventSelectorTitleContainer>
            ) : null}

            <div className="list">
                {filteredEvents.length ? (
                    filteredEvents.map((event) => (
                        <UpcomingEventCard key={event.id} business={business} event={event} />
                    ))
                ) : (
                    <NoServices searchUsed />
                )}
            </div>
        </UpcomingEventsContainer>
    )
}
