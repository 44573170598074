import { withTheme } from '@emotion/react'
import { Column } from '../../../components/admin/layout/Column'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'

export const Content = withTheme(
    styled(Column)(({ theme }: { theme: AdminTheme }) => ({
        flex: '1 1 0',
        padding: theme.Spacing(2.5),
        [theme.BookingPageBreakPoint]: {
            padding: theme.Spacing(3),
        },
    }))
)
