import { Reducer } from 'react'
import { AdminAction, AdminActionTypes } from './actions'
import { AdminState } from './types'
import { isSavedAdminMenuCollapsed } from '../../hooks/admin/use-admin-menu-collapsed'

function getInitialAdminState() {
    return {
        adminMenuOpen: false,
        adminMenuCollapsed: isSavedAdminMenuCollapsed(),
        showNotificatonIndicator: [],
        widgetOpen: false,
        zendeskScriptLoaded: false,
        zendeskWidgetLoading: false,
        liveAgentAvailable: undefined,
    }
}

export const adminReducer: Reducer<AdminState, AdminAction> = (state, action) => {
    let previousState = state ? state : getInitialAdminState()

    switch (action.type) {
        case AdminActionTypes.SET_ADMIN_MENU_OPEN:
            return {
                ...previousState,
                adminMenuOpen: action.payload,
            }
        case AdminActionTypes.SET_ADMIN_MENU_COLLAPSED:
            return {
                ...previousState,
                adminMenuCollapsed: action.payload,
            }
        case AdminActionTypes.SHOW_NOTIFICIATION_INDICATOR: {
            const showNotificatonIndicator = previousState.showNotificatonIndicator
            return {
                ...previousState,
                showNotificatonIndicator: [
                    ...showNotificatonIndicator,
                    ...(Array.isArray(action.payload) ? action.payload : [action.payload]),
                ],
            }
        }
        case AdminActionTypes.HIDE_NOTIFICIATION_INDICATOR: {
            const showNotificatonIndicator = previousState.showNotificatonIndicator
            return {
                ...previousState,
                showNotificatonIndicator: showNotificatonIndicator.filter((where) =>
                    Array.isArray(action.payload) ? !action.payload.includes(where) : where !== action.payload
                ),
            }
        }
        case AdminActionTypes.SET_WIDGET_OPEN: {
            return {
                ...previousState,
                widgetOpen: action.payload,
            }
        }
        case AdminActionTypes.ZENDESK_SCRIPT_LOADED: {
            return {
                ...previousState,
                zendeskScriptLoaded: true,
            }
        }
        case AdminActionTypes.SET_ZENDESK_WIDGET_LOADING: {
            return {
                ...previousState,
                zendeskWidgetLoading: action.payload,
            }
        }
        case AdminActionTypes.SET_EDITED_CUSTOM_THEME: {
            return {
                ...previousState,
                editedCustomTheme: action.payload,
            }
        }
        case AdminActionTypes.SET_LIVE_AGENT_AVAILABLE: {
            return {
                ...previousState,
                liveAgentAvailable: action.payload,
            }
        }
        default:
            return previousState
    }
}
