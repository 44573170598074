import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { AdminPageContent } from '../../../../components/admin/layout/AdminPageContent'
import { AdminMain } from '../../../../components/admin/layout/AdminMain'
import { useBusiness } from '../../../../hooks/use-business'
import { BillingDetails } from '../main/BillingDetailsForm'
import { useBillingDetailsApi } from '../../../../hooks/apis/use-billing-details-api'
import { useToast } from '../../../../hooks/use-toast'
import { PaymentStartDto, useSubscriptionApi } from '../../../../hooks/apis/use-subscription-api'
import { useHistory } from 'react-router-dom'
import { LoadingScreen } from '../../../../components/ui-kit/comopnents/LoadingScreen'
import { AdminPageHeader } from '../../../../components/admin/layout/AdminPageHeader'
import { useGetErrorMessage } from '../../../../services/getErrorMessage'
import { Appearance, loadStripe, StripeElementLocale, StripeElementsOptions } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { StripeCheckoutForm } from './StripeCheckoutForm'
import { useAdminTheme } from '../../../../hooks/use-admin-theme'
import { LanguageCodes } from '../../../../i18n'
import { BillingCycle, SubscriptionTier } from '../../../../common/types/enums'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')

export const SubscriptionBreakpointMedia = '(min-width: 1280px)'
export const SubscriptionBreakpoint = `@media${SubscriptionBreakpointMedia}`

export function useStripeAppearance() {
    const theme = useAdminTheme()
    return useMemo<Appearance>(
        () => ({
            rules: {
                '.Label': {
                    ...theme._BodySmallSemibold,
                    color: theme.ContentPrimary,
                    ...theme._DefaultFontFamily,
                    fontWeight: 500,
                },
                '.Input': {
                    borderColor: theme.BorderSecondary,
                    borderRadius: '8px',
                },
                '.Input:focus': {
                    borderColor: theme.ThemeColor,
                    outline: 'none',
                    boxShadow: `0 0 0 4px ${theme.FocusOutline}`,
                },
            },
        }),
        [
            theme._BodySmallSemibold,
            theme.ContentPrimary,
            theme._DefaultFontFamily,
            theme.BorderSecondary,
            theme.ThemeColor,
            theme.FocusOutline,
        ]
    )
}

export const SubscriptionCheckout: FC<{
    billingCycle: BillingCycle
    setBillingCycle: (billingCycle: BillingCycle) => void
}> = ({ billingCycle, setBillingCycle }) => {
    const business = useBusiness()!

    const isTrial = business.subscriptionTier === 'free' && business.proTrialAvailable

    const history = useHistory()

    const onSuccess = useCallback(
        (subscriptionTier: SubscriptionTier) => {
            history.push(`/admin/subscription/success?target=${subscriptionTier}`)
        },
        [history]
    )

    return (
        <AdminMain className="admin-main">
            <AdminPageHeader
                backLink="/admin/subscription"
                title={
                    business.subscriptionTier === 'free' ? (
                        isTrial ? (
                            <Trans ns="admin">Start free trial</Trans>
                        ) : (
                            <Trans ns="admin">Upgrade</Trans>
                        )
                    ) : business.isCancelPending ? (
                        <Trans ns="admin">Renew</Trans>
                    ) : (
                        <Trans ns="admin">Subscription</Trans>
                    )
                }
            />
            <AdminPageContent style={{ paddingTop: 0, maxWidth: 1014 }}>
                <SubscriptionCheckoutContent
                    billingCycle={billingCycle}
                    setBillingCycle={setBillingCycle}
                    onSuccess={onSuccess}
                />
            </AdminPageContent>
        </AdminMain>
    )
}

export const SubscriptionCheckoutContent: FC<{
    billingCycle: BillingCycle
    setBillingCycle: (billingCycle: BillingCycle) => void
    onSuccess: (subscriptionTier: SubscriptionTier) => void
}> = ({ billingCycle, setBillingCycle, onSuccess }) => {
    const business = useBusiness()!
    const [billingAddress, setBillingAddress] = useState<BillingDetails>({
        billingName: '',
        country: 'HU',
        city: '',
        streetAddress: '',
        zipCode: '',
        taxID: '',
    })
    const billingDetailsApi = useBillingDetailsApi()
    const { startPro, startTrial, updateOrder } = useSubscriptionApi()
    const toast = useToast()
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)

    const [priceInfo, setPriceInfo] = useState<PaymentStartDto>()

    const [couponCode, setCouponCode] = useState<string | null>(() => {
        const queryParams = new URLSearchParams(window.location.search)
        return queryParams.get('coupon')
    })

    const firstLoad = useRef(true)

    const getErrorMessage = useGetErrorMessage()

    const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions>()
    const appearance = useStripeAppearance()
    useEffect(() => {
        if ((!stripeOptions || stripeOptions.clientSecret !== priceInfo?.paymentId) && priceInfo?.paymentId) {
            setStripeOptions({
                clientSecret: priceInfo.paymentId,
                locale: (business.languageCode || LanguageCodes.Fallback) as StripeElementLocale,
                appearance,
            })
        }
    }, [appearance, business.languageCode, priceInfo?.paymentId, stripeOptions])

    useEffect(() => {
        if (!priceInfo?.paymentId) {
            setLoading(true)
            ;(async () => {
                try {
                    const billingDetailsResponse = await billingDetailsApi.get()
                    const billingDetails = billingDetailsResponse.data
                    if (billingDetails) {
                        setBillingAddress(billingDetails)
                    }

                    let request
                    if (business.subscriptionTier === 'free' && business.proTrialAvailable) {
                        request = await startTrial(
                            billingCycle,
                            billingDetails?.country || 'HU',
                            billingDetails?.taxID || '',
                            couponCode
                        )
                    } else {
                        request = await startPro(
                            billingCycle,
                            billingDetails?.country || 'HU',
                            billingDetails?.taxID || '',
                            couponCode
                        )
                    }
                    const response = await request
                    setPriceInfo(response.data)
                    setLoading(false)
                } catch (e) {
                    toast.error(await getErrorMessage(e))
                }
            })()
        }
    }, [
        billingCycle,
        billingDetailsApi,
        business.proTrialAvailable,
        business.subscriptionTier,
        couponCode,
        getErrorMessage,
        priceInfo?.paymentId,
        startPro,
        startTrial,
        toast,
    ])

    const updatePrices = useCallback(async () => {
        setUpdating(true)
        try {
            const response = await updateOrder(
                business.subscriptionTier === 'free' && business.proTrialAvailable
                    ? 'pro-trial'
                    : business.isCancelPending
                    ? 'pro-resume'
                    : 'pro',
                billingCycle,
                billingAddress.country,
                billingAddress.taxID || '',
                priceInfo?.invoiceId,
                couponCode
            )
            setPriceInfo(response.data)
        } catch (e) {
            toast.error(await getErrorMessage(e, { coupon: couponCode }))
        }
        setUpdating(false)
    }, [
        billingAddress.country,
        billingAddress.taxID,
        billingCycle,
        business.isCancelPending,
        business.proTrialAvailable,
        business.subscriptionTier,
        couponCode,
        getErrorMessage,
        priceInfo?.invoiceId,
        toast,
        updateOrder,
    ])

    useEffect(() => {
        if (priceInfo?.invoiceId) {
            if (firstLoad.current) {
                firstLoad.current = false
                return
            }
            const timeout = setTimeout(() => {
                updatePrices()
            }, 300)
            return () => clearTimeout(timeout)
        }
    }, [priceInfo?.invoiceId, updatePrices])

    const [paymentDetailsOpen, setPaymentDetailsOpen] = useState(false)

    return (
        <>
            {loading ? (
                <LoadingScreen />
            ) : (
                <Elements key={stripeOptions?.clientSecret} stripe={stripePromise} options={stripeOptions}>
                    <StripeCheckoutForm
                        business={business}
                        billingAddress={billingAddress}
                        updating={updating}
                        priceInfo={priceInfo}
                        billingCycle={billingCycle}
                        couponCode={couponCode}
                        paymentDetailsOpen={paymentDetailsOpen}
                        setPaymentDetailsOpen={setPaymentDetailsOpen}
                        setBillingCycle={setBillingCycle}
                        setCouponCode={setCouponCode}
                        setBillingAddress={setBillingAddress}
                        onSuccess={onSuccess}
                    />
                </Elements>
            )}
        </>
    )
}
