import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { asClickable } from '../../../../components/helpers/as-clickable'
import { LanguageCodes } from '../../../../i18n'
import { AdminTheme } from '../../../../theme/theme'
import SimplePayLogoFull from '../../../../icons/online-payment-providers/SimplePayLogoFull.webp'
import { classNames } from '../../../../services/class-names'
import { faCircle } from '@fortawesome/free-regular-svg-icons'

export const PaymentOptionContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        lineHeight: theme.Spacing(4),
        cursor: 'default',
        '.icon': {
            cursor: 'pointer',
            fontSize: theme.Spacing(3),
            marginRight: theme.Spacing(1.5),
            marginTop: theme.Spacing(0.5),

            color: theme.BorderSecondary,
            '&.selected': {
                color: theme.InteractivePrimary,
            },
        },
        '.description': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            rowGap: theme.Spacing(1),
            flexWrap: 'wrap',
        },
        '.illustration': {
            height: theme.Spacing(4),
        },
    }))
)

const ClickabelFontAwesomeIcon = asClickable(FontAwesomeIcon)

export const PaymentOptionRadio: FC<{ selected: boolean; onSelect: () => void; labelledBy: string }> = ({
    selected,
    onSelect,
    labelledBy,
}) => {
    return (
        <ClickabelFontAwesomeIcon
            className={classNames('icon', selected ? 'selected' : null)}
            role="radio"
            aria-checked={selected}
            icon={selected ? faCheckCircle : faCircle}
            onClick={onSelect}
            aria-labelledby={labelledBy}
        />
    )
}

export const SimplePayLogo: FC<
    { languageCode: string } & Omit<React.HtmlHTMLAttributes<HTMLImageElement>, 'src' | 'title' | 'alt'>
> = ({ languageCode, ...rest }) => {
    const href =
        languageCode === LanguageCodes.Hu
            ? 'http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf'
            : 'http://simplepartner.hu/PaymentService/Payment_information.pdf'
    const { t } = useTranslation('bookingpage')
    return (
        <a className="contents" href={href} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
            <img
                src={SimplePayLogoFull}
                title={t('SimplePay - Online card payment')}
                alt={t('SimplePay payment information')}
                {...rest}
            />
        </a>
    )
}
