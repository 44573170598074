import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-xhr-backend'

export type LanguageCode = 'hu' | 'en' | 'ro'

export const LanguageCodes = {
    Hu: 'hu',
    En: 'en',
    Ro: 'ro',
    Fallback: 'en',
}

export const LanguageNames = {
    [LanguageCodes.En]: 'English',
    [LanguageCodes.Hu]: 'Magyar',
    [LanguageCodes.Ro]: 'Română',
}

export const LanguageFlags = {
    [LanguageCodes.En]: '🇬🇧',
    [LanguageCodes.Hu]: '🇭🇺',
    [LanguageCodes.Ro]: '🇷🇴',
}

export const AVAILABLE_LANGUAGES: string[] = [LanguageCodes.En, LanguageCodes.Hu, LanguageCodes.Ro]

export const ADMIN_LANG_LOCAL_STORAGE_KEY = 'admin_lang'

export const preferred_lang = window.localStorage.getItem(ADMIN_LANG_LOCAL_STORAGE_KEY)

function getDefaultLang(saved?: string | null) {
    if (saved && AVAILABLE_LANGUAGES.includes(saved)) {
        return saved
    }
    switch (navigator.language.toLowerCase().slice(0, 2)) {
        case 'hu':
            return LanguageCodes.Hu
        case 'ro':
            return LanguageCodes.Ro
        default:
            return LanguageCodes.En
    }
}

export const default_lang = getDefaultLang(preferred_lang)

function initInstance(instance: typeof i18n, default_lang: string) {
    return (
        instance
            // learn more: https://github.com/i18next/i18next-xhr-backend
            .use(Backend)
            // for all options read: https://www.i18next.com/overview/configuration-options
            .init({
                debug: process.env.NODE_ENV === 'development',
                lng: default_lang,
                fallbackLng: 'en',
                supportedLngs: AVAILABLE_LANGUAGES,
                ns: ['admin'],
                interpolation: {
                    escapeValue: false, // not needed for react as it escapes by default
                },
                backend: {
                    loadPath: '/locales/{{lng}}-{{ns}}.json?buildNumber=' + process.env.REACT_APP_BUILD_NUMBER,
                },
                keySeparator: false,
                nsSeparator: false,
                pluralSeparator: '__',
                contextSeparator: '__',
                ignoreJSONStructure: true,
            })
    )
}

export function createInstance(default_lang: string) {
    const instance = i18n.createInstance()
    return initInstance(instance, default_lang)
}

i18n
    // connect with React
    .use(initReactI18next)
// Init with default settings
initInstance(i18n, default_lang)

export function changeLanguage(language: string): Promise<Function> {
    window.localStorage.setItem(ADMIN_LANG_LOCAL_STORAGE_KEY, language)
    try {
        document.querySelector('html')?.setAttribute('lang', i18n.language)
    } catch {}
    return i18n.changeLanguage(language)
}

export default i18n
