import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { withSpacing } from '../../helpers/with-spacing'

export const Column = withTheme(
    withSpacing(
        styled.div<{
            grow?: boolean
            stretch?: boolean
            spacing?: number
            mobileSpacing?: number
            mobileFullWidth?: boolean
            centered?: boolean
            verticalCentered?: boolean
            theme: AdminTheme
            maxWidth?: number | string
            maxHeight?: number | string
        }>(
            ({
                grow,
                stretch,
                spacing,
                mobileSpacing,
                mobileFullWidth,
                centered,
                verticalCentered,
                maxWidth,
                maxHeight,
                theme,
            }) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: centered ? 'center' : 'stretch',
                alignSelf: stretch ? 'stretch' : undefined,
                justifyContent: verticalCentered ? 'center' : 'flex-start',
                gap: theme.Spacing(mobileSpacing !== undefined ? mobileSpacing : spacing !== undefined ? spacing : 3),
                flex: grow ? '1 1 0' : undefined,
                maxWidth: maxWidth || '100%',
                maxHeight,
                flexBasis: mobileFullWidth ? '100%' : grow ? 0 : undefined,
                [theme.BreakPoint]: {
                    gap: theme.Spacing(spacing !== undefined ? spacing : 3),
                    flexBasis: grow ? 0 : 'auto',
                },
            })
        )
    )
)
