import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { Trans } from 'react-i18next'
import {
    GUIDE_IDS,
    LEARN_THE_BASICS_SALONIC_SUB_TASK_IDS,
    LEARN_THE_BASICS_SALONIC_TASK_IDS,
    LEARN_THE_BASICS_SUB_TASK_IDS,
    LEARN_THE_BASICS_TASK_IDS,
} from './constants'
import { NavLink } from 'react-router-dom'
import { Guide } from '../../../../store/getting-started/types'

const note = (
    <>
        Ha problémát tapasztalnál kérlek jelezed nekünk a <a href="mailto:hello@minup.io">hello@minup.io</a> email
        címen.
    </>
)

export function LearnTheBasicsSalonicGuide(): Guide {
    return {
        id: GUIDE_IDS.LEARN_THE_BASICS,
        icon: faGraduationCap,
        title: <Trans ns="admin">Learn the basics</Trans>,
        tasks: [
            {
                id: LEARN_THE_BASICS_TASK_IDS.SET_UP_ACCOUNT,
                number: 1,
                title: 'Hozd létre a fiókod',
                subTasks: [],
                percentage: 17,
                linkTo: '',
                autocomplete: true,
            },
            {
                id: LEARN_THE_BASICS_TASK_IDS.COMPLETE_YOUR_PROFILE,
                number: 2,
                title: 'Profil',
                subTasks: [
                    {
                        id: LEARN_THE_BASICS_SUB_TASK_IDS.GO_TO_PROFILE_SETTINGS,
                        title: (
                            <Trans ns="admin">
                                Go to Settings <NavLink to="/admin/booking/overview/profile">Profile settings</NavLink>
                            </Trans>
                        ),
                    },
                    {
                        id: LEARN_THE_BASICS_SUB_TASK_IDS.SAVE_SETTINGS,
                        title: 'Ellenőrizd a profil adataidat.',
                    },
                ],
                note,
                percentage: 17,
                linkTo: '/admin/booking/overview/profile',
                skipText: 'Kész',
            },
            {
                id: LEARN_THE_BASICS_SALONIC_TASK_IDS.CALENDAR,
                number: 3,
                title: 'Naptár',
                subTasks: [
                    {
                        id: LEARN_THE_BASICS_SALONIC_SUB_TASK_IDS.CHECK_YOUR_CALENDAR,
                        title: (
                            <>
                                Irány a <NavLink to="/admin/calendar">Naptár</NavLink> menüpont.
                            </>
                        ),
                    },
                    {
                        id: LEARN_THE_BASICS_SALONIC_SUB_TASK_IDS.CHECK_DATES,
                        title: 'Ellenőrizd, hogy minden időpont át lett-e mentve a naptáradba.',
                    },
                ],
                note,
                percentage: 17,
                linkTo: '/admin/calendar',
                skipText: 'Kész',
            },
            {
                id: LEARN_THE_BASICS_SALONIC_TASK_IDS.CRM,
                number: 4,
                title: 'Vendégek',
                subTasks: [
                    {
                        id: LEARN_THE_BASICS_SALONIC_SUB_TASK_IDS.GO_TO_CLIENTS,
                        title: (
                            <>
                                Irány az <NavLink to="/admin/crm">Ügyfelek</NavLink> menüpont.
                            </>
                        ),
                    },
                    {
                        id: LEARN_THE_BASICS_SALONIC_SUB_TASK_IDS.CHECK_YOUR_CLIENTS,
                        title: 'Ellenőrizd, hogy minden vendéged át lett-e mentve az ügyféladatbázisba.',
                    },
                ],
                note,
                percentage: 17,
                linkTo: '/admin/crm',
                skipText: 'Kész',
            },
            {
                id: LEARN_THE_BASICS_TASK_IDS.CREATE_A_SERVICE,
                number: 5,
                title: 'Szolgáltatások',
                subTasks: [
                    {
                        id: LEARN_THE_BASICS_SUB_TASK_IDS.GO_TO_SERVICES,
                        title: (
                            <Trans ns="admin">
                                Go to Settings <NavLink to="/admin/booking/services">Services</NavLink>
                            </Trans>
                        ),
                    },
                    {
                        id: LEARN_THE_BASICS_SUB_TASK_IDS.CHECK_SERVICES,
                        title: 'Ellenőrizd, hogy minden szolgáltatás megfelelően van-e beállítva. Ha nem, végezd el a szükséges módosításokat.',
                    },
                ],
                note,
                percentage: 17,
                linkTo: '/admin/booking/services',
                skipText: 'Kész',
            },
            {
                id: LEARN_THE_BASICS_TASK_IDS.CHOOSE_YOUR_STYLE,
                number: 6,
                title: <Trans ns="admin">Choose your style</Trans>,
                subTasks: [
                    {
                        id: LEARN_THE_BASICS_SUB_TASK_IDS.GO_TO_APPEARANCE,
                        title: (
                            <Trans ns="admin">
                                Go to Settings <NavLink to="/admin/booking/appearance">Appearance</NavLink>
                            </Trans>
                        ),
                    },

                    {
                        id: LEARN_THE_BASICS_SUB_TASK_IDS.SELECT_THEME,
                        title: <Trans ns="admin">Select theme</Trans>,
                    },
                    {
                        id: LEARN_THE_BASICS_SUB_TASK_IDS.UPLOAD_COVER_PICTURE,
                        title: <Trans ns="admin">Upload a cover picture</Trans>,
                    },
                ],
                note: <Trans ns="admin">Save when you’re done!</Trans>,
                percentage: 15,
                linkTo: '/admin/booking/appearance',
            },
        ],
    }
}
