import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme, fromTheme } from '../../../theme/theme'
import { AdminMain } from '../../admin/layout/AdminMain'
import { AdminPageContent } from '../../admin/layout/AdminPageContent'

export const CalendarAdminMain = withTheme(
    styled(AdminMain)(({ theme }: { theme: AdminTheme }) => ({
        paddingBottom: 0,
        [theme.BreakPoint]: {
            paddingBottom: theme.Spacing(4),
        },
    }))
)

export const CalendarAdminPage = withTheme(styled(AdminPageContent)`
    flex-grow: 1;
    overflow: hidden;
    padding: 0 !important;
    ${fromTheme('BreakPoint')} {
        padding: 0 24px !important;
    }
`)

export const CalendarContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',

        background: 'white',

        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',

        flexGrow: 1,

        border: `1px solid ${theme.BorderPrimary}`,

        [theme.BreakPoint]: {
            borderRadius: theme.Spacing(1.5),
        },
    }))
)

export const TimeScale = styled.div`
    position: relative;
    width: 54px;
    flex-basis: 54px;
    flex-grow: 0;
`
export const TimeScaleWithButtons = styled(TimeScale)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
    padding: 12px 8px;
`

export const CalendarHead = styled.div`
    flex: 0 0 auto;
    width: 100%;
    display: flex;
`

export const CalendarHeadDayHeaderContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        flex: '1 1 auto',
        borderBottom: '2px solid',
        borderColor: theme.Neutral_300,
        overflow: 'hidden',
        scrollbarWidth: 'thin',
        scrollbarGutter: 'stable',
    }))
)

export const CalendarContent = withTheme(
    styled.div(({ theme, isFullScreen }: { theme: AdminTheme; isFullScreen?: boolean }) => ({
        touchAction: 'pan-y',
        width: '100%',
        display: 'flex',
        scrollbarWidth: 'thin',
        overflow: 'auto',
        ...(isFullScreen ? { borderRadius: `0 0 ${theme.Spacing(1.5)} ${theme.Spacing(1.5)}` } : undefined),
    }))
)

export const CurrentTimeContainer = withTheme(styled.div`
    position: absolute;
    color: ${fromTheme('Secondary4_700')};
    ${fromTheme('Caption')};
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.9);
`)
