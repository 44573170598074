import moment from 'moment'
import { FC, useContext, useMemo } from 'react'
import { CalendarZoomContext, DecoratedCalendarEntry } from '../../../components/ui-kit/calendar/constants'
import { createLayout } from '../../../services/create-layout'
import { DayContainer, Hour } from '../../../components/ui-kit/calendar/CalendarDayComponents'
import { DayEvent } from './DayEvent'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'

const StyledHour = withTheme(
    styled(Hour)(({ theme }: { theme: AdminTheme }) => ({
        '&.day-hour': {
            backgroundColor: theme.SurfaceColor,
            borderLeft: `1px solid ${theme.BorderPrimary}`,
            '&:not(:first-of-type)': {
                borderTop: `1px solid ${theme.BorderPrimary}`,
            },
        },
    }))
)

const StyledDayContainer = withTheme(
    styled(DayContainer)(({ theme }: { theme: AdminTheme }) => ({
        borderTop: `1px solid ${theme.BorderPrimary}`,
        height: 'unset',
    }))
)

interface WeekDayProps {
    day: moment.Moment
    today: moment.Moment
    events: DecoratedCalendarEntry[]
    workingHours: string[]
    onClickEvent: (id: string) => void
}

export const WeekDay: FC<WeekDayProps> = ({ day, today, events, workingHours, onClickEvent }) => {
    const layout = useMemo(() => createLayout(events), [events])
    const { hourHeight } = useContext(CalendarZoomContext)

    return (
        <StyledDayContainer hourHeight={hourHeight}>
            {workingHours.map((h) => (
                <StyledHour className="day-hour" key={h} hourHeight={hourHeight} />
            ))}
            {layout.map((e, i) => (
                <DayEvent key={i} event={e} onClick={onClickEvent} workingHoursLength={workingHours.length} />
            ))}
        </StyledDayContainer>
    )
}
