import styled from '@emotion/styled'
import { ReactComponent as Ribbon } from '../../icons/Ribbon.svg'
import { FC, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const StyledMinupRibbon = styled(Ribbon)(() => ({
    position: 'absolute',
    zIndex: 1,
    top: -4,
    right: -8,
    pointerEvents: 'none',
    '&.clickable': {
        'path, circle': {
            pointerEvents: 'auto',
            cursor: 'pointer',
        },
    },
}))

export const RIBBON_EVENT_NAME = 'openRibbon'
export const MINUP_RIBBON_ID = 'minup-ribbon'

export const MinupRibbon: FC = () => {
    const location = useLocation()
    const { i18n } = useTranslation()
    const isEmbed = useMemo(() => {
        return location.pathname.startsWith('/embed')
    }, [location])

    const handleClick = useCallback(() => {
        const event = new CustomEvent(RIBBON_EVENT_NAME, {
            detail: { open: true },
        })
        window.dispatchEvent(event)
    }, [])

    const openMinup = useCallback(() => {
        window.open(
            `https://minup.io/${i18n.language}/?utm_source=referral&utm_medium=plg&utm_campaign=ribbon`,
            '_blank'
        )
    }, [i18n.language])

    return (
        <StyledMinupRibbon
            id={MINUP_RIBBON_ID}
            onClick={isEmbed ? openMinup : handleClick}
            className={isEmbed ? 'clickable' : ''}
        />
    )
}
