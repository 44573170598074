import styled from '@emotion/styled'
import axios from 'axios'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Route, Switch, useParams } from 'react-router'
import { LoadingScreen } from '../../components/ui-kit/comopnents/LoadingScreen'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../theme/theme'
import { ServiceSelector } from './ServiceSelector'
import i18n from '../../i18n'
import { EventsEmbedList } from './events/EventsEmbedList'
import { EventPage } from './events/EventPage'
import { useTheme } from '../../theme/ThemeProvider'
import { MinupRibbon } from '../../components/helpers/MinupRibbon'
import { ServicePage } from './services/ServicePage'
import { BusinessPublicDto } from '../../bookingpage/types/business'

const BookingPageContainer = withTheme(
    styled.div(({ theme, hideBorder }: { theme: AdminTheme; hideBorder?: boolean }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        [theme.BookingPageBreakPoint]: {
            ...(!hideBorder ? { padding: 3 } : undefined),
        },
    }))
)

const BookingPageContent = withTheme(
    styled.div(({ hideBorder, theme }: { hideBorder?: boolean; theme: AdminTheme }) => ({
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden auto',
        backgroundColor: theme.SurfaceColor,
        color: theme.ContentPrimary,
        [theme.BookingPageBreakPoint]: {
            ...(!hideBorder
                ? {
                      borderRadius: theme.Spacing(1),
                      border: `1px solid ${theme.BorderPrimary}`,
                      boxShadow: theme.Elevation_100,
                  }
                : undefined),
        },
    }))
)

const BookingPageWithStickyFooter = styled(BookingPageContent)(() => ({
    display: 'flex',
    flexDirection: 'column',
}))

export const EmbeddedBookingPage: FunctionComponent = () => {
    const params = useParams<{ handle: string }>()
    const [loading, setLoading] = useState(true)
    const [business, setBusiness] = useState<BusinessPublicDto>()
    useTheme(business?.theme || 'minup', business?.customTheme)

    const { hideBorder, hideTitle, directServiceLink, isCategory } = useMemo(() => {
        const search = new URLSearchParams(window.location.search)
        const pathName = window.location.pathname.replace(/\/+$/, '')
        const isAllServices = pathName === `/embed/${params.handle}`
        const isAllEvents = pathName === `/embed/${params.handle}/events`
        const isCategory = pathName.includes('/category/')
        return {
            hideBorder: search.get('hideBorder') === 'true',
            hideTitle: search.get('hideTitle') === 'true',
            directServiceLink: !isAllServices && !isAllEvents && !isCategory,
            isCategory,
        }
    }, [params.handle])

    useEffect(() => {
        ;(async () => {
            await i18n.loadNamespaces('bookingpage')
            try {
                const handle = encodeURIComponent(params.handle)
                if (handle) {
                    const response = await axios.get(`/api/book/byhandle/${handle}`)
                    setBusiness(response.data)
                    if (response.data && i18n.language !== response.data.bookingPageLanguage) {
                        await i18n.changeLanguage(response.data.bookingPageLanguage)
                    }
                }
            } catch {}
            setLoading(false)
        })()
    }, [params.handle])

    return loading ? (
        <LoadingScreen />
    ) : business ? (
        <BookingPageContainer hideBorder={hideBorder}>
            <Switch>
                <Route path={['/embed/:handle', '/embed/:handle/category/:categoryId']} exact>
                    <BookingPageWithStickyFooter hideBorder={hideBorder}>
                        {!business.hideMinupBranding ? <MinupRibbon /> : null}
                        <ServiceSelector business={business} embedded hideTitle={hideTitle} />
                    </BookingPageWithStickyFooter>
                </Route>
                <Route path="/embed/:handle/service/:serviceId">
                    <BookingPageWithStickyFooter hideBorder={hideBorder}>
                        <ServicePage
                            business={business}
                            hideTitle={hideTitle}
                            directServiceLink={directServiceLink}
                            fromCategory={isCategory}
                        />
                    </BookingPageWithStickyFooter>
                </Route>
                <Route path="/embed/:handle/events" exact>
                    <BookingPageContent hideBorder={hideBorder} className="p">
                        {!business.hideMinupBranding ? <MinupRibbon /> : null}
                        <EventsEmbedList business={business} hideTitle={hideTitle} />
                    </BookingPageContent>
                </Route>
                <Route path="/embed/:handle/events/:eventId/:occurrenceId?">
                    <BookingPageWithStickyFooter hideBorder={hideBorder}>
                        <EventPage business={business} hideBackButton={directServiceLink} />
                    </BookingPageWithStickyFooter>
                </Route>
            </Switch>
        </BookingPageContainer>
    ) : (
        <div>
            <h3>404</h3>
        </div>
    )
}
