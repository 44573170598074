import { Moment } from 'moment'
import { FC } from 'react'
import { UcFirst } from '../../../pages/booking/components/UcFirst'
import { classNames } from '../../../services/class-names'

export const FromToDisplay: FC<{ from: Moment; to: Moment; className?: string }> = ({ from, to, className }) => {
    return (
        <span className={classNames('content-primary', 'semibold', 'centered', className)}>
            {from.date()} - {to.date()}&nbsp;
            <UcFirst>{to.format('MMMM')}</UcFirst>
            <span className="only-desktop">, {to.year()}</span>
        </span>
    )
}
