import DOMPurify from 'dompurify'
import { useCallback } from 'react'

// Create strict instance disabling style
const StrictDOMPurifyInstance = DOMPurify(window)
StrictDOMPurifyInstance.setConfig({
    ALLOWED_TAGS: [],
})

export function useSanitizeString() {
    return useCallback((dirty: string) => {
        return StrictDOMPurifyInstance.sanitize(dirty)
    }, [])
}
