import styled from '@emotion/styled'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'
import { Flex } from '../../../components/helpers/Flex'

export const WizardStepFooter = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        width: '100%',
        padding: '24px 20px',

        '& > div:last-of-type': {
            display: 'flex',
        },

        borderTop: `1px solid ${theme.BorderPrimary}`,

        [theme.BreakPoint]: {
            padding: theme.Spacing(3),
        },
    }))
)
