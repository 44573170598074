import { FC, useCallback, useEffect, useState } from 'react'
import { useBusiness } from '../../../hooks/use-business'
import { ModalBackground } from '../../ui-kit/admin/modal/ModalBackground'
import { ModalContent } from '../../ui-kit/admin/modal/ModalContent'
import { ModalContainer } from '../../ui-kit/admin/modal/ModalBody'
import { ModalHeader } from '../../ui-kit/admin/modal/ModalHeader'
import { ModalFooter } from '../../ui-kit/admin/modal/ModalFooter'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '../../ui-kit/comopnents/Icon'
import { Flex } from '../../helpers/Flex'
import { PrimaryButton } from '../../ui-kit/button/primary'
import moment from 'moment'
import { Column } from '../layout/Column'

const TestModeWarningModal: FC<{ onDismiss: () => void }> = ({ onDismiss }) => {
    return (
        <ModalBackground>
            <ModalContainer width={440}>
                <ModalHeader>
                    <Flex justifyContent="flex-start">
                        <Icon
                            className="mrs only-desktop"
                            icon={faExclamationTriangle}
                            variant={'ContentWarning'}
                            fontSize={16}
                            containerSize={2.5}
                        />
                        <h2>Teszt üzemmód</h2>
                    </Flex>
                </ModalHeader>
                <ModalContent>
                    <Column>
                        <p>
                            A Minup-ot jelenleg teszt üzemmódban használod. Az <b>emlékeztető emailek</b> a lefoglalt
                            időpontokról továbbra is <b>a Salonic fiókodon keresztül kerülnek kiküldésre</b>.
                        </p>
                        <p>
                            Amennyiben elégedett vagy a Minup rendszerével és szeretnél véglegesen átállni, jelezz
                            nekünk a <a href="mailto:hello@minup.io">hello@minup.io</a> email címen vagy
                            ügyfélszolgálati nyitvatartási időben a{' '}
                            <a className="nowrap" href="tel:+36202681236">
                                +36 20 268 1236
                            </a>{' '}
                            telefonszámon.
                        </p>
                    </Column>
                </ModalContent>
                <ModalFooter>
                    <Flex
                        grow={1}
                        justifyContent="flex-end"
                        desktopDirection="row"
                        flexDirection="column-reverse"
                        alignItems="stretch"
                        desktopAlignItems="center"
                        gap={2}
                    >
                        <PrimaryButton type="button" onClick={onDismiss}>
                            Rendben
                        </PrimaryButton>
                    </Flex>
                </ModalFooter>
            </ModalContainer>
        </ModalBackground>
    )
}

const PAYMENT_METHOD_SNOOZE_DATE_KEY = 'test_mode_snooze_date'
const SNOOZE_HOURS = 6

export const TestModeWarning: FC = () => {
    const business = useBusiness()
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (moment(business?.createdAt).isBefore(moment().subtract(10, 'minutes')) && business?.disableReminders) {
            const snoozeDate = localStorage.getItem(PAYMENT_METHOD_SNOOZE_DATE_KEY)

            if (!snoozeDate) {
                setShowModal(true)
                return
            }

            const date = moment(snoozeDate, moment.ISO_8601, true)
            setShowModal(!date.isValid() || moment().diff(date, 'hours') >= SNOOZE_HOURS)
        }
    }, [business?.createdAt, business?.disableReminders])

    const onDismiss = useCallback(() => {
        setShowModal(false)
        window.localStorage.setItem(PAYMENT_METHOD_SNOOZE_DATE_KEY, moment().toISOString())
    }, [])

    return showModal ? <TestModeWarningModal onDismiss={onDismiss} /> : null
}
