import { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useClientTimezone } from '../../../hooks/useClientTimezone'
import { captureException } from '../../../appInsights'
import { ContactInfo } from '../components/ContactInfoScreen'
import { useDispatch } from 'react-redux'
import { createAction2 } from '../../../store/utils/create-action'
import { BookingPageActionTypes } from '../../../store/booking-page/actions'
import { ERROR_MESSAGE_SEPARATOR, useGetErrorMessage } from '../../../services/getErrorMessage'
import { useToast } from '../../../hooks/use-toast'
import { EventPublicDto } from '../../../store/events/types'
import { OverviewScreen } from '../components/OverviewScreen'
import { SuccessScreen } from '../components/SuccessScreen'
import { useBeforeUnload } from '../../../hooks/use-before-unload'
import { BusinessPublicDto } from '../../../bookingpage/types/business'
import { PaymentMethod } from '../../../common/types/enums'
import { sleep } from '../../../common/helpers/sleep'

export const EventOverview: FC<{
    contactInfo: ContactInfo
    answers: string
    event: EventPublicDto
    business: BusinessPublicDto
    isEmbed?: boolean
}> = ({ contactInfo, answers, event, business, isEmbed }) => {
    const { handle, eventId, occurrenceId } = useParams<{ handle: string; eventId: string; occurrenceId: string }>()
    const [googleCalendarLink, setGoogleCalendarLink] = useState('')
    const [success, setSuccess] = useState(false)
    const [navigating, setNavigating] = useState(false)

    const occurrence = event.occurrences.find((occurrence) => occurrence.id === occurrenceId)

    const { clientTimezone } = useClientTimezone()
    const history = useHistory()

    useEffect(() => {
        return () => {
            if (success && history.action === 'POP') {
                history.push(`/${isEmbed ? 'embed' : 'book'}/${handle}`)
            }
        }
    }, [handle, history, isEmbed, success])

    useBeforeUnload(success === false && !navigating)

    useEffect(() => {
        if (!contactInfo) {
            history.push(`/${isEmbed ? 'embed' : 'book'}/${handle}/events/${eventId}/dates`)
        }
    }, [contactInfo, eventId, handle, history, isEmbed, occurrenceId, success])

    const dispatch = useDispatch()
    const onSuccess = useCallback(() => {
        dispatch(createAction2({ type: BookingPageActionTypes.BOOKING_SUCCESSFUL }))
    }, [dispatch])
    const getErrorMessage = useGetErrorMessage()

    const toast = useToast()
    const onSubmit = useCallback(
        async (
            contactInfo: ContactInfo,
            _clientTimezone: string,
            recaptchaToken: string | null | undefined,
            paymentMethod: PaymentMethod
        ) => {
            const response = await fetch(`/api/book/${handle}/events/schedule`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    recaptchaToken,
                    isEmbed: isEmbed,
                    occurrenceId,
                    eventId,
                    name: contactInfo.clientName,
                    email: contactInfo.clientEmail,
                    phone: contactInfo.clientPhone,
                    note: contactInfo.clientNote,
                    seats: contactInfo.seats,
                    billingName: contactInfo.billingName,
                    billingCountry: contactInfo.billingCountry,
                    billingCountryCode: contactInfo.billingCountryCode,
                    billingCity: contactInfo.billingCity,
                    billingAddress: contactInfo.billingAddress,
                    billingZipCode: contactInfo.billingZipCode,
                    isBusiness: contactInfo.isBusiness,
                    taxID: contactInfo.taxID,
                    answers,
                    clientTimezone,
                    paymentMethod,
                }),
            })

            const data = await response.clone().json()
            if (response.ok) {
                if (business.paymentSettings?.active && data.paymentUrl) {
                    setNavigating(true)
                    // wait for state to update
                    await sleep(10)
                    window.location.href = data.paymentUrl
                    return
                } else {
                    setGoogleCalendarLink(data.googleCalendarLink)
                    onSuccess()
                    setSuccess(true)
                }
            } else {
                if (response.status === 400) {
                    try {
                        const r = await response.clone().json()
                        const errorMessage = (await getErrorMessage(r.error)).split(ERROR_MESSAGE_SEPARATOR)
                        toast.error(errorMessage[0], errorMessage[1] || '')
                    } catch {
                        const responseText = await response.clone().text()
                        captureException(new Error('Appointment schedule failed'))
                        toast.error(await getErrorMessage(responseText), '')
                    }
                } else {
                    const responseText = await response.clone().text()
                    captureException(new Error('Appointment schedule failed'))
                    toast.error(await getErrorMessage(responseText), '')
                }
            }
        },
        [
            answers,
            business.paymentSettings?.active,
            clientTimezone,
            eventId,
            getErrorMessage,
            handle,
            isEmbed,
            occurrenceId,
            onSuccess,
            toast,
        ]
    )

    return success ? (
        <SuccessScreen
            googleCalendarLink={googleCalendarLink}
            clientEmail={contactInfo.clientEmail}
            business={business}
            canCancelUntil={event.canCancelUntil}
            approvalRequired={event.approvalRequired}
        />
    ) : (
        <OverviewScreen
            contactInfo={contactInfo}
            event={event}
            selectedSlot={occurrence}
            business={business}
            onSubmit={onSubmit}
        />
    )
}
