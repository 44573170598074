import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { AdminTheme } from '../../../theme/theme'
import { classNames } from '../../../services/class-names'

export interface SegmentOption<T> {
    value: T
    label: string | ReactNode
    icon?: ReactNode
}

const SegmentButton = withTheme(
    styled.div(
        ({
            theme,
            selected,
            grow,
            buttonMinWidth,
        }: {
            theme: AdminTheme
            selected: boolean
            grow?: boolean
            buttonMinWidth?: number
        }) => ({
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: theme.Spacing(1),
            padding: `${theme.Spacing(1)} ${theme.Spacing(1.5)}`,
            cursor: 'pointer',
            minHeight: theme.Spacing(5),
            minWidth: buttonMinWidth || theme.Spacing(5),

            ...theme._BodyMedium,
            '&:not(:first-of-type)': {
                marginLeft: -1,
            },
            '&:focus-visible': {
                border: `1px solid ${theme.ThemeColor}`,
                outline: 'none',
                boxShadow: `0 0 0 4px ${theme.FocusOutline}`,
                zIndex: 1,
            },
            ...(grow
                ? {
                      flexGrow: 1,
                      justifyContent: 'center',
                  }
                : undefined),
            borderRadius: theme.Spacing(1),
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#00000000',
            ...(selected
                ? {
                      backgroundColor: theme.SurfaceColor,
                      color: theme.ContentPrimary,
                      outline: `1px solid ${theme.BorderTertiary}`,
                  }
                : undefined),
        })
    )
)

const SegmentContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        whiteSpace: 'nowrap',
        display: 'flex',
        backgroundColor: theme.BackgroundNeutralSecondary,
        color: theme.ContentSecondary,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.BorderSecondary,
        borderRadius: theme.Spacing(1),
    }))
)

export function SegmentedPicker<T>({
    options,
    value,
    grow,
    buttonMinWidth,
    onChange,
}: {
    options: SegmentOption<T>[]
    value: T
    grow?: boolean
    buttonMinWidth?: number
    onChange: (value: T) => void
}) {
    return (
        <SegmentContainer>
            {options.map((option) => (
                <SegmentButton
                    key={'' + option.value}
                    role="button"
                    tabIndex={0}
                    onClick={() => onChange(option.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault()
                            onChange(option.value)
                        }
                    }}
                    selected={value === option.value}
                    grow={grow}
                    buttonMinWidth={buttonMinWidth}
                >
                    {option.icon}
                    <span className={classNames(option.icon ? 'only-desktop' : null)}>{option.label}</span>
                </SegmentButton>
            ))}
        </SegmentContainer>
    )
}
