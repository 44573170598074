import { createContext, FC, useCallback, useState } from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Redirect, Route, Switch } from 'react-router'
import { LoadingScreenContext } from './LoadingScreenContext'
import { LanguageSelector, Step1 } from './steps/Step1'
import logoWithText from '../../../icons/logo-with-text.svg'

import { AdminTheme } from '../../../theme/theme'
import { LoadingScreen } from '../../../components/ui-kit/comopnents/LoadingScreen'
import { Pair } from './steps/Pair'
import { Step2 } from './steps/Step2'
import { Flex } from '../../../components/helpers/Flex'
import i18n, { changeLanguage, LanguageCodes } from '../../../i18n'

export const WizardProgressContext = createContext({
    numberOfSteps: 4,
    currentStep: 0,
    setCurrentStep: (step: number) => {},
})

const WizardPageContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,

        overflowY: 'auto',
        overflowX: 'hidden',

        backgroundColor: theme.BackgroundColor,

        flexDirection: 'column',
        justifyContent: 'flex-start',

        zIndex: 99999,
    }))
)

const WizardHeader = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        width: '100%',
        boxShadow: 'inset 0px -1px 0px #e5e5e5',

        backgroundColor: theme.ContentInverted,

        padding: theme.Spacing(2.5),

        img: {
            height: 28,
        },
    }))
)

const WizardStepContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        width: '100%',

        backgroundColor: theme.ContentInverted,
        boxShadow: 'inset 0px -1px 0px #e5e5e5',

        flexShrink: 1,

        [theme.BreakPoint]: {
            width: 550,
            margin: theme.Spacing(2.5),
            border: `1px solid ${theme.BorderPrimary}`,
            borderRadius: theme.Spacing(1.5),
        },
    }))
)

export const WizardPage: FC = () => {
    const [showLoadingScreen, setShowLoadingScreen] = useState(false)

    const [languageCode, setLanguageCode] = useState(i18n.language || LanguageCodes.Hu)

    const onChangeLanguage = useCallback(
        (lang) => {
            changeLanguage(lang)
            setLanguageCode(lang)
        },
        [setLanguageCode]
    )

    return (
        <WizardPageContainer>
            <WizardHeader justifyContent="space-between" desktopJustifyContent="center">
                <img src={logoWithText} alt="minup logo" />
                <LanguageSelector className="only-mobile" languageCode={languageCode} onChange={onChangeLanguage} />
            </WizardHeader>
            <WizardStepContainer>
                <LoadingScreenContext.Provider value={{ show: setShowLoadingScreen }}>
                    <Switch>
                        <Route path="/admin/wizard/" exact>
                            <Redirect to="/admin/wizard/1" />
                        </Route>
                        <Route path="/admin/wizard/pair" component={Pair} />
                        <Route path="/admin/wizard/1">
                            <Step1 languageCode={languageCode} onChangeLanguage={onChangeLanguage} />
                        </Route>
                        <Route path="/admin/wizard/2" component={Step2} />
                    </Switch>
                </LoadingScreenContext.Provider>
                {showLoadingScreen && <LoadingScreen lightBackground />}
            </WizardStepContainer>
        </WizardPageContainer>
    )
}
