import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'

export const WysiwygDisplay = withTheme(
    styled.div({
        'p:empty::after': {
            content: '"\\00a0"',
        },
    })
)
