import { useMemo } from 'react'
import { Business, CreateBusinessDto, FeatureFlags } from '../../store/auth/types'
import { useAuthorizedApi } from './use-authorized-api'
import { CustomTheme } from '../../common/types/custom-theme'
import { MigrationState } from '../../common/types/enums'

export interface BusinessUpdateOptions {
    updateServices?: boolean
    updateOpeningHours?: boolean
    updateCompanyDetails?: boolean
}

export function useBusinessApi() {
    const { get, put, post, patch, _delete } = useAuthorizedApi()

    return useMemo(() => {
        return {
            get: (language?: string, timezone?: string, skipCheckEmail?: boolean) =>
                get<{ business: Business; featureFlags: FeatureFlags }>('/api/business', {
                    params: { language, timezone, skipCheckEmail },
                }),
            update: (business: Business, options?: BusinessUpdateOptions) =>
                put('/api/business', { ...business, ...options }),
            create: (business: CreateBusinessDto) => post('/api/business', { ...business }),
            createFromSalonic: (apiKey: string, urlHandle?: string) =>
                post('/api/business/fromSalonic', { apiKey, urlHandle }),
            updateTimezone: (timezone: string) => put('/api/business/timezone', { timezone }),
            updateLanguage: (language: string) => put('/api/business/language', { language }),
            updateCountryCode: (countryCode: string) => put('/api/business/countryCode', { countryCode }),
            updateServiceType: (serviceType: string, industry: string, isRegistration: boolean) =>
                put<Business>('/api/business/serviceType', { serviceType, industry, isRegistration }),
            uploadPicture: (imageId: string, type: 'profile' | 'cover' | 'og') =>
                post('/api/business/uploadPicture', { imageId, type }),
            removeProfileImage: () => _delete('/api/business/removeProfileImage'),
            removeCoverImage: () => _delete('/api/business/removeCoverImage'),
            removeOgImage: () => _delete('/api/business/removeOgImage'),
            sendVerificationEmail: () => post(`/api/business/sendverificationemail`),
            verifyCode: (code: string) => post('/api/business/verifycode', { code }),
            deleteAccount: (exitInterview: any) => _delete('/api/business/delete', { ...exitInterview }),
            setAppearance: (theme: string, metaTitle: string, metaDescription: string) =>
                patch('/api/business/appearance', { theme, metaTitle, metaDescription }),
            setCustomTheme: (customTheme: CustomTheme) => patch('/api/business/customTheme', { ...customTheme }),
            initiateChangeEmail: (newEmail: string) => post(`/api/business/initiateEmailChange`, { newEmail }),
            verifyChangeEmail: (newEmail: string, code: string, password: string | null = null) =>
                post(`/api/business/verifyEmailChange`, { newEmail, code, password }),
            cancelRegistration: () => post('/api/business/cancelRegistration'),
            getMigrationState: () =>
                get<{ migrationState: MigrationState | null; migrationProgress: string | null } | undefined>(
                    '/api/business/migrationState'
                ),
        }
    }, [get, put, post, patch, _delete])
}
