import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import {
    createContext,
    forwardRef,
    PropsWithChildren,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'
import { classNames } from '../../../services/class-names'
import { AdminTheme } from '../../../theme/theme'
import { Flex } from '../../helpers/Flex'
import { Icon } from './Icon'

const AccordionContext = createContext<{
    open: boolean | undefined
    setOpen: (open: (boolean | undefined) | ((current: boolean | undefined) => boolean | undefined)) => void
}>({
    open: false,
    setOpen: () => {},
})

const _AccordionHeaderContainer = styled.div(({ theme, open }: { theme: AdminTheme; open: boolean | undefined }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.InteractiveSecondaryHover,
    },
    '&:active': {
        backgroundColor: theme.InteractiveSecondaryActive,
    },
    '.title-icon': {
        fontSize: 20,
    },
    '&.compact': {
        cursor: 'default',
        padding: theme.Spacing(2),
        borderBottomWidth: open ? 1 : undefined,
        borderBottomStyle: open ? 'solid' : undefined,
        borderBottomColor: open ? theme.BorderPrimary : undefined,
        '&:hover': {
            backgroundColor: 'unset',
        },
        '&:active': {
            backgroundColor: 'unset',
        },
        '.indicator': {
            cursor: 'pointer',
        },
        [theme.BreakPoint]: {
            padding: `${theme.Spacing(2)} ${theme.Spacing(4)}`,
        },
    },

    border: `1px solid #00000000`,
    '&:focus-visible': {
        border: `1px solid ${theme.ThemeColor}`,
        borderRadius: `${theme.Spacing(1)} !important`,
        outline: 'none',
        boxShadow: `0 0 0 4px ${theme.FocusOutline}`,
    },
    padding: theme.Spacing(2),
    [theme.BreakPoint]: {
        padding: theme.Spacing(4),
    },
}))

const AccordionHeaderContainer = withTheme(_AccordionHeaderContainer)

const AccordionContent = styled('div')(() => ({}))
const AccordionBody = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        padding: `0 ${theme.Spacing(4)} ${theme.Spacing(3)} ${theme.Spacing(4)}`,
        '&.compact': {
            padding: theme.Spacing(2),
            [theme.BreakPoint]: {
                padding: `${theme.Spacing(3)} ${theme.Spacing(4)}`,
            },
        },
    }))
)
export const ACCORDION_ANIMATION_DELAY = 400
const AccordionContainer = withTheme(
    styled('div')(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        backgroundColor: 'white',
        borderRadius: theme.Spacing(1),
        border: `1px solid ${theme.BorderPrimary}`,
        [_AccordionHeaderContainer as any]: {
            borderRadius: theme.Spacing(1),
            '.indicator': {
                transition: `transform ${ACCORDION_ANIMATION_DELAY / 2}ms linear`,
            },
        },
        [AccordionContent as any]: {
            maxHeight: 0,
            overflow: 'hidden',
            transition: `max-height ${ACCORDION_ANIMATION_DELAY}ms ease-out`,
        },
        '&.open': {
            [_AccordionHeaderContainer as any]: {
                borderRadius: `${theme.Spacing(1)} ${theme.Spacing(1)} 0 0`,
                '.indicator': {
                    transform: 'rotate(180deg)',
                },
            },
            [AccordionContent as any]: {
                transitionTimingFunction: 'ease-in',
            },
        },
        '&.can-overflow': {
            [AccordionContent as any]: {
                overflow: 'visible',
            },
        },
        '&.compact': {
            backgroundColor: theme.Neutral_100,
        },
    }))
)

const AccordionHeader = forwardRef<
    HTMLDivElement,
    {
        id?: string
        title: string | ReactNode
        icon?: ReactNode
        badge?: ReactNode
        className?: string
        compact?: boolean
        headerClickable: boolean
        dropdown?: ReactNode
    }
>(({ id, title, icon, badge, className, compact, headerClickable, dropdown }, ref) => {
    const { setOpen, open } = useContext(AccordionContext)
    const onClick = useCallback(() => {
        setOpen((open) => !open)
    }, [setOpen])

    const onKeyPress = useCallback(
        (e) => {
            if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault()
                onClick()
            }
        },
        [onClick]
    )

    return (
        <AccordionHeaderContainer
            open={open}
            className={className}
            ref={ref}
            id={id}
            onClick={headerClickable ? onClick : undefined}
            tabIndex={headerClickable ? 0 : undefined}
            onKeyPress={headerClickable ? onKeyPress : undefined}
        >
            <Flex justifyContent="flex-start" gap={1.5}>
                <span className="title-icon">{icon}</span>
                <h3>{title}</h3>
                {badge}
            </Flex>
            {compact ? (
                <Flex gap={2}>
                    {dropdown}
                    <Icon
                        tabIndex={0}
                        className="indicator"
                        icon={faChevronDown}
                        onClick={onClick}
                        onKeyPress={onKeyPress}
                    />
                </Flex>
            ) : (
                <Icon className="indicator" icon={faChevronDown} />
            )}
        </AccordionHeaderContainer>
    )
})

type BaseAccordionProps = PropsWithChildren<{
    title: string | ReactNode
    icon?: ReactNode
    badge?: ReactNode
    className?: string
    compact?: boolean
    opened?: boolean
    headerClickable?: boolean
    dropdown?: ReactNode
    onOpen?: (open: boolean) => void
}>

export type AccordionRef = {
    openAndScrollTo: () => void
}

type AccordionProps =
    | (BaseAccordionProps & {
          id?: string
          saveState?: false
      })
    | (BaseAccordionProps & {
          id: string
          saveState: true
      })

const ACCORDION_STATE_PREFIX = 'ACCORDION_STATE_'

export const Accordion = forwardRef<AccordionRef, AccordionProps>(
    (
        {
            id,
            title,
            icon,
            badge,
            className,
            compact,
            opened,
            headerClickable = true,
            dropdown,
            saveState,
            onOpen,
            children,
            ...props
        },
        ref
    ) => {
        const savedState =
            saveState && localStorage.getItem(ACCORDION_STATE_PREFIX + id) !== null
                ? localStorage.getItem(ACCORDION_STATE_PREFIX + id) === 'open'
                : undefined
        const [open, setOpen] = useState<boolean | undefined>(savedState !== undefined ? savedState : opened)
        const contentRef = useRef<HTMLDivElement | undefined>()
        const headerRef = useRef<HTMLDivElement | undefined>()
        const firstRef = useRef(true)

        useImperativeHandle(ref, () => ({
            openAndScrollTo: () => {
                setOpen(true)
                headerRef.current?.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                })
            },
        }))

        useEffect(() => {
            if (saveState) {
                localStorage.setItem(ACCORDION_STATE_PREFIX + id, open ? 'open' : 'closed')
            }
        }, [id, open, saveState])

        useEffect(() => {
            onOpen && onOpen(!!open)
            if (!open && firstRef.current) {
                return
            }
            firstRef.current = false

            let timeout: any = undefined
            if (open === true) {
                if (contentRef.current) {
                    contentRef.current.style.display = 'block'
                    setTimeout(() => {
                        if (contentRef.current) {
                            contentRef.current.style.maxHeight = '1000px'
                        }
                    }, 50)
                    setTimeout(() => {
                        if (contentRef.current) {
                            contentRef.current.style.maxHeight = 'none'
                        }
                    }, 50 + ACCORDION_ANIMATION_DELAY)
                }
            } else if (open === false) {
                if (contentRef.current) {
                    contentRef.current.style.maxHeight = '1000px'
                }
                setTimeout(() => {
                    if (contentRef.current) {
                        contentRef.current.style.maxHeight = '0'
                    }
                }, 50)
                setTimeout(() => {
                    if (contentRef.current) {
                        contentRef.current.style.display = 'none'
                    }
                }, 50 + ACCORDION_ANIMATION_DELAY)
                timeout = setTimeout(() => {
                    if (contentRef.current) {
                    }
                }, ACCORDION_ANIMATION_DELAY)
            }
            return () => {
                if (timeout) {
                    clearTimeout(timeout)
                }
            }
        }, [open, onOpen])

        const [canOverflow, setCanOverflow] = useState(false)
        useEffect(() => {
            if (open) {
                const timeout = setTimeout(() => {
                    setCanOverflow(true)
                }, ACCORDION_ANIMATION_DELAY)
                return () => clearTimeout(timeout)
            } else {
                setCanOverflow(false)
            }
        }, [open])

        return (
            <AccordionContext.Provider value={{ open, setOpen }}>
                <AccordionContainer
                    className={classNames(
                        className,
                        open ? 'open' : null,
                        canOverflow ? 'can-overflow' : null,
                        compact ? 'compact' : null
                    )}
                    id={id}
                    {...props}
                >
                    <>
                        <AccordionHeader
                            className={classNames(compact ? 'compact' : null)}
                            compact={compact}
                            headerClickable={headerClickable}
                            dropdown={dropdown}
                            ref={headerRef as any}
                            id={id ? id + '-header' : undefined}
                            title={title}
                            badge={badge}
                            icon={icon}
                        />
                        <AccordionContent ref={contentRef as any}>
                            <AccordionBody className={classNames(compact ? 'compact' : null)}>{children}</AccordionBody>
                        </AccordionContent>
                    </>
                </AccordionContainer>
            </AccordionContext.Provider>
        )
    }
)
