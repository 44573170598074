import { CSSProperties, DetailedHTMLProps, FC, HTMLAttributes, useEffect, useRef, useState } from 'react'

export const MultilineEllipsis: FC<
    { disableClamp?: boolean; lineHeight: number; reRender?: any } & DetailedHTMLProps<
        HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >
> = ({ disableClamp, lineHeight, reRender, children, style, ...rest }) => {
    const [clampStyle, setClampStyle] = useState<CSSProperties>()
    const ref = useRef<HTMLDivElement>()

    useEffect(() => {
        setClampStyle({})
        setTimeout(() => {
            if (!disableClamp) {
                const element = ref.current
                if (element) {
                    const height = element.clientHeight
                    setClampStyle({
                        overflow: 'hidden',
                        display: '-webkit-box',
                        overflowWrap: 'anywhere',
                        WebkitLineClamp: Math.max(1, Math.floor(height / lineHeight)),
                        WebkitBoxOrient: 'vertical',
                    })
                }
            } else {
                setClampStyle({})
            }
        }, 10)
    }, [disableClamp, lineHeight, reRender])

    return (
        <div
            ref={ref as any}
            {...rest}
            style={{
                ...style,
                ...clampStyle,
            }}
        >
            {children}
        </div>
    )
}
