import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { ModalBackground } from '../../../components/ui-kit/admin/modal/ModalBackground'
import { withTheme } from '@emotion/react'
import { ModalContainer } from '../../../components/ui-kit/admin/modal/ModalBody'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { ModalIllustration } from '../../../components/ui-kit/admin/modal/ModalIllustration'
import { EventPublicDto, OccurrencePublicDto } from '../../../store/events/types'
import {
    faCalendarAlt,
    faMapMarkerAlt,
    faTimes,
    faUserFriends,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { ModalContent } from '../../../components/ui-kit/admin/modal/ModalContent'
import { ModalFooter } from '../../../components/ui-kit/admin/modal/ModalFooter'
import { Flex } from '../../../components/helpers/Flex'
import { PrimaryButton, PrimaryButtonNavLink } from '../../../components/ui-kit/button/primary'
import { Trans, useTranslation } from 'react-i18next'
import { Column } from '../../../components/admin/layout/Column'
import moment from 'moment'
import { ModalHeader } from '../../../components/ui-kit/admin/modal/ModalHeader'
import { faCreditCard } from '@fortawesome/free-regular-svg-icons'
import { useOnClickOutside } from '../../../hooks/use-on-click-outside'
import { useParams } from 'react-router-dom'
import { LoadingScreen } from '../../../components/ui-kit/comopnents/LoadingScreen'
import { BookingPageEventCover } from '../components/BookingPageEventCover'
import { getEventBookUrl } from '../events/helpers/get-event-book-url'
import { classNames } from '../../../services/class-names'
import { NeutralIconButton } from '../../../components/ui-kit/button/NeutralIconButton'
import { Icon } from '../../../components/ui-kit/comopnents/Icon'
import { captureException } from '../../../appInsights'
import { useToast } from '../../../hooks/use-toast'
import { useGetErrorMessage } from '../../../services/getErrorMessage'
import { EventCoverImage } from '../components/EventCoverImage'

const ColumnedContent = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        width: '100%',
        display: 'grid',
        gap: theme.Spacing(3),
        gridTemplateColumns: '1fr',
    }))
)

const IconBackground = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        flexShrink: 0,
        width: 44,
        height: 44,
        borderRadius: theme.Spacing(1),
        backgroundColor: theme.InteractiveSecondaryHover,
    }))
)

const SizedBookingPageEventCover = withTheme(
    styled(BookingPageEventCover)(() => ({
        aspectRatio: '339/95',
        '#cover': {
            height: '100%',
            aspectRatio: 'unset',
        },
    }))
)

const CloseButton = withTheme(
    styled(NeutralIconButton)(({ theme }: { theme: AdminTheme }) => ({
        zIndex: 1,
        position: 'absolute',
        top: 8,
        right: 8,
        color: `${theme.InteractiveCoverClose} !important`,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
        },
    }))
)

const StyledModalBackground = withTheme(
    styled(ModalBackground)(({ theme, isEmbed }: { theme: AdminTheme; isEmbed: boolean }) => ({
        backgroundColor: isEmbed ? theme.BackgroundSecondary : 'rgba(0, 0, 0, 0.5)',
    }))
)

const PopupItem: FC<{
    title: ReactNode
    icon: IconDefinition
    value: string | ReactNode
    valueClassName?: string
}> = ({ title, icon, value, valueClassName }) => {
    return (
        <Flex gap={1.5} justifyContent="flex-start">
            <IconBackground>
                <Icon containerSize={2} variant="InteractivePrimary" icon={icon} />
            </IconBackground>
            <Column spacing={0.5}>
                <span>{title}</span>
                <b className={classNames(valueClassName)}>{value}</b>
            </Column>
        </Flex>
    )
}

export const EventPopup: FC<{
    embedded?: boolean
    occurrence: OccurrencePublicDto
    onClose: (navigate?: string) => void
}> = ({ embedded, occurrence, onClose }) => {
    const modalRef = useRef<HTMLDivElement>(null)
    const { handle } = useParams<{ handle: string }>()
    const { t } = useTranslation('bookingpage')

    const [event, setEvent] = useState<EventPublicDto | undefined>()
    const modalSettings = embedded
        ? { width: 740, fullScreen: true, isEmbed: true }
        : { width: 339, fullScreen: false, isEmbed: false }
    const toast = useToast()
    const getErrorMessage = useGetErrorMessage()

    useEffect(() => {
        ;(async () => {
            try {
                const eventResponse = await fetch(`/api/book/byhandle/${handle}/events/${occurrence.eventId}`)
                if (eventResponse.ok) {
                    const occurrence = await eventResponse.json()
                    setEvent(occurrence)
                }
            } catch (e) {
                toast.error(await getErrorMessage(e))
                captureException(e)
                onClose()
            }
        })()
    }, [occurrence.eventId, handle, toast, t, onClose, getErrorMessage])

    useOnClickOutside(modalRef, () => onClose())
    const remaining = useMemo(
        () => Math.max(occurrence.maxParticipants - occurrence.currentParticipants, 0),
        [occurrence.currentParticipants, occurrence.maxParticipants]
    )

    return (
        <StyledModalBackground fullScreen={modalSettings.fullScreen} isEmbed={modalSettings.isEmbed}>
            <ModalContainer width={modalSettings.width} fullScreen={modalSettings.fullScreen} ref={modalRef}>
                {!event ? (
                    <Flex p={6}>
                        <LoadingScreen noBackground inline />
                    </Flex>
                ) : (
                    <>
                        <ModalIllustration>
                            <CloseButton
                                className="small"
                                icon={faTimes}
                                type="button"
                                onClick={() => onClose()}
                                aria-label={t('Close')}
                            />
                            <SizedBookingPageEventCover>
                                <EventCoverImage imagePublicId={occurrence.imagePublicId} id="cover" width="339" />
                            </SizedBookingPageEventCover>
                        </ModalIllustration>
                        <ModalHeader>
                            <h1 className="heading small">{occurrence.title}</h1>
                        </ModalHeader>
                        <ModalContent className="px">
                            <ColumnedContent className="small">
                                <PopupItem
                                    title={<Trans ns="bookingpage">Date and time</Trans>}
                                    icon={faCalendarAlt}
                                    value={`${moment(occurrence.from).format('YYYY.MM.DD')}, ${moment(
                                        occurrence.from
                                    ).format('HH:mm')}-${moment(occurrence.to).format('HH:mm')}`}
                                />
                                <PopupItem
                                    title={<Trans ns="bookingpage">Max. attendees</Trans>}
                                    icon={faUserFriends}
                                    valueClassName={
                                        occurrence.currentParticipants !== -1 && remaining === 1 ? 'danger' : ''
                                    }
                                    value={
                                        occurrence.currentParticipants === -1 ? (
                                            `${occurrence.maxParticipants}`
                                        ) : (
                                            <>
                                                {remaining}/{occurrence.maxParticipants}{' '}
                                                <Trans ns="bookingpage">free seats</Trans>
                                            </>
                                        )
                                    }
                                />
                                {event.location ? (
                                    <PopupItem
                                        title={<Trans ns="bookingpage">Location</Trans>}
                                        icon={faMapMarkerAlt}
                                        value={event.location}
                                    />
                                ) : null}
                                {occurrence.price ? (
                                    <PopupItem
                                        title={<Trans ns="bookingpage">Price</Trans>}
                                        icon={faCreditCard}
                                        value={occurrence.price}
                                    />
                                ) : null}
                            </ColumnedContent>
                        </ModalContent>
                        <ModalFooter>
                            <Flex flexDirection="column" grow={1} gap={1}>
                                {occurrence.soldOut ? (
                                    <PrimaryButton
                                        type="button"
                                        disabled
                                        className="w100"
                                        style={{ cursor: 'not-allowed' }}
                                    >
                                        <Trans ns="bookingpage">Sold out</Trans>
                                    </PrimaryButton>
                                ) : (
                                    <PrimaryButtonNavLink
                                        className={classNames('w100', occurrence.pastEvent ? 'disabled' : '')}
                                        onClick={(e) => occurrence.pastEvent && e.preventDefault()}
                                        style={{
                                            ...(occurrence.pastEvent
                                                ? {
                                                      pointerEvents: 'none',
                                                      touchAction: 'none',
                                                      cursor: 'not-allowed',
                                                  }
                                                : null),
                                        }}
                                        to={getEventBookUrl(embedded, handle, event, occurrence.id)}
                                    >
                                        <Trans ns="bookingpage">Book</Trans>
                                    </PrimaryButtonNavLink>
                                )}
                                {occurrence.pastEvent || occurrence.soldOut ? (
                                    <span>
                                        <Trans ns="bookingpage">Not available for booking</Trans>
                                    </span>
                                ) : null}
                            </Flex>
                        </ModalFooter>
                    </>
                )}
            </ModalContainer>
        </StyledModalBackground>
    )
}
