import DOMPurify from 'dompurify'
import moment from 'moment'
import { FC, HTMLAttributes, useState, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { Flex } from '../../../components/helpers/Flex'
import { UtcTimestamp } from '../../../components/helpers/UtcTimestamp'
import { EventPublicDto, OccurrencePublicDto } from '../../../store/events/types'
import { getImageUrl } from '../../../services/get-image-url'
import { SummaryCardContainer, ServiceDetailsContainer } from '../components/SummaryCardContainer'
import { GoogleMapsLink } from '../../../components/ui-kit/comopnents/GoogleMapsLink'
import { AvatarImage, ServiceAvatarContainer } from '../../../components/ui-kit/comopnents/AvatarImage'
import { TextButton } from '../../../components/ui-kit/button/TextButton'
import { LONG_MONTH_AND_DAY_FORMAT } from '../dictionaries/constants'
import { UcFirst } from '../components/UcFirst'
import { BusinessPublicDto } from '../../../bookingpage/types/business'
import { WysiwygDisplay } from '../components/WysiwygDisplay'

const ShowDetailsButton: FC<{ expand: boolean; setExpand: (value: boolean) => void }> = ({ expand, setExpand }) => {
    return (
        <TextButton
            className="show-hide-button"
            onClick={(e) => {
                e.preventDefault()
                setExpand(!expand)
            }}
        >
            {expand ? <Trans ns="bookingpage">Hide details</Trans> : <Trans ns="bookingpage">Show details</Trans>}
        </TextButton>
    )
}

interface EventSummaryCardProps {
    hideDetails?: boolean
    event: EventPublicDto
    occurrence: OccurrencePublicDto
    business: BusinessPublicDto
    success?: boolean
    clientTimezone: string
    seatsBooked?: number
}

export const EventSummaryCard: FC<Omit<HTMLAttributes<HTMLDivElement>, 'slot'> & EventSummaryCardProps> = ({
    hideDetails,
    event,
    occurrence,
    business,
    success,
    clientTimezone,
    seatsBooked,
    ...rest
}) => {
    const [expand, setExpand] = useState(false)
    const description = useMemo(() => {
        const purified = DOMPurify.sanitize(event.description || '')
        const test = document.createElement('span')
        test.innerHTML = purified
        return test.textContent?.trim() ? purified : ''
    }, [event.description])
    const imageUrl = getImageUrl(event.imagePublicId, 'bookingpageeventcarddesktop')

    return (
        <SummaryCardContainer {...rest}>
            <div className="details-container">
                <ServiceAvatarContainer>
                    <AvatarImage
                        src={imageUrl}
                        emojiSize={24}
                        renderImage={(src) => <img src={src} alt="" title="" />}
                    />
                </ServiceAvatarContainer>
                <div className="service-summary">
                    <Flex flexDirection="column" alignItems="flex-start" gap={1}>
                        <h3>{occurrence.title}</h3>
                        {occurrence.price || event.price ? <span>{occurrence.price || event.price}</span> : null}
                        {seatsBooked ? (
                            <span>
                                <Trans ns="bookingpage">Seats booked</Trans>: {seatsBooked}
                            </span>
                        ) : null}
                    </Flex>
                </div>
                {!hideDetails ? (
                    <div className="details-summary">
                        <Flex className="bodymedium semibold" justifyContent="flex-start">
                            <span className="mrs">🗓️</span>
                            <UcFirst>
                                <UtcTimestamp
                                    tz={clientTimezone}
                                    format={LONG_MONTH_AND_DAY_FORMAT}
                                    timestamp={moment(occurrence.from).unix()}
                                />{' '}
                                <UtcTimestamp
                                    tz={clientTimezone}
                                    format="HH:mm"
                                    timestamp={moment(occurrence.from).unix()}
                                />
                                -
                                <UtcTimestamp
                                    tz={clientTimezone}
                                    format="HH:mm"
                                    timestamp={moment(occurrence.to).unix()}
                                />
                            </UcFirst>
                        </Flex>
                        {event.location ? (
                            <GoogleMapsLink className="only-booking-desktop" address={event.location}>
                                {event.location}
                            </GoogleMapsLink>
                        ) : null}
                    </div>
                ) : null}
            </div>

            {!hideDetails ? (
                <>
                    <div className="only-booking-desktop">
                        {description ? (
                            <ServiceDetailsContainer
                                flexDirection="column"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                            >
                                <ShowDetailsButton expand={expand} setExpand={setExpand} />
                                {expand && <WysiwygDisplay dangerouslySetInnerHTML={{ __html: description }} />}
                            </ServiceDetailsContainer>
                        ) : null}
                    </div>

                    <div className="only-booking-mobile">
                        {description || event.location ? (
                            <ServiceDetailsContainer
                                flexDirection="column"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                            >
                                <ShowDetailsButton expand={expand} setExpand={setExpand} />
                                {expand && (
                                    <>
                                        {event.location ? (
                                            <div className="details-summary">
                                                <GoogleMapsLink
                                                    className="only-booking-mobile"
                                                    address={event.location}
                                                >
                                                    {event.location}
                                                </GoogleMapsLink>
                                            </div>
                                        ) : null}
                                        {description && (
                                            <WysiwygDisplay dangerouslySetInnerHTML={{ __html: description }} />
                                        )}
                                    </>
                                )}
                            </ServiceDetailsContainer>
                        ) : null}
                    </div>
                </>
            ) : null}
        </SummaryCardContainer>
    )
}
