import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Flex } from '../../../components/helpers/Flex'
import { Trans } from 'react-i18next'
import { Column } from '../../../components/admin/layout/Column'
import ReCAPTCHA from 'react-google-recaptcha'
import { PrimaryButton } from '../../../components/ui-kit/button/primary'
import { Icon } from '../../../components/ui-kit/comopnents/Icon'
import { faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { RequiredIndicator } from '../../../components/ui-kit/comopnents/RequiredIndicator'
import { Input } from '../../../components/ui-kit/comopnents/Input'
import { useClientTimezone } from '../../../hooks/useClientTimezone'
import styled from '@emotion/styled'
import { captureException } from '../../../appInsights'
import { ContactInfo } from '../components/ContactInfoScreen'
import { Form, Formik } from 'formik'
import { EventPublicDto, OccurrencePublicDto } from '../../../store/events/types'
import { Footer } from './Footer'
import { Slot } from '../services/ServicePage'
import { MinupRibbon } from '../../../components/helpers/MinupRibbon'
import { BookingPageTitle } from './BookingPageTitle'
import { DetailsCard } from './SuccessDetailsCard'
import { CanCancelUntilDisplay } from './HourDayDisplay'
import { Content } from './Content'
import { ContactInfoCard } from './SuccessContactInfoCard'
import { EventSummaryCard } from '../events/EventSummaryCard'
import { SummaryCard } from '../services/SummaryCard'
import moment from 'moment'
import { Separator } from './Separator'
import { DetailsCardContainer } from './DetailsCardContainer'
import { BusinessPublicDto } from '../../../bookingpage/types/business'
import { ServicePublicDto } from '../../../bookingpage/types/service'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'
import { Currency } from '../../../components/helpers/Currency'
import { PaymentMethod } from '../../../common/types/enums'

import { PaymentOptionContainer, PaymentOptionRadio, SimplePayLogo } from './payment/PaymentOverviewComponents'

const ColumnForm = styled(Form)(() => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
}))

const Total = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        flexGrow: 1,
        gap: theme.Spacing(0.5),
        padding: theme.Spacing(2),
        backgroundColor: theme.BackgroundSecondary,
        borderRadius: theme.Spacing(1.5),
        justifyContent: 'flex-start',
    }))
)

interface CommonProps {
    contactInfo: ContactInfo
    business: BusinessPublicDto
    onSubmit: (
        contactInfo: ContactInfo,
        clientTimezone: string,
        recaptchaToken: string | null | undefined,
        paymentMethod: PaymentMethod
    ) => Promise<void>
}

interface ServiceProps {
    service: ServicePublicDto
    additionalServices: ServicePublicDto[]
    event?: undefined
    selectedSlot?: Slot
}

interface EventProps {
    service?: undefined
    additionalServices?: undefined
    event: EventPublicDto
    selectedSlot?: OccurrencePublicDto
}

type OverviewScreenProps = (ServiceProps | EventProps) & CommonProps

export const OverviewScreen: FC<OverviewScreenProps> = ({
    contactInfo,
    service,
    additionalServices,
    event,
    business,
    selectedSlot,
    onSubmit,
}) => {
    const recaptchaRef = useRef<ReCAPTCHA>(null)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const { clientTimezone } = useClientTimezone()

    const fullPrice = useMemo(() => {
        const price = service
            ? [service, ...additionalServices].reduce(
                  (sum: number, service: ServicePublicDto) => sum + (service.priceValue || 0),
                  0
              )
            : (event.priceValue || 0) * (contactInfo?.seats || 0)

        return price || 0
    }, [additionalServices, contactInfo?.seats, event?.priceValue, service])

    const [paymentMethod, setPaymentMethod] = useState(
        fullPrice > 0 && business.paymentSettings?.active ? PaymentMethod.SimplePay : PaymentMethod.None
    )

    const depositPrice = useMemo(() => {
        return !business.paymentSettings || business.paymentSettings?.fullPrice
            ? fullPrice
            : (fullPrice * business.paymentSettings?.depositPercent) / 100
    }, [business.paymentSettings, fullPrice])

    const onsubmit = useCallback(
        async (clientInfo) => {
            setIsSubmitting(true)
            let recaptchaToken = null
            if (recaptchaRef.current) {
                recaptchaToken = await recaptchaRef.current.executeAsync()
                recaptchaRef.current.reset()
            }

            if (!process.env.REACT_APP_RECAPTCHA_DISABLE && !recaptchaToken) {
                console.warn('recaptcha', recaptchaRef.current)
                captureException(new Error('No recaptcha token'))
            }

            await onSubmit(clientInfo, clientTimezone, recaptchaToken, paymentMethod)
            setIsSubmitting(false)
        },
        [clientTimezone, onSubmit, paymentMethod]
    )

    const titleRef = useRef<HTMLElement>()
    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.scrollIntoView({ behavior: 'auto', block: 'center' })
        }
    }, [])

    const total = useMemo(() => {
        if (event && event.priceValue) {
            return contactInfo?.seats * event.priceValue
        } else if (service && (service.priceValue || additionalServices.some((service) => service.priceValue))) {
            let servicePrice = service.priceValue || 0
            const additionalServicePrices = additionalServices.reduce(
                (total, service) => total + (service.priceValue || 0),
                0
            )
            return servicePrice + additionalServicePrices
        }
    }, [additionalServices, contactInfo?.seats, event, service])

    return (
        <>
            {contactInfo ? (
                <Formik initialValues={contactInfo} onSubmit={onsubmit}>
                    {() => (
                        <ColumnForm>
                            <Content>
                                {!business.hideMinupBranding ? <MinupRibbon /> : null}
                                <BookingPageTitle
                                    ref={titleRef as any}
                                    title={<Trans ns="bookingpage">Booking overview</Trans>}
                                />
                                <Column>
                                    {service ? (
                                        <DetailsCard
                                            selectedSlot={selectedSlot}
                                            name={business.name}
                                            location={service.address}
                                            type={'service'}
                                        />
                                    ) : (
                                        <DetailsCard
                                            selectedSlot={selectedSlot as OccurrencePublicDto}
                                            name={business.name}
                                            location={event?.location!}
                                            type={'event'}
                                        />
                                    )}
                                </Column>
                                {service ? (
                                    <Column>
                                        <SummaryCard
                                            hideDetails
                                            service={service}
                                            additionalServices={[]}
                                            day={moment.unix(selectedSlot!.from)}
                                            slot={selectedSlot}
                                            business={business}
                                        />
                                        {additionalServices.map((service) => (
                                            <SummaryCard
                                                hideDetails
                                                service={service}
                                                additionalServices={[]}
                                                day={moment.unix(selectedSlot!.from)}
                                                slot={selectedSlot}
                                                business={business}
                                            />
                                        ))}
                                    </Column>
                                ) : (
                                    <EventSummaryCard
                                        hideDetails
                                        event={event}
                                        occurrence={selectedSlot!}
                                        business={business}
                                        clientTimezone={clientTimezone}
                                        seatsBooked={contactInfo.seats}
                                    />
                                )}
                                {total ? (
                                    <Total>
                                        <span className="semibold">
                                            <Trans ns="bookingpage">Total</Trans>:
                                        </span>
                                        <Currency
                                            value={total}
                                            currency={business.currencyCode}
                                            languageCode={business.bookingPageLanguage}
                                        />
                                    </Total>
                                ) : null}
                                <Separator>
                                    <Trans ns="bookingpage">My contact info</Trans>
                                </Separator>
                                <ContactInfoCard contactInfo={contactInfo} />
                                {business.invoiceSettings && contactInfo.invoiceRequested ? (
                                    <>
                                        <Separator>
                                            <Trans ns="bookingpage">My billing info</Trans>
                                        </Separator>
                                        <DetailsCardContainer>
                                            <Column spacing={0.5}>
                                                <span>{contactInfo.billingName}</span>
                                                <span>{contactInfo.billingCity}</span>
                                                <span>{contactInfo.billingAddress}</span>
                                                <span>{contactInfo.billingZipCode}</span>
                                                <span>{contactInfo.billingCountry}</span>
                                                {contactInfo.isBusiness ? (
                                                    <span>
                                                        <Trans ns="bookingpage">Tax ID</Trans>: {contactInfo.taxID}
                                                    </span>
                                                ) : null}
                                            </Column>
                                        </DetailsCardContainer>
                                    </>
                                ) : null}
                                {business.paymentSettings?.active && fullPrice > 0 ? (
                                    <>
                                        <Separator>
                                            <Trans ns="bookingpage">Payment method</Trans>
                                        </Separator>
                                        <Column grow>
                                            {!business.paymentSettings.fullPrice ? (
                                                <p>
                                                    <b>
                                                        <Trans ns="bookingpage">
                                                            {{
                                                                depositPercent: business.paymentSettings.depositPercent,
                                                            }}
                                                            % deposit (
                                                            <Currency
                                                                value={depositPrice}
                                                                currency={business.currencyCode}
                                                                languageCode={business.bookingPageLanguage}
                                                            />
                                                            ) is required to book the selected services.
                                                        </Trans>
                                                    </b>
                                                </p>
                                            ) : null}
                                            <PaymentOptionContainer
                                                id="payment-option-simplepay"
                                                onClick={() => setPaymentMethod(PaymentMethod.SimplePay)}
                                            >
                                                {business.paymentSettings?.enableOnSitePayment ? (
                                                    <PaymentOptionRadio
                                                        selected={paymentMethod === PaymentMethod.SimplePay}
                                                        onSelect={() => setPaymentMethod(PaymentMethod.SimplePay)}
                                                        labelledBy="payment-option-simplepay"
                                                    />
                                                ) : null}
                                                <span
                                                    className="description"
                                                    id="payment-option-simplepay"
                                                    onClick={() => setPaymentMethod(PaymentMethod.SimplePay)}
                                                >
                                                    <Trans ns="admin">Online credit card payment</Trans>
                                                    <SimplePayLogo
                                                        languageCode={business.bookingPageLanguage}
                                                        className="illustration"
                                                    />
                                                </span>
                                            </PaymentOptionContainer>
                                            {business.paymentSettings?.enableOnSitePayment ? (
                                                <PaymentOptionContainer>
                                                    <PaymentOptionRadio
                                                        selected={paymentMethod === PaymentMethod.OnSite}
                                                        onSelect={() => setPaymentMethod(PaymentMethod.OnSite)}
                                                        labelledBy="payment-option-onsite"
                                                    />
                                                    <span
                                                        className="description"
                                                        id="payment-option-onsite"
                                                        onClick={() => setPaymentMethod(PaymentMethod.OnSite)}
                                                    >
                                                        <Trans ns="admin">On-site payment: Cash or Card</Trans>
                                                    </span>
                                                </PaymentOptionContainer>
                                            ) : null}
                                        </Column>
                                    </>
                                ) : null}
                                <Separator>
                                    <Trans ns="bookingpage">Terms and Conditions</Trans>
                                </Separator>
                                {(service || event)?.canCancelUntil ? (
                                    <Flex justifyContent="flex-start">
                                        <Icon
                                            icon={faInfoCircle}
                                            className="primary"
                                            style={{
                                                padding: '0 5px 0 4px',
                                            }}
                                        />
                                        <CanCancelUntilDisplay hours={(service || event).canCancelUntil} />
                                    </Flex>
                                ) : null}
                                <div>
                                    <label>
                                        <Input className="mrs" type="checkbox" name="tnc" required />
                                        <Trans ns="bookingpage">
                                            I have read and accept the{' '}
                                            <a
                                                href={`/book/${business.urlHandle}/privacy-policy`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                privacy policy
                                            </a>
                                        </Trans>
                                        <RequiredIndicator />
                                    </label>
                                </div>
                                <p className="small subtle">
                                    <Trans ns="bookingpage">
                                        This site is protected by reCAPTCHA and the Google{' '}
                                        <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
                                            Privacy Policy
                                        </a>{' '}
                                        and{' '}
                                        <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
                                            Terms of Service
                                        </a>{' '}
                                        apply.
                                    </Trans>
                                </p>
                            </Content>
                            {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                />
                            )}
                            <Footer
                                nextButton={
                                    <PrimaryButton className="continue" type="submit" disabled={isSubmitting}>
                                        {isSubmitting && <Icon icon={faSpinner} spin />}
                                        {paymentMethod === PaymentMethod.SimplePay ? (
                                            <Trans ns="bookingpage">Procceed to payment</Trans>
                                        ) : (
                                            <Trans ns="bookingpage">Confirm</Trans>
                                        )}
                                    </PrimaryButton>
                                }
                                back={() => window.history.back()}
                            />
                        </ColumnForm>
                    )}
                </Formik>
            ) : null}
        </>
    )
}
