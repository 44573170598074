import React, { CSSProperties, FC } from 'react'
import styled from '@emotion/styled'
import logo from '../../../icons/AnimatedLogo.svg'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'

interface LoadingScreenProps {
    noBackground?: boolean
    lightBackground?: boolean
    inline?: boolean
    containerStyle?: CSSProperties
}

const LoadingScreenContainer = withTheme(
    styled.div(({ theme, noBackground, lightBackground, inline }: { theme: AdminTheme } & LoadingScreenProps) => ({
        position: inline ? 'relative' : 'absolute',
        background: noBackground ? 'none' : lightBackground ? theme.SurfaceColor : theme.BackgroundColor,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.ContentTertiary,
    }))
)

const AnimatedLogo = withTheme(
    styled.img(({ theme }: { theme: AdminTheme }) => ({
        width: 40,
        margin: theme.Spacing(3),
    }))
)

export const LoadingScreen: FC<LoadingScreenProps> = ({ children, containerStyle, ...rest }) => (
    <LoadingScreenContainer {...rest} style={containerStyle}>
        <AnimatedLogo src={logo} alt="Loading..." />
        {children}
    </LoadingScreenContainer>
)
