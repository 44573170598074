import React, { useMemo, useState } from 'react'
import { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import moment from 'moment'
import 'moment/locale/hu'
import 'moment/locale/ro'

import { reactPlugin } from './appInsights'
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'

import { ThemeProviderContainer } from './theme/ThemeProvider'

import { BookingPage } from './pages/booking/BookingPage'
import { EmbeddedBookingPage } from './pages/booking/EmbeddedBookingPage'
import {
    ConfirmationPopup,
    ConfirmationPopupContext,
    ConfirmationPopupOptions,
    ConfirmationPopupProps,
} from './components/ui-kit/comopnents/ConfirmationPopup'
import { SubscriptionPopupContextProvider } from './pages/admin/subscription/checkout/SubscriptionPopupContext'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { LashArtist } from './icons/custom/LashArtist'
import { SimplePayReturnPage } from './pages/booking/payment/SimplePayReturnPage'
library.add(fas)
library.add(LashArtist)

const LoginPage = React.lazy(() => import('./pages/login/LoginPage'))
const AdminPage = React.lazy(() => import('./pages/admin/AdminPage'))

function App() {
    const { i18n } = useTranslation()
    useEffect(() => {
        moment.locale(i18n.language)
        try {
            document.querySelector('html')?.setAttribute('lang', i18n.language)
        } catch {}
    }, [i18n.language])
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationPopupProps | undefined>(undefined)

    const confirmationPopupContext = useMemo(() => {
        return {
            dismiss: () => {
                setConfirmationProps(undefined)
            },
            show: <T,>(props: ConfirmationPopupOptions) => {
                return new Promise<T | boolean>((resolve) => {
                    setConfirmationProps({
                        ...props,
                        resolve: (value?: T) => {
                            setConfirmationProps(undefined)
                            resolve(value || true)
                        },
                        reject: () => {
                            setConfirmationProps(undefined)
                            resolve(false)
                        },
                    })
                })
            },
        }
    }, [])

    return (
        <AppInsightsErrorBoundary onError={() => <h1>Something went wrong</h1>} appInsights={reactPlugin}>
            <ThemeProviderContainer>
                <ConfirmationPopupContext.Provider value={confirmationPopupContext}>
                    <SubscriptionPopupContextProvider>
                        <Router>
                            <Switch>
                                <Route path="/" exact>
                                    <Redirect to="/admin" />
                                </Route>
                                <Route path="/book/:handle">
                                    <BookingPage />
                                </Route>
                                <Route path="/payment/simplepay/return">
                                    <SimplePayReturnPage />
                                </Route>
                                <Route path="/embed/:handle">
                                    <EmbeddedBookingPage />
                                </Route>
                                <Route path="/register">
                                    <LoginPage register />
                                </Route>
                                <Route path="/salonic">
                                    <LoginPage register salonic />
                                </Route>
                                <Route path="/admin">
                                    <AdminPage />
                                </Route>
                            </Switch>
                            {confirmationProps && <ConfirmationPopup {...confirmationProps} />}
                        </Router>
                    </SubscriptionPopupContextProvider>
                </ConfirmationPopupContext.Provider>
            </ThemeProviderContainer>
        </AppInsightsErrorBoundary>
    )
}

export default App
