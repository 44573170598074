import { withTheme } from '@emotion/react'
import styled, { CreateStyledComponent } from '@emotion/styled'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import { ButtonHTMLAttributes, FunctionComponent } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { AdminTheme } from '../../../theme/theme'
import { Icon } from '../comopnents/Icon'

function asNeutralIconButtonContainer<ComponentProps extends {}, SpecificComponentProps extends {}>(
    component: CreateStyledComponent<ComponentProps, SpecificComponentProps>
) {
    return withTheme(
        component(({ theme }: { theme: AdminTheme }) => ({
            textDecoration: 'none',
            outline: 'none',
            ...theme._BodyMedium,
            color: theme.ContentSecondary,
            backgroundColor: '#00000000',
            border: 'none',
            borderRadius: theme.ButtonBorderRadius,
            '&.round': {
                borderRadius: '18px',
            },

            padding: 0,
            flexShrink: 0,

            width: 36,
            height: 36,
            fontSize: 20,

            display: 'flex',
            '&.inline': {
                display: 'inline-flex',
            },

            alignItems: 'center',
            justifyContent: 'center',

            cursor: 'pointer',

            '&.opaque': {
                color: theme.ThemeColor,
                backgroundColor: theme.InteractiveSecondaryHover,
            },

            '&:hover': {
                color: theme.ContentPrimary,
                backgroundColor: theme.InteractiveNeutralHover,
                '&.opaque': {
                    color: theme.ThemeColor,
                    backgroundColor: theme.InteractiveSecondaryHover,
                },
            },

            '&:focus-visible': {
                color: theme.ContentSecondary,
                boxShadow: `0 0 0 4px ${theme.FocusOutline}`,
                border: `1px solid ${theme.ThemeColor}`,
                '&.opaque': {
                    color: theme.ThemeColor,
                },
            },

            '&:active': {
                color: theme.ContentPrimary,
                boxShadow: 'none',
                backgroundColor: theme.InteractiveNeutralActive,
                border: 'none',
                '&.opaque': {
                    color: theme.ThemeColor,
                    backgroundColor: theme.InteractiveSecondaryActive,
                },
            },

            '&.primary': {
                color: theme.Neutral_0,
                backgroundColor: theme.Primary_700,
                '&:hover': {
                    backgroundColor: theme.Primary_800,
                },

                '&:focus-visible': {
                    boxShadow: `0 0 0 4px ${theme.Opacity_30}`,
                    border: `1px solid ${theme.Primary_700}`,
                },

                '&:active': {
                    backgroundColor: theme.Primary_900,
                },
            },

            '&.secondary': {
                color: theme.ThemeColor,
                backgroundColor: theme.InteractiveSecondary,
                '&:hover': {
                    backgroundColor: theme.InteractiveProHover,
                },

                '&:focus-visible': {
                    boxShadow: `0 0 0 4px ${theme.FocusOutline}`,
                    border: `1px solid ${theme.ThemeColor}`,
                },

                '&:active': {
                    backgroundColor: theme.InteractiveSecondaryActive,
                },
            },

            '&.no-bg': {
                backgroundColor: '#00000000',
                '&:hover': {
                    backgroundColor: '#00000000',
                },
            },

            '&.primary.no-bg': {
                color: theme.Primary_700,
                '&:hover': {
                    color: theme.Primary_800,
                },

                '&:focus-visible': {
                    boxShadow: `0 0 0 4px ${theme.Opacity_30}`,
                    border: `1px solid ${theme.Primary_700}`,
                },

                '&:active': {
                    color: theme.Primary_900,
                },
            },

            '&.outlined': {
                backgroundColor: theme.InteractiveNeutral,
                border: `1px solid ${theme.BorderSecondary}`,
                '&:hover': {
                    backgroundColor: theme.InteractiveNeutralHover,
                },
                '&:active': {
                    backgroundColor: theme.InteractiveNeutralActive,
                },
            },

            '&.danger': {
                color: theme.Secondary4_600,
                '&:hover': {
                    backgroundColor: `${theme.Secondary4_700}19`,
                },

                '&:focus-visible': {
                    boxShadow: `0 0 0 4px ${theme.Opacity_30}`,
                    border: `1px solid ${theme.Primary_700}`,
                },

                '&:active': {
                    backgroundColor: `${theme.Secondary4_700}4b`,
                },
            },

            '&.interactive': {
                color: theme.Primary_700,
                '&:hover': {
                    backgroundColor: `${theme.Primary_800}19`,
                },

                '&:focus-visible': {
                    boxShadow: `0 0 0 4px ${theme.Opacity_30}`,
                    border: `1px solid ${theme.Primary_700}`,
                },

                '&:active': {
                    backgroundColor: `${theme.Primary_700}4b`,
                },
            },

            '&.booking-page-card': {
                color: theme.ContentSecondary,
                backgroundColor: theme.InteractiveBookingPageCard,
                '&:hover': {
                    backgroundColor: theme.InteractiveBookingPageCardHover,
                },
                '&:active': {
                    backgroundColor: theme.InteractiveBookingPageCardActive,
                },
            },

            '&.booking-page-card.selected': {
                color: theme.ThemeColor,
                backgroundColor: theme.InteractiveSecondaryHover,
                '&:hover': {
                    backgroundColor: theme.InteractiveSecondaryActive,
                },
                '&:active': {
                    backgroundColor: theme.InteractiveSecondaryActive,
                },
            },

            '&:disabled, &.disabled': {
                color: theme.Neutral_400,
                background: 'none',
                backgroundColor: 'initial !important',
                cursor: 'initial',
            },

            '&.medium': {
                width: 32,
                height: 32,
                fontSize: 16,
            },

            '&.admin-bar-button': {
                width: 32,
                height: 32,
                fontSize: 16,
            },

            '&.small': {
                width: 28,
                height: 28,
                fontSize: 12,
            },

            '&.extra-small': {
                width: 20,
                height: 20,
                fontSize: 12,
                borderRadius: 4,
            },

            '&.bordered': {
                border: `1px solid ${theme.Neutral_400}`,
                backgroundColor: 'white',
            },

            '&.title-button': {
                width: 30,
                height: 30,
            },
        }))
    )
}

interface NeutralIconButtonProps {
    icon: IconProp
    spin?: boolean
}

const NeutralIconButtonContainer = asNeutralIconButtonContainer(styled.button)

export const NeutralIconButton = React.forwardRef<
    any,
    NeutralIconButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>(({ icon, spin, ...rest }, ref) => {
    return (
        <NeutralIconButtonContainer ref={ref} {...rest}>
            <Icon icon={icon} spin={spin} />
        </NeutralIconButtonContainer>
    )
})

const NeutralIconButtonNavlinkContainer = asNeutralIconButtonContainer(styled(NavLink))

export const NeutralIconButtonNavLink: FunctionComponent<NeutralIconButtonProps & NavLinkProps> = ({
    icon,
    ...rest
}) => {
    return (
        <NeutralIconButtonNavlinkContainer {...rest}>
            <Icon icon={icon} />
        </NeutralIconButtonNavlinkContainer>
    )
}
