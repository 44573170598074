import { useDispatch, useSelector } from 'react-redux'
import { AuthState, FeatureFlagName, FeatureFlags } from '../store/auth/types'
import { useCallback } from 'react'
import { createAction2 } from '../store/utils/create-action'
import { AuthActionTypes } from '../store/auth/actions'

export function useFeatureFlags(): FeatureFlags | undefined {
    return useSelector<{ auth: AuthState }, FeatureFlags | undefined>((state) => state.auth.featureFlags)
}

export function useHasFeature() {
    const featureFlags = useFeatureFlags()
    return useCallback(
        (featureFlag: FeatureFlagName) => {
            return Boolean(featureFlags?.get(featureFlag)?.enabled)
        },
        [featureFlags]
    )
}

export function useSetFeatureFlags() {
    const dispatch = useDispatch()
    return useCallback(
        (featureFlags: FeatureFlags | undefined) => {
            dispatch(createAction2({ type: AuthActionTypes.SET_FEATURE_FLAGS, payload: featureFlags }))
        },
        [dispatch]
    )
}
