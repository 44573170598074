import Tooltip from 'rc-tooltip'
import { FC, useMemo } from 'react'
import { useAdminTheme } from '../../../hooks/use-admin-theme'
import { useIsTouchDevice } from '../../../hooks/use-is-touch-device'

type TriggerActionType = 'hover' | 'focus' | 'click' | 'contextMenu'

export const StyledTooltip: FC<{
    text: string | undefined
    dangerouslySetIsHtml?: boolean
    onlyDesktop?: boolean
    onlyBookingDesktop?: boolean
    triggerClickable?: boolean
    mouseEnterDelay?: number
    placement?: 'top' | 'right'
}> = ({
    text,
    dangerouslySetIsHtml,
    onlyDesktop,
    onlyBookingDesktop,
    triggerClickable,
    mouseEnterDelay,
    placement = 'top',
    children,
}) => {
    const theme = useAdminTheme()
    const overlay = useMemo(() => {
        return text && dangerouslySetIsHtml ? <span dangerouslySetInnerHTML={{ __html: text }} /> : text
    }, [dangerouslySetIsHtml, text])

    const onlyShowOnDesktop = onlyDesktop || onlyBookingDesktop

    const isTouchDevice = useIsTouchDevice()

    const trigger = useMemo<TriggerActionType | TriggerActionType[]>(() => {
        if (onlyShowOnDesktop && isTouchDevice) {
            return []
        }
        return triggerClickable ? ['click', 'hover', 'focus', 'contextMenu'] : ['hover']
    }, [isTouchDevice, onlyShowOnDesktop, triggerClickable])

    return text ? (
        <Tooltip
            showArrow
            arrowContent
            placement={placement}
            mouseEnterDelay={mouseEnterDelay}
            trigger={trigger}
            styles={{
                root: {
                    opacity: '1',
                    padding: '0 0 5px 0',
                },
                body: {
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '12px',
                    lineHeight: '16px',
                    backgroundColor: theme.Neutral_800,
                    color: theme.Primary_0,
                },
            }}
            overlay={overlay}
        >
            {(children as React.ReactElement) || null}
        </Tooltip>
    ) : (
        <>{children}</>
    )
}
