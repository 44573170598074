import { darkTheme } from './dark-theme'
import { AdminTheme } from './theme'

export const midnightTheme: AdminTheme = {
    ...darkTheme,
    // Background
    BackgroundColor: '#18181B',
    BackgroundPrimary: 'hsla(0, 0%, 100%, 1)',
    BackgroundSecondary: 'hsla(240, 6%, 10%, 1)',
    BackgroundNeutralSecondary: 'hsla(230, 7%, 16%, 1)',

    // Content
    FontColor: 'hsla(234, 24%, 25%, 1)',
    ContentInverted: 'hsla(234, 24%, 25%, 1)',
    ContentDanger: 'hsla(357, 97%, 76%, 1)',
    ContentDisabled: 'hsla(215, 10%, 33%, 1)',
    // Border
    BorderSecondary: 'hsla(215, 10%, 33%, 1)',
    BorderPrimary: 'hsla(215, 10%, 33%, 1)',
    BorderInverted: 'hsla(215, 10%, 33%, 1)',
    BorderDisabled: 'hsla(215, 10%, 33%, 1)',
    BookingPageAdminBarBackground: 'hsla(218, 10%, 16%, 0.9)',
    BookingPageBorderStyle: { border: `none` },
    BookingPageCardHoverBorderStyle: {
        border: `1px solid hsla(215, 20%, 67%, 1)`,
    },
    // Other
    ThemeColor: 'hsla(225, 61%, 74%, 1)',
    InteractivePrimary: 'hsla(225, 61%, 74%, 1)',
    InteractivePrimaryHover: 'hsla(221, 63%, 82%, 1)',
    InteractivePrimaryActive: 'hsla(230, 56%, 67%, 1)',
    InteractiveSecondary: 'hsla(234, 24%, 25%, 1)',
    InteractiveSecondaryHover: 'hsla(234, 24%, 39%, 1)',
    InteractiveSecondaryActive: 'hsla(234, 24%, 25%, 1)',
    InteractiveNeutral: 'hsla(215, 10%, 33%, 1)',
    InteractiveNeutralHover: 'hsla(214, 10%, 46%, 1)',
    InteractiveNeutralActive: 'hsla(218, 10%, 16%, 1)',
    InteractiveDisabledDefault: 'hsla(215, 10%, 33%, 1)',
    FocusOutline: 'hsla(225, 61%, 74%, 0.3)',
    SurfaceColor: 'hsla(218, 10%, 16%, 1)',
    Cover1: 'hsla(240, 10%, 14%, 1)',
    Cover2: 'hsla(240, 7%, 24%, 1)',
    Cover3: 'hsla(240, 7%, 27%, 1)',
    BookingPageAvatarBackground: 'hsla(225, 61%, 74%, 1)',
    AnimationColor: [148 / 255.0, 168 / 255.0, 229 / 255.0],
    ServiceColorVariant1Color: 'hsla(222, 100%, 76%, 1)',
    ServiceColorVariant1Background: 'hsla(234, 24%, 39%, 1)',
    ServiceColorVariant2Color: 'hsla(258, 100%, 83%, 1)',
    ServiceColorVariant2Background: 'hsla(256, 37%, 41%, 1)',
    ServiceColorVariant3Color: 'hsla(314, 100%, 80%, 1)',
    ServiceColorVariant3Background: 'hsla(314, 48%, 31%, 1)',
    ServiceColorVariant4Color: 'hsla(357, 67%, 31%, 1)',
    ServiceColorVariant4Background: 'hsla(357, 95%, 84%, 1)',
    ServiceColorVariant5Color: 'hsla(165, 93%, 84%, 1)',
    ServiceColorVariant5Background: 'hsla(165, 69%, 22%, 1)',
    ServiceColorVariant6Color: 'hsla(44, 100%, 76%, 1)',
    ServiceColorVariant6Background: 'hsla(44, 96%, 25%, 1)',
}
