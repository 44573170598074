import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { applyMiddleware, combineReducers, createStore, Middleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { fork } from 'redux-saga/effects'
import { logger } from './middlewares/logger'
import { calendarReducer } from './store/calendar/reducer'
import { authReducer } from './store/auth/reducer'
import { notificationsReducer } from './store/notifications/reducer'

import './i18n'
import 'rc-tooltip/assets/bootstrap.css'
import { adminReducer } from './store/admin/reducer'
import { gettingStartedReducer } from './store/getting-started/reducer'
import { gettingStartedRootSaga } from './store/getting-started/sagas/getting-started-root-saga'
import { liveChatRootSaga } from './store/admin/live-chat-root-saga'
import { zendeskRootSaga } from './store/admin/zendesk-root-saga'

const middlewares: Middleware[] = []

if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger)
}

const sagaMiddleWare = createSagaMiddleware()

middlewares.push(sagaMiddleWare)

const store = createStore(
    combineReducers({
        calendar: calendarReducer,
        auth: authReducer,
        notifications: notificationsReducer,
        admin: adminReducer,
        gettingStarted: gettingStartedReducer,
    }),
    applyMiddleware(...middlewares)
)

sagaMiddleWare.run(function* () {
    yield fork(gettingStartedRootSaga)
    yield fork(liveChatRootSaga)
    yield fork(zendeskRootSaga)
})

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Suspense fallback="">
                <App />
            </Suspense>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
