import moment from 'moment'
import { Moment } from 'moment'

export function isInView(pivot: Moment | undefined, date: Moment, nDays: number): boolean {
    if (!pivot) {
        return false
    }

    return date.isBetween(pivot, moment(pivot).add(nDays - 1, 'days'), 'day', '[]')
}
