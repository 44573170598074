import { FunctionComponent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { createAction } from '../../store/utils/create-action'
import { AuthActionTypes } from '../../store/auth/actions'
import { getLockInstance, saveRedirectUri } from '../../services/lock'
import { useToast } from '../../hooks/use-toast'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useCheckAccessToken } from '../../hooks/admin/use-check-access-token'
interface LoginPageProps {
    register?: boolean
    skipLock?: boolean
    salonic?: boolean
}

const SALONIC_MIGRATION_LOCAL_STORAGE_KEY = 'salonic_migration'
export function setSalonicMigration(isSalonic: boolean) {
    if (isSalonic) {
        localStorage.setItem(SALONIC_MIGRATION_LOCAL_STORAGE_KEY, '1')
    } else {
        localStorage.removeItem(SALONIC_MIGRATION_LOCAL_STORAGE_KEY)
    }
}
export function isSalonicMigration() {
    return localStorage.getItem(SALONIC_MIGRATION_LOCAL_STORAGE_KEY) === '1'
}
export function cancelSalonicMigration() {
    localStorage.removeItem(SALONIC_MIGRATION_LOCAL_STORAGE_KEY)
}

export const LoginPage: FunctionComponent<LoginPageProps> = ({ register, skipLock, salonic }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const toast = useToast()
    const { t } = useTranslation('admin')
    const checkAccessToken = useCheckAccessToken()

    useEffect(() => {
        ;(async () => {
            dispatch(createAction(AuthActionTypes.SET_LOADING, true))
            dispatch(createAction(AuthActionTypes.SET_ACCESS_TOKEN, undefined))

            const lock = await getLockInstance()
            lock.checkSession({}, (error, authResult) => {
                if (error && error.code === 'login_required') {
                    if (!skipLock) {
                        saveRedirectUri()
                        lock.on('authenticated', function (authResult) {
                            checkAccessToken(authResult, register)
                        })

                        dispatch(createAction(AuthActionTypes.AUTH_RESET, undefined))
                        lock.show({
                            initialScreen: register ? 'signUp' : 'login',
                        })
                    }
                } else if (authResult && !error) {
                    if (salonic) {
                        setSalonicMigration(true)
                    }
                    checkAccessToken(authResult, register)
                } else {
                    toast.error(t('Authentication failed'), '', 8)
                    console.error(error)
                }
            })
        })()
    }, [dispatch, register, skipLock, toast, t, history, checkAccessToken, salonic])

    return null
}

export default LoginPage
