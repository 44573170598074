import moment, { Duration } from 'moment'
import { FC, useMemo, useState, useEffect } from 'react'

export const Counter: FC<{ timeout: string }> = ({ timeout }) => {
    const momentTimeout = useMemo(() => moment(timeout), [timeout])
    const [remaining, setRemaining] = useState<Duration>()
    useEffect(() => {
        const updateTimer = () => {
            const now = moment()
            const diff = momentTimeout.diff(now, 'seconds')
            setRemaining(moment.duration(Math.max(diff, 0), 'seconds'))
        }
        updateTimer()
        const timer = setInterval(updateTimer, 1000)
        return () => clearInterval(timer)
    }, [momentTimeout])
    return remaining ? (
        <>
            {Math.floor(remaining.asMinutes()).toString().padStart(2, '0')}:
            {(remaining.asSeconds() % 60).toString().padStart(2, '0')}
        </>
    ) : null
}
