import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'

export const BookingPageCover = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        position: 'relative',
        width: '100%',
        '& > div': {
            position: 'absolute',
            right: 24,
            bottom: 12,
            zIndex: 99,
            display: 'flex',
            ...theme._Caption,
            color: theme.Neutral_0,
            '& > svg': {
                marginLeft: theme.Spacing(1),
            },
        },

        '.avatar': {
            position: 'absolute',
            bottom: -40,
            left: 24,
        },

        '#cover': {
            width: '100%',
            aspectRatio: '740/180',
            [theme.BookingPageBreakPoint]: {
                borderTopLeftRadius: theme.Spacing(1),
                borderTopRightRadius: theme.Spacing(1),
            },
        },
        svg: {
            '#shape-1': {
                fill: theme.Cover1,
            },
            '#shape-2': {
                fill: theme.Cover2,
            },
            '#shape-3': {
                fill: theme.Cover3,
            },
        },
    }))
)
