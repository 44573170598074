import { css, Global, ThemeProvider } from '@emotion/react'
import { createContext, useContext, FC, useState, useMemo } from 'react'
import { barbieTheme } from './barbie-theme'
import { pineForestTheme } from './pine-forest-theme'
import { purpleHazeTheme } from './purple-haze-theme'
import { AdminTheme } from './theme'
import { defaultTheme } from './default-theme'
import { midnightTheme } from './midnight-theme'
import { CustomTheme } from '../common/types/custom-theme'
import { ButtonStyle } from '../common/types/enums'

const ThemeUpdateContext = createContext<(theme: AdminTheme) => void>((_) => {})

export function createThemeFromCustomTheme(customTheme: Partial<CustomTheme>): AdminTheme {
    const theme = {
        ...(customTheme.IsDarkTheme ? midnightTheme : defaultTheme),
        ...customTheme,
        AnimationColor: customTheme.AnimationColor
            ? JSON.parse(customTheme.AnimationColor)
            : defaultTheme.AnimationColor,
        BookingPageAvatarBackground: (customTheme.IsDarkTheme ? customTheme.ThemeColor : customTheme.Cover3)!,
    }

    switch (customTheme.ButtonStyle) {
        case ButtonStyle.Round:
            theme.ButtonBorderRadius = defaultTheme.Spacing(5)
            break
        case ButtonStyle.Square:
            theme.ButtonBorderRadius = defaultTheme.Spacing(0)
            break
    }

    return theme
}

const CUSTOM_THEME_PREVIEW_LOCALSTORAGE_KEYS = 'custom_theme_preview'
export function setPreviewCustomTheme(theme: CustomTheme) {
    sessionStorage.setItem(CUSTOM_THEME_PREVIEW_LOCALSTORAGE_KEYS, JSON.stringify(theme))
}

function getPreviewCustomTheme(): CustomTheme | undefined {
    try {
        return JSON.parse(sessionStorage.getItem(CUSTOM_THEME_PREVIEW_LOCALSTORAGE_KEYS) || '')
    } catch (e) {
        console.warn('Cannot parse custom theme preview')
    }
    return
}

export function useTheme(themeName: string, customTheme?: CustomTheme) {
    const setTheme = useContext(ThemeUpdateContext)
    const theme = createTheme(themeName, customTheme)

    if (theme) {
        setTheme(theme!)
    }
}

export function createTheme(themeName: string, customTheme?: CustomTheme): AdminTheme {
    let theme: AdminTheme | undefined
    switch (themeName) {
        case 'barbie':
            theme = barbieTheme
            break
        case 'purple-haze':
            theme = purpleHazeTheme
            break
        case 'pine-forest':
            theme = pineForestTheme
            break
        case 'midnight':
            theme = midnightTheme
            break
        case 'custom':
            if (customTheme) {
                theme = createThemeFromCustomTheme(customTheme) || defaultTheme
            } else {
                console.warn('Custom theme selected but empty, defaulting to minup theme')
                theme = defaultTheme
            }
            break
        case 'custom-preview':
            const previewTheme = getPreviewCustomTheme()
            theme = previewTheme ? createThemeFromCustomTheme(previewTheme) : defaultTheme
            break
        case 'minup':
        default:
            theme = defaultTheme
            break
    }
    return theme
}

export const ThemeProviderContainer: FC = ({ children }) => {
    const [theme, setTheme] = useState(defaultTheme)

    const globalStyles = useMemo(() => {
        return css`
            html,
            body {
                height: 100%;
                margin: 0;
                overflow: hidden;
                color: ${theme.Neutral_800};
                ${theme.DefaultFontFamily};
                ${theme.BodyMedium};
            }

            * {
                scrollbar-width: thin;
            }

            #root {
                height: 100%;
                overflow-y: auto;
            }

            div,
            span,
            p,
            a,
            button,
            section,
            footer,
            nav {
                box-sizing: border-box;
            }

            h1,
            h2,
            h3,
            h4 {
                margin: 0;
                padding: 0;
            }

            input[type='date'] {
                width: auto;
                text-align-last: left;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
            }

            h1 {
                ${theme.HeadingMedium}
            }

            h1.large {
                ${theme.HeadingLarge}
            }

            h2 {
                ${theme.HeadingSmall}
            }

            h2.large {
                ${theme.HeadingMedium}
            }

            h3 {
                ${theme.BodyMediumSemibold}
            }
            h3.large {
                ${theme.BodyLargeSemibold}
            }

            .bodymedium {
                ${theme.BodyMedium}
            }

            .semibold {
                font-weight: 600;
            }

            .large {
                ${theme.BodyLarge}
            }

            .small {
                ${theme.BodySmall}
            }

            .large.semibold {
                ${theme.BodyLargeSemibold}
            }

            .small.semibold {
                ${theme.BodySmallSemibold}
            }

            .caption {
                ${theme.Caption}
            }

            .caption.semibold {
                ${theme.CaptionSemibold}
            }

            .heading.small {
                ${theme.HeadingSmall}
            }

            a {
                text-decoration: none;
                color: ${theme.Primary_700};

                &:hover {
                    color: ${theme.Primary_800};
                }

                &:active {
                    color: ${theme.Primary_900};
                }
            }

            p {
                margin: 0;
            }

            b {
                font-weight: 600;
            }

            .uppercase {
                text-transform: uppercase;
            }

            .m0 {
                margin: 0 !important;
            }

            .mt0 {
                margin-top: 0 !important;
            }

            .mb0 {
                margin-bottom: 0 !important;
            }

            .mr0 {
                margin-right: 0 !important;
            }

            .ml0 {
                margin-left: 0 !important;
            }

            .mb {
                margin-bottom: 24px !important;
            }

            .mt {
                margin-top: 24px !important;
            }

            .ml {
                margin-left: 24px !important;
            }

            .mr {
                margin-right: 24px !important;
            }

            .mbm {
                margin-bottom: 16px !important;
            }

            .mtm {
                margin-top: 16px !important;
            }

            .mlm {
                margin-left: 16px !important;
            }

            .mrm {
                margin-right: 16px !important;
            }

            .mbh {
                margin-bottom: 12px !important;
            }

            .mth {
                margin-top: 12px !important;
            }

            .mlh {
                margin-left: 12px !important;
            }

            .mrh {
                margin-right: 12px !important;
            }

            .mbs {
                margin-bottom: 8px !important;
            }

            .mbxs {
                margin-bottom: 4px !important;
            }

            .mts {
                margin-top: 8px !important;
            }

            .mtxs {
                margin-top: 4px !important;
            }

            .mls {
                margin-left: 8px !important;
            }

            .mlxs {
                margin-left: 4px !important;
            }

            .mrs {
                margin-right: 8px !important;
            }

            .mrxs {
                margin-right: 4px !important;
            }

            .pb {
                padding-bottom: 24px !important;
            }

            .pt {
                padding-top: 24px !important;
            }

            .pl {
                padding-left: 24px !important;
            }

            .pr {
                padding-right: 24px !important;
            }

            .pbm {
                padding-bottom: 16px !important;
            }

            .ptm {
                padding-top: 16px !important;
            }

            .plm {
                padding-left: 16px !important;
            }

            .prm {
                padding-right: 16px !important;
            }

            .pbh {
                padding-bottom: 12px !important;
            }

            .pth {
                padding-top: 12px !important;
            }

            .plh {
                padding-left: 12px !important;
            }

            .prh {
                padding-right: 12px !important;
            }

            .pbs {
                padding-bottom: 8px !important;
            }

            .pts {
                padding-top: 8px !important;
            }

            .pls {
                padding-left: 8px !important;
            }

            .prs {
                padding-right: 8px !important;
            }

            .p {
                padding: 24px;
            }

            .pm {
                padding: 16px;
            }

            .ps {
                padding: 8px;
            }

            .p0 {
                padding: 0 !important;
            }

            .pt0 {
                padding-top: 0 !important;
            }

            .pb0 {
                padding-bottom: 0 !important;
            }

            .pr0 {
                padding-right: 0 !important;
            }

            .pl0 {
                padding-left: 0 !important;
            }

            .h100 {
                height: 100%;
            }

            .w100 {
                width: 100%;
            }

            .mw100 {
                max-width: 100%;
            }

            .line-through {
                text-decoration: line-through;
            }

            .secondary {
                color: ${theme.ContentSecondary};
            }

            .tertiary {
                color: ${theme.ContentTertiary};
            }

            .subtle {
                color: ${theme.Neutral_500};
            }

            .grey,
            .gray {
                color: ${theme.Neutral_700};
            }

            .grecaptcha-badge {
                visibility: hidden;
            }

            .primary {
                color: ${theme.ThemeColor};
            }

            .content-primary {
                color: ${theme.ContentPrimary};
            }

            .danger {
                color: ${theme.ContentDanger};
            }

            .success {
                color: ${theme.Secondary1_700};
            }

            .warning {
                color: ${theme.ContentWarning};
            }

            .text-centered {
                text-align: center;
            }

            .centered {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .grow {
                flex-grow: 1;
            }

            .shrink {
                flex-shrink: 1;
            }

            .contents {
                display: contents;
            }

            ${theme.BreakPointReverse} {
                .only-desktop {
                    display: none !important;
                }
            }

            ${theme.BreakPoint} {
                .only-mobile {
                    display: none !important;
                }
            }

            ${theme.BookingPageBreakPointReverse} {
                .only-booking-desktop {
                    display: none !important;
                }
            }

            ${theme.BookingPageBreakPoint} {
                .only-booking-mobile {
                    display: none !important;
                }
            }

            .no-overflow {
                overflow: hidden;
            }

            .auto-overflow {
                overflow: auto;
            }

            .ellipsis {
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .rc-tooltip {
                z-index: 99999;
            }

            .underlined {
                text-decoration: underline;
            }

            .capitalize {
                text-transform: capitalize;
            }

            .inline {
                display: inline !important;
            }

            .inline-flex {
                display: inline-flex !important;
            }

            .nowrap {
                white-space: nowrap;
            }

            .relative {
                position: relative;
            }

            @media screen and (max-width: 5000px) {
                .auth0-lock.auth0-lock .auth0-lock-cred-pane {
                    border-radius: 8px !important;
                }

                .auth0-lock.auth0-lock .auth0-lock-overlay {
                    background: ${theme.BackgroundSecondary} !important;
                }

                .auth0-lock.auth0-lock .auth0-lock-header {
                    border-bottom: 1px solid ${theme.BorderPrimary} !important;
                    padding: 0 !important;
                }

                .auth0-lock.auth0-lock .auth0-lock-header-welcome {
                    padding: 33px !important;
                }

                .auth0-lock.auth0-lock .auth0-lock-header-logo.centered {
                    height: 40px !important;
                    margin: 0 !important;
                }

                .auth0-lock.auth0-lock .auth0-lock-header-bg .auth0-lock-header-bg-solid,
                .auth0-lock.auth0-lock .auth0-lock-header-bg-blur,
                .auth0-lock.auth0-lock .auth0-lock-header-bg {
                    background: white !important;
                }

                .auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-widget {
                    box-shadow: ${theme.Elevation_300} !important;
                }

                .auth0-lock.auth0-lock .auth0-lock-tabs li a {
                    font-size: 14px;
                    color: ${theme.ContentPrimary} !important;
                }
                .auth0-lock.auth0-lock .auth0-lock-tabs li.auth0-lock-tabs-current span {
                    font-size: 14px;
                    color: ${theme.ContentBrand} !important;
                }
                .auth0-lock.auth0-lock .auth0-lock-tabs li.auth0-lock-tabs-current {
                    box-shadow: 0 1px 0 0 ${theme.ContentBrand} !important;
                }
            }
        `
    }, [
        theme.BackgroundSecondary,
        theme.BodyLarge,
        theme.BodyLargeSemibold,
        theme.BodyMedium,
        theme.BodyMediumSemibold,
        theme.BodySmall,
        theme.BodySmallSemibold,
        theme.BookingPageBreakPoint,
        theme.BookingPageBreakPointReverse,
        theme.BorderPrimary,
        theme.BreakPoint,
        theme.BreakPointReverse,
        theme.Caption,
        theme.CaptionSemibold,
        theme.ContentBrand,
        theme.ContentDanger,
        theme.ContentPrimary,
        theme.ContentSecondary,
        theme.ContentTertiary,
        theme.ContentWarning,
        theme.DefaultFontFamily,
        theme.Elevation_300,
        theme.HeadingLarge,
        theme.HeadingMedium,
        theme.HeadingSmall,
        theme.Neutral_500,
        theme.Neutral_700,
        theme.Neutral_800,
        theme.Primary_700,
        theme.Primary_800,
        theme.Primary_900,
        theme.Secondary1_700,
        theme.ThemeColor,
    ])

    return (
        <ThemeUpdateContext.Provider value={setTheme}>
            <Global styles={globalStyles} />
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ThemeUpdateContext.Provider>
    )
}
