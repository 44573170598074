import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { BusinessPublicDto } from '../../../bookingpage/types/business'
import moment, { Moment } from 'moment'
import { getWeekDay } from '../../../services/get-weekday'
import {
    CalendarContainer,
    CalendarContent,
    CalendarHead,
    CalendarHeadDayHeaderContainer,
    TimeScale,
} from '../../../components/ui-kit/calendar/CalendarPageComponents'
import { DayHead, Hour, PageFrame, TimeScaleText } from '../../../components/ui-kit/calendar/CalendarDayComponents'
import { Flex } from '../../../components/helpers/Flex'
import { NeutralIconButton } from '../../../components/ui-kit/button/NeutralIconButton'
import { faChevronLeft, faChevronRight, faCompress, faExpand } from '@fortawesome/free-solid-svg-icons'
import { Trans, useTranslation } from 'react-i18next'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import {
    CalendarZoomContext,
    DecoratedCalendarEntry,
    DEFAULT_HOUR_HEIGHT,
    hours,
} from '../../../components/ui-kit/calendar/constants'
import { WeekDay } from './WeekDay'
import { useShortDayNames } from '../../../dictionaries/day-names-short'
import { OccurrencePublicDto } from '../../../store/events/types'
import { AppointmentStatus, EventType } from '../../../store/appointments/types'
import { Column } from '../../../components/admin/layout/Column'
import { EventPopup } from './EventPopup'
import { isInView } from '../../../components/ui-kit/calendar/is-in-view'
import { Content } from '../components/Content'
import { classNames } from '../../../services/class-names'
import { FromToDisplay } from '../../../components/ui-kit/calendar/FromToDisplay'
import { useSanitizeString } from '../../../hooks/use-sanitize-string'

const GroupEventsTimetable = styled.div(() => ({
    padding: `0`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flexGrow: 1,
    minHeight: 'fit-content',
    overflow: 'hidden',
}))

const StyledCalendarHeadDayHeaderContainer = styled(CalendarHeadDayHeaderContainer)(() => ({
    border: `none`,
    paddingLeft: 54,
}))

const NoBorderDayHead = withTheme(
    styled(DayHead)(({ theme }: { theme: AdminTheme }) => ({
        border: 'none',
        height: 'auto',
        padding: `0 0 ${theme.Spacing(0.5)}`,
        backgroundColor: theme.SurfaceColor,
        color: theme.ContentPrimary,
        textTransform: 'none',
    }))
)

const StyledCalendarContainer = styled(CalendarContainer)(() => ({
    border: 'none',
    borderRadius: '0 !important',
    backgroundColor: 'unset',
}))

const TopBorderTimeScale = withTheme(
    styled(TimeScale)(({ theme }: { theme: AdminTheme }) => ({
        backgroundColor: theme.SurfaceColor,
        color: theme.ContentPrimary,
        borderTop: `1px solid ${theme.BorderPrimary}`,
    }))
)

const PositionedTimeScaleText = styled(TimeScaleText)(() => ({
    right: 10,
}))

const ThemedContent = withTheme(
    styled(Content)(({ theme, inFullScreen }: { theme: AdminTheme; inFullScreen: boolean }) => ({
        ...(inFullScreen
            ? {
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: 9999,
                  backgroundColor: `rgba(0, 0, 0, 0.5) !important`,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '0 !important',
              }
            : {
                  backgroundColor: theme.SurfaceColor,
              }),
    }))
)

const Calendar = withTheme(
    styled(Column)(({ theme, inFullScreen }: { theme: AdminTheme; inFullScreen: boolean }) => ({
        maxWidth: 1248,
        width: '100%',
        maxHeight: '100%',
        overflow: 'scroll',
        backgroundColor: theme.SurfaceColor,
        gap: `${theme.Spacing(2.5)} !important`,
        [theme.BookingPageBreakPoint]: {
            gap: `${theme.Spacing(3)} !important`,
        },

        // Add visible overflow when isn't in fullscreen, so focus visible can be visible on every angles,
        // but overflow shouldn't be visible in fullscreen
        ...(inFullScreen ? { borderRadius: theme.Spacing(1.5), padding: theme.Spacing(3) } : { overflow: 'visible' }),
    }))
)

const Header = withTheme(
    styled(Flex)(({ theme, inFullScreen }: { theme: AdminTheme; inFullScreen: boolean }) => ({
        gap: `${theme.Spacing(2.5)} !important`,
        [theme.BookingPageBreakPoint]: {
            gap: `${theme.Spacing(3)} !important`,
        },
        flexDirection: inFullScreen ? 'row' : 'column',
        justifyContent: inFullScreen ? 'space-between' : undefined,
    }))
)

const DayBadge = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        borderRadius: theme.Spacing(0.5),
        padding: `0 ${theme.Spacing(0.5)}`,
        color: theme.ContentPrimary,
        backgroundColor: theme.BorderPrimary,
        ...theme._CaptionSemibold,
        '&.today': {
            backgroundColor: theme.InteractivePrimary,
            color: theme.ContentInverted,
        },
    }))
)

const TIMETABLE_BREAKPOINT = 620

export const GroupServicesTimetable: FC<{
    business: BusinessPublicDto
    events: OccurrencePublicDto[]
    embedded?: boolean
    hideTitle?: boolean
}> = ({ business, events, embedded, hideTitle }) => {
    const [fullScreen, setFullScreen] = useState(false)
    const [selectedOccurrenceId, setSelectedOccurrenceId] = useState('')
    const selectedOccurrence = useMemo(() => {
        return events.find((event) => event.id === selectedOccurrenceId)
    }, [events, selectedOccurrenceId])

    const { t } = useTranslation('bookingpage')
    const DAY_NAMES_SHORT = useShortDayNames()
    const [hourHeight, setHourHeight] = useState(DEFAULT_HOUR_HEIGHT)
    const [pivot, _setPivot] = useState<Moment | undefined>()
    const [numberOfDays] = useState(7)
    const [nDays, setNDays] = useState(numberOfDays)
    const setPivot = useCallback((newPivot: Moment | undefined) => {
        _setPivot((current) => {
            if (!current || !current.isSame(newPivot, 'day')) {
                return newPivot
            }
            return current
        })
    }, [])
    const today = useMemo(() => moment.tz(business.timezone), [business.timezone])
    const timeForFirstAvailableEvent = useMemo(() => {
        const filteredEvents = events.filter((event) => !event.pastEvent)
        return filteredEvents.length ? moment.tz(filteredEvents[0].from, business.timezone) : today
    }, [business.timezone, events, today])

    useEffect(() => {
        setPivot(getPivot(timeForFirstAvailableEvent, nDays))
        // getPivot intentionally left out of deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTextLength = useCallback(
        (appointment: OccurrencePublicDto, length: number) => {
            return appointment.allDay ? t('All day') : t('{{length}} minutes', { length })
        },
        [t]
    )

    const week = useMemo(() => {
        const week = []
        for (let i = 0; i < nDays; ++i) {
            const day = moment(pivot).add(i, 'days')
            week.push({
                day,
            })
        }
        return week
    }, [nDays, pivot])

    const filteredEventForWeek = useMemo(
        () =>
            events.filter((event) => {
                const eventStart = moment.tz(event.from, business.timezone)
                const eventEnd = moment.tz(event.to, business.timezone)
                return week.some((day) => eventStart.isSame(day.day, 'day') || eventEnd.isSame(day.day, 'day'))
            }),
        [business.timezone, events, week]
    )

    const workingHours = useMemo(() => {
        // default working hours
        const baseStart = 8
        const baseEnd = 16
        // Add offset
        let startBoundary = baseStart - 1
        let endBoundary = baseEnd + 1

        filteredEventForWeek.forEach((event) => {
            const eventStart = moment.tz(event.from, business.timezone)
            const eventEnd = moment.tz(event.to, business.timezone)

            if (!eventStart.isSame(eventEnd, 'day')) {
                startBoundary = 0
            } else {
                if (eventStart.hour() < baseStart) {
                    startBoundary = Math.min(startBoundary, eventStart.hour() - 1)
                }
            }

            if (!eventEnd.isSame(eventStart, 'day')) {
                endBoundary = 24
            } else {
                if (eventEnd.hour() > baseEnd) {
                    endBoundary = Math.max(endBoundary, eventEnd.hour() + 1)
                }
                if (eventEnd.hour() < baseStart) {
                    startBoundary = 0
                }
            }
        })

        startBoundary = Math.max(startBoundary, 0)
        endBoundary = Math.min(endBoundary, 24)

        return hours.slice(startBoundary, endBoundary)
    }, [business.timezone, filteredEventForWeek])

    const sanitize = useSanitizeString()

    const _events = useMemo(() => {
        const blocks: DecoratedCalendarEntry[][] = []
        for (let i = 0; i < nDays; ++i) {
            const list: DecoratedCalendarEntry[] = []
            const day = moment(pivot).add(i, 'days')
            const startOfDay = moment(day).startOf('day')
            const endOfDay = moment(day).endOf('day')

            for (const event of filteredEventForWeek as OccurrencePublicDto[]) {
                const from = moment.tz(event.from, business.timezone)
                const to = moment.tz(event.to, business.timezone)
                if (to.isAfter(startOfDay) && from.isBefore(endOfDay)) {
                    const newFrom = from.isAfter(startOfDay) ? moment(from) : moment(startOfDay)
                    const newTo = to.isBefore(endOfDay) ? moment(to) : moment(endOfDay)
                    const length = newTo.diff(newFrom, 'minutes')
                    const workingStartHour = parseInt(workingHours[0].split(':')[0], 10)
                    const purifiedTitle = sanitize(event.title || '')
                    const soldOut = event.soldOut ? t('Sold out') : ''
                    list.push({
                        start: (newFrom.hours() - workingStartHour) * 60 + newFrom.minutes(),
                        length,
                        tooltip: `${purifiedTitle}<br />${newFrom.format('HH:mm')} - ${newTo.format('HH:mm')}<br/>${
                            soldOut || getTextLength(event, length)
                        }`,
                        fromDateTime: from,
                        toDateTime: to,
                        subTitle: '',
                        status: AppointmentStatus.Approved,
                        type: EventType.Event,
                        objectId: event.id,
                        bookedSeats: event.currentParticipants,
                        source: '',
                        maxSeats: event.maxParticipants,
                        allDay: event.allDay!,
                        free: true,
                        title: event.title || '',
                        from: event.from,
                        to: event.to,
                        color: event.color,
                        isPastEvent: event.pastEvent,
                    })
                }
            }
            blocks.push(list)
        }
        return blocks
    }, [nDays, pivot, filteredEventForWeek, business.timezone, workingHours, sanitize, t, getTextLength])

    const getPivot = useCallback(
        (date: Moment, nDays: number, isChange: boolean = false, forceMovePivot: boolean = false) => {
            if (!isChange || !isInView(pivot, date, nDays)) {
                switch (nDays) {
                    case 7:
                        return moment(date).startOf('week')
                    case 3:
                    default:
                        return forceMovePivot || !isInView(pivot, date, nDays) ? moment(date) : pivot
                }
            } else {
                return pivot
            }
        },
        [pivot]
    )

    const onResize = useCallback(() => {
        let newNDays = numberOfDays
        if (numberOfDays > 3 && window.innerWidth < TIMETABLE_BREAKPOINT) {
            newNDays = 3
        }
        setNDays(newNDays)
    }, [numberOfDays])

    useEffect(() => {
        onResize()
        if (numberOfDays > 3) {
            window.addEventListener('resize', onResize)
            return () => window.removeEventListener('resize', onResize)
        }
    }, [numberOfDays, onResize])

    const back = useCallback(
        (days?: number, force?: boolean) => {
            setPivot(getPivot(moment(pivot).subtract(days || nDays, 'day'), nDays, false, force))
        },
        [getPivot, nDays, pivot, setPivot]
    )

    const forward = useCallback(
        (days?: number, force?: boolean) => {
            setPivot(getPivot(moment(pivot).add(days || nDays, 'day'), nDays, false, force))
        },
        [getPivot, nDays, pivot, setPivot]
    )

    const onCloseEventPopup = useCallback(() => {
        setSelectedOccurrenceId('')
    }, [])

    return (
        <CalendarZoomContext.Provider value={{ hourHeight, setHourHeight }}>
            <ThemedContent inFullScreen={fullScreen} className={embedded ? 'p0' : ''}>
                <Calendar inFullScreen={fullScreen}>
                    <Header inFullScreen={fullScreen} className={classNames('w100')}>
                        {!hideTitle ? (
                            <Flex justifyContent="space-between" className="w100" style={{ minHeight: 40 }}>
                                <h2>
                                    <Trans ns="bookingpage">Group services</Trans>
                                </h2>
                                {!fullScreen ? (
                                    <NeutralIconButton
                                        icon={faExpand}
                                        aria-label={t('Expand calendar')}
                                        onClick={() => setFullScreen(true)}
                                    />
                                ) : null}
                            </Flex>
                        ) : null}
                        <Flex
                            justifyContent={embedded ? 'center' : 'space-between'}
                            gap={1}
                            shrink={0}
                            className={classNames(fullScreen ? '' : 'w100')}
                        >
                            <NeutralIconButton
                                aria-label={t('Calendar back')}
                                onClick={() => back()}
                                icon={faChevronLeft}
                            />
                            <FromToDisplay from={week[0].day} to={week[week.length - 1].day} />
                            <NeutralIconButton
                                aria-label={t('Calendar forward')}
                                onClick={() => forward()}
                                icon={faChevronRight}
                            />
                        </Flex>
                        {fullScreen ? (
                            <Flex className="w100" justifyContent="flex-end">
                                <NeutralIconButton
                                    icon={faCompress}
                                    aria-label={t('Compress calendar')}
                                    onClick={() => setFullScreen(false)}
                                />
                            </Flex>
                        ) : null}
                    </Header>
                    <GroupEventsTimetable>
                        <PageFrame>
                            <CalendarHead>
                                <StyledCalendarHeadDayHeaderContainer>
                                    {week.map(({ day }, i) => {
                                        return (
                                            <NoBorderDayHead key={day.day()}>
                                                <Flex gap={0.5}>
                                                    <span className="small semibold">
                                                        {DAY_NAMES_SHORT[getWeekDay(day) % 7]}
                                                    </span>
                                                    <DayBadge
                                                        className={classNames(
                                                            day.isSame(moment(), 'day') ? 'today' : ''
                                                        )}
                                                    >
                                                        {day.format('D')}
                                                    </DayBadge>
                                                </Flex>
                                            </NoBorderDayHead>
                                        )
                                    })}
                                </StyledCalendarHeadDayHeaderContainer>
                            </CalendarHead>
                            <StyledCalendarContainer>
                                <CalendarContent isFullScreen={fullScreen}>
                                    <TopBorderTimeScale>
                                        {workingHours.map((h, i) => (
                                            <Hour key={i} hourHeight={hourHeight}>
                                                <PositionedTimeScaleText>{i ? h : ''}</PositionedTimeScaleText>
                                            </Hour>
                                        ))}
                                    </TopBorderTimeScale>
                                    {week.map(({ day }, i) => {
                                        return (
                                            <WeekDay
                                                day={day}
                                                today={moment()}
                                                key={day.day()}
                                                onClickEvent={setSelectedOccurrenceId}
                                                events={_events ? _events[i] : []}
                                                workingHours={workingHours}
                                            />
                                        )
                                    })}
                                </CalendarContent>
                            </StyledCalendarContainer>
                        </PageFrame>
                    </GroupEventsTimetable>
                </Calendar>
                {selectedOccurrence ? (
                    <EventPopup occurrence={selectedOccurrence} onClose={onCloseEventPopup} embedded={embedded} />
                ) : null}
            </ThemedContent>
        </CalendarZoomContext.Provider>
    )
}
