import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { NeutralIconButtonNavLink } from '../../../components/ui-kit/button/NeutralIconButton'
import { OccurrencePublicDto } from '../../../store/events/types'
import { classNames } from '../../../services/class-names'
import { Trans } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { BusinessPublicDto } from '../../../bookingpage/types/business'
import { EventCoverImage } from '../components/EventCoverImage'

const UpcomingEventCardContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        borderRadius: 12,
        overflow: 'hidden',
        minHeight: 166,
        width: '100%',
        maxWidth: 334,
        border: `1px solid ${theme.BorderPrimary}`,
        cursor: 'pointer',
        '&:hover': {
            ...theme.BookingPageCardHoverBorderStyle,
        },
        '&.embedded': {
            maxWidth: 330,
        },
        '.image': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 86,
            width: '100%',
            overflow: 'hidden',
            img: {
                width: '100%',
                height: 'auto',
                aspectRatio: '740/240',
                backgroundColor: theme.Cover1,
            },
            svg: {
                '#shape-1': {
                    fill: theme.Cover1,
                },
                '#shape-2': {
                    fill: theme.Cover2,
                },
                '#shape-3': {
                    fill: theme.Cover3,
                },
            },
        },
        '.content': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: 16,
            overflow: 'hidden',
            '.icon': {
                fontSize: 20,
                width: 48,
                height: 48,
                marginRight: 24,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: theme.ContentSecondary,
            },
            '.date': {
                width: 48,
                height: 48,
                marginRight: 24,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                '.month': {
                    textTransform: 'uppercase',
                    color: theme.ThemeColor,
                    ...theme._BodySmall,
                },
                '.day': {
                    color: theme.Neutral_800,
                    ...theme._BodyMediumSemibold,
                },
            },
            '.title': {
                flex: '1 1 auto',
                h4: {
                    marginBottom: 4,
                },
                span: {
                    color: theme.ContentSecondary,
                },
            },
        },
    }))
)

export const UpcomingEventCard: FC<{ business: BusinessPublicDto; event: OccurrencePublicDto; isEmbed?: boolean }> = ({
    business,
    event,
    isEmbed,
}) => {
    const history = useHistory()
    const from = moment.tz(event.from, business.timezone)
    const to = moment.tz(event.to, business.timezone)
    const url = `/${isEmbed ? 'embed' : 'book'}/${business.urlHandle}/events/${event.eventId}`
    return (
        <UpcomingEventCardContainer
            onClick={(e) => {
                history.push(url)
            }}
            className={classNames(isEmbed ? 'embedded' : null)}
        >
            <div className="image">
                <EventCoverImage imagePublicId={event.imagePublicId} />
            </div>
            <div className="content">
                {event.hasMore ? (
                    <div className="icon">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                ) : (
                    <div className="date">
                        <span className="month">{from.format('MMM')}</span>
                        <span className="day">{from.format('D')}</span>
                    </div>
                )}
                <div className="title">
                    <h4>{event.title}</h4>
                    {event.hasMore ? (
                        <span className="small">
                            <Trans ns="bookingpage">Select date</Trans>
                        </span>
                    ) : (
                        <span className="small capitalize">
                            {from.format('dddd, HH:mm')} - {to.format(to.isSame(from, 'day') ? 'HH:mm' : 'dddd, HH:mm')}
                        </span>
                    )}
                </div>
                <div>
                    <NeutralIconButtonNavLink icon={faArrowRight} to={url} />
                </div>
            </div>
        </UpcomingEventCardContainer>
    )
}
