import styled from '@emotion/styled'
import axios from 'axios'
import moment from 'moment-timezone'
import { FC, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Flex } from '../../../components/helpers/Flex'
import { PrimaryButton, PrimaryButtonNavLink } from '../../../components/ui-kit/button/primary'
import { InputGroup } from '../../../components/ui-kit/comopnents/InputGroup'
import { Textarea } from '../../../components/ui-kit/comopnents/Textarea'
import i18n from '../../../i18n'
import { LoadingScreen } from '../../../components/ui-kit/comopnents/LoadingScreen'
import { Slot } from '../services/ServicePage'
import { EventSummaryCard } from './EventSummaryCard'
import { BusinessPublicDto } from '../../../bookingpage/types/business'
import { ServiceColor } from '../../../common/types/enums'
import { CantCancelCancelPageText } from './CantCancelCancelPageText'

interface CancelEventDto {
    date: string
    serviceImageUrl: string
    serviceLocation: string
    serviceName: string
    servicePrice: string
    serviceFrom: string
    serviceTo: string
    slot: Slot
    active: boolean
    canCancel: boolean
    canCancelUntil: number
    language: string
}

const Container = styled.div`
    position: relative;
`

export const CancelEvent: FC<{ business: BusinessPublicDto }> = ({ business }) => {
    const [loading, setLoading] = useState(true)
    const [sending, setSending] = useState(false)
    const params = useParams<{ handle: string; cancellationToken: string }>()
    const [error, setError] = useState('')
    const [reason, setReason] = useState('')
    const [eventDetails, setEventDetails] = useState<CancelEventDto | undefined>()
    const clientTimezone = moment.tz.guess()
    const { t } = useTranslation('bookingpage')

    const cancel = useCallback(() => {
        if (sending) {
            return
        }
        ;(async () => {
            setSending(true)
            try {
                await axios.post(`/api/book/events/cancel/${params.cancellationToken}`, { reason })
                setEventDetails({
                    ...eventDetails,
                    active: false,
                } as CancelEventDto)
            } catch {
                setError(t('Something went wrong, please try again later'))
            }
            setSending(false)
        })()
    }, [eventDetails, params.cancellationToken, reason, sending, t])

    useEffect(() => {
        if (!params.handle || !params.cancellationToken) {
            setError(t('Invalid link!'))
        }
        ;(async () => {
            try {
                const response = await axios.get<CancelEventDto>(
                    `/api/book/events/bycancellationtoken/${params.cancellationToken}`
                )
                setEventDetails(response.data)
                if (response.data && i18n.language !== response.data.language) {
                    await i18n.changeLanguage(response.data.language)
                }
            } catch (e: any) {
                if (e.response?.status === 404) {
                    setError(t('The appointment you are looking for cannot be found. Please check the link'))
                } else {
                    setError(t('Something went wrong, please try again later'))
                }
            }
            setLoading(false)
        })()
    }, [params.cancellationToken, params.handle, t])

    return (
        <Container>
            {!loading && !error && (
                <Flex className="mb">
                    <h2>
                        {eventDetails && eventDetails.active
                            ? t("Are you sure you wan't to cancel the appointment?")
                            : t('Appointment canceled successfully')}
                    </h2>
                </Flex>
            )}
            <Container>
                {error ? (
                    <div>{error}</div>
                ) : loading ? (
                    <LoadingScreen />
                ) : (
                    <div>
                        <EventSummaryCard
                            className={'mb' + (eventDetails!.active ? '' : ' line-through')}
                            success
                            business={business}
                            clientTimezone={clientTimezone}
                            event={{
                                id: '',
                                name: eventDetails!.serviceName,
                                description: '',
                                price: eventDetails!.servicePrice,
                                priceValue: null,
                                location: eventDetails!.serviceLocation,
                                maxParticipants: 0,
                                imagePublicId: eventDetails!.serviceImageUrl,
                                approvalRequired: false,
                                occurrences: [],
                                canCancelUntil: 0,
                                canScheduleFrom: 0,
                                canScheduleUntil: 0,
                                phoneRequired: false,
                            }}
                            occurrence={{
                                id: '',
                                title: eventDetails!.serviceName,
                                from: eventDetails!.serviceFrom,
                                to: eventDetails!.serviceTo,
                                price: eventDetails!.servicePrice,
                                maxParticipants: 0,
                                currentParticipants: 0,
                                maxSeatsPerBooking: 0,
                                soldOut: false,
                                imagePublicId: '',
                                eventId: '',
                                hasMore: false,
                                color: ServiceColor.Color1,
                                pastEvent: false,
                                allDay: false,
                            }}
                        />

                        {!eventDetails!.canCancel ? (
                            <>
                                <p className="mb semibold centered">
                                    <CantCancelCancelPageText canCancelUntil={eventDetails!.canCancelUntil} />
                                </p>
                                <Flex>
                                    <PrimaryButton className="danger" disabled style={{ cursor: 'not-allowed' }}>
                                        <Trans ns="bookingpage">Cancel appointment</Trans>
                                    </PrimaryButton>
                                </Flex>
                            </>
                        ) : eventDetails!.active ? (
                            <>
                                {' '}
                                <InputGroup className="mb">
                                    <label htmlFor="reason">
                                        <Trans ns="bookingpage">Reason for cancellation</Trans>:
                                    </label>
                                    <Textarea
                                        id="reason"
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                        placeholder={t('You can leave a note for the service provider. (optional)')}
                                        disabled={sending}
                                    />
                                </InputGroup>
                                <Flex>
                                    <PrimaryButton className="danger" onClick={cancel} disabled={sending}>
                                        <Trans ns="bookingpage">Cancel appointment</Trans>
                                    </PrimaryButton>
                                </Flex>
                            </>
                        ) : (
                            <Flex>
                                <PrimaryButtonNavLink to={`/book/${business.urlHandle}`}>
                                    <Trans ns="bookingpage">Book new appointment</Trans>
                                </PrimaryButtonNavLink>
                            </Flex>
                        )}
                    </div>
                )}
            </Container>
        </Container>
    )
}
