import { DecoratedCalendarEntry, DecoratedCalendarEntryWithLayout } from '../components/ui-kit/calendar/constants'

// 0/1 for constructing easy test matrix:
// const testMatrix = [
//     [1, 1, 1, 1, 0],
//     [1, 1, 1, 1, 0],
//     [1, 1, 1, 0, 0],
//     [1, 1, 0, 1, 1],
//     [0, 0, 0, 1, 1],
// ]
type booleanish = true | false | 0 | 1
type GraphMatrix = booleanish[][]

interface EventNode extends DecoratedCalendarEntryWithLayout {
    i: number
    neighbors: EventNode[]
}
type NodeMap = { [key: number]: EventNode }

// Converts events to nodes with position, index, and list of neighboring nodes
function createNodes(events: DecoratedCalendarEntry[]): EventNode[] {
    const nodes = events
        .sort((a, b) => (a.start >= b.start ? 1 : -1))
        .map((e, i) => {
            return {
                ...e,
                width: NaN,
                left: NaN,
                i,
                neighbors: [],
            } as EventNode
        })
    nodes.forEach((n) => {
        n.neighbors = nodes.filter(
            (other) => other !== n && other.start + other.length > n.start && other.start < n.start + n.length
        )
    })
    return nodes
}

// Builds an adjacency matrix from event nodes
function buildMatrix(nodes: EventNode[]): GraphMatrix {
    const matrix: boolean[][] = []

    for (const node of nodes) {
        matrix[node.i] = new Array<boolean>(nodes.length).fill(false)
        matrix[node.i][node.i] = true
        for (const neighbor of node.neighbors) {
            matrix[node.i][neighbor.i] = true
        }
    }

    return matrix
}

// Returns the first node from "group" with which node "i" is not a direct neighbor
// If node "i" is adjacent to each node from "group", return false
function getNotNeighboring(i: number, group: number[], matrix: GraphMatrix): number | false {
    for (var neighbor of group) {
        if (i !== neighbor && !matrix[i][neighbor]) {
            return neighbor
        }
    }
    return false
}

// Get groups of nodes from an adjacency matrix
// A "group" is a subset of nodes which forms a complete graph
// (each node is directly adjacent to each other in the group)
function getGroups(matrix: GraphMatrix, nodes: EventNode[]) {
    // Final list of groups
    const groups: number[][] = []
    // List of possible groups to check
    const maybe_queue: number[][] = []
    // Start with the assumption that all the nodes form a group
    maybe_queue.push(matrix.map((_, i) => i))
    // While we have anything in the queue check if they are
    // really form a group
    do {
        // Get the first candidate from the queue
        const maybe_group = maybe_queue.shift()
        // If queue was empty, exit the loop
        if (maybe_group === undefined) {
            break
        }
        // Let's assume it's good, until proven otherwise
        let good = true
        // Check each node
        for (const i of maybe_group) {
            // Check if there is a node that is not adjacent to node "i"
            const notNeighboring = getNotNeighboring(i, maybe_group, matrix)
            // If found, the candidate didn't meet the criteria, so we
            // split it in two, one containing node "i" the other node "notNeighboring"
            // and add them to the queue
            if (notNeighboring !== false) {
                maybe_queue.push(maybe_group.filter((node) => node !== notNeighboring))
                maybe_queue.push(maybe_group.filter((node) => node !== i))
                good = false
                break
            }
        }

        // Id the candidate proved to be good, and it's not just a subset
        // of a group already found then we add it to the final list
        if (good) {
            if (!groups.some((group) => maybe_group.every((node) => group.includes(node)))) {
                groups.push(maybe_group)
            }
        }
    } while (maybe_queue.length)

    return groups
}

// Backtracking algorithm to find arrangement of nodes
// Try to place all nodes that don't yet have a position, starting from "index" and at position "left"
function placeNext(nodes: EventNode[], groups: number[][], nodeMap: NodeMap, index: number, left: number = 0): boolean {
    // if we are over the array, we fail
    if (index >= nodes.length) {
        return false
    }

    // Width of current node
    const width = nodeMap[index].width
    // End position of current node
    const end = left + width

    // If end is over the edge, we fail
    if (end > 1) {
        return false
    }

    // Check if current placement collides with any node in the group
    for (const group of groups) {
        if (group.includes(index)) {
            for (const n of group) {
                const node = nodeMap[n]
                // If collides, try placing this node at the end of the one that it collides with
                if (n !== index && end > node.left && left < node.left + node.width) {
                    return placeNext(nodes, groups, nodeMap, index, node.left + node.width)
                }
            }
        }
    }

    // If we are all good, set the position and find the next node to place
    nodeMap[index].left = left

    const groupsByReadiness = groups
        .filter((group) => group.some((i) => isNaN(nodeMap[i].left)))
        .sort((a, b) => a.filter((i) => isNaN(nodeMap[i].left)).length - b.filter((i) => isNaN(nodeMap[i].left)).length)

    if (groupsByReadiness.length) {
        for (const group of groupsByReadiness) {
            const nextNodes = group.filter((i) => isNaN(nodeMap[i].left))
            // If there are others, try to arrange those
            let success = false
            for (const nextNode of nextNodes) {
                success = placeNext(nodes, groups, nodeMap, nextNode, 0)
                // If arrangement was possible, we're good,
                // otherwise we try placing the next node
                if (success) {
                    return true
                }
            }

            // If there was no possible arrangement starting with this node,
            // reset to unpositioned and fail
            nodeMap[index].left = NaN
            return false
        }
    } else {
        return true
    }

    // If there was no possible arrangement starting with this node,
    // reset to unpositioned and fail
    nodeMap[index].left = NaN
    return false
}

// Start the backtracking algorithm to place the nodes the nodes
function placeNodes(nodes: EventNode[], groups: number[][], nodeMap: NodeMap) {
    let success = false
    // Starting with the first node, try to
    // make an arrangement, where this node is
    // at the leftmost place in the row
    for (let i = 0; i < nodes.length; ++i) {
        success = placeNext(nodes, groups, nodeMap, nodes[i].i)
        // If we could place it, then no need to go further
        // if no placement possible with this as the starting node
        // we'll try with the next block as first
        if (success) {
            break
        }
    }

    // Shouldn't happen, but in case there was no suitable arrangement
    // we fall back to a simple overlapping design
    if (!success) {
        console.error('Failed to create arrangement for group:', groups)
        let left = 0
        // Each node should be at lest 1/3rd width, or more if possible
        const width = Math.max(1.0 / nodes.length, 1 / 3)
        // Place the nodes starting from 0 in equal increments
        // so the last node will reach until the end
        const step = (1 - width) / (nodes.length - 1)
        nodes.forEach((node) => {
            if (isNaN(node.left)) {
                node.width = width
                node.left = left
                left += step
            }
        })
    }
}

// Arranges a block of nodes so that every block takes as much space
// as possible and there is no overlap between two events inside the block
function arrangeBlock(nodes: EventNode[]): DecoratedCalendarEntryWithLayout[] {
    // If empty, do nothing
    if (!nodes.length) {
        return []
    }

    // Get the adjacency matrix
    const matrix = buildMatrix(nodes)
    // Get the groups between the block
    const groups = getGroups(matrix, nodes)
    // Create a simple key => node map from the nodes, for easy access
    const nodeMap: NodeMap = nodes.reduce((map, node) => ({ ...map, [node.i]: node }), {})

    // Starting with the longest group, calculate the width for the events
    // longest group must have the smallest event width, and the rest always
    // divides the remaining space
    for (const group of groups.sort((a, b) => (a.length > b.length ? 0 : 1))) {
        // Start with the whole space as available
        let availableSpace = 1
        // If any node already got it's length from a previous group
        // decrease the available space it takes up
        for (const index of group) {
            availableSpace -= nodeMap[index].width || 0
        }
        // Then divide the remaining space equally between the nodes
        // that don't have a width yet
        const noWidth = group.filter((index) => !nodeMap[index].width)
        for (const index of noWidth) {
            nodeMap[index].width = availableSpace / noWidth.length
        }
    }

    // Round down, so there is no going over the full width because of float rounding errors
    for (let node of nodes) {
        node.width = Math.floor(node.width * 1000.0) / 1000
    }

    // Then arrange them in a column so no event overlaps an other
    placeNodes(nodes, groups, nodeMap)

    return nodes
}

// Fuction to create the calendar layout
export function createLayout(events: DecoratedCalendarEntry[]): DecoratedCalendarEntryWithLayout[] {
    const blocks: DecoratedCalendarEntry[][] = []
    // Select all the long events, that would mess up the layout and treat them separately
    const longEvents = events.filter((e) => e.start === 0 && e.length > 900)
    blocks.push(longEvents)

    // Sort the rest of the events by start time
    let sorted = events
        .filter((e) => e.objectId && !longEvents.includes(e))
        .sort((a, b) => (a.start >= b.start ? 1 : -1))

    // Sort the events into "blocks"
    // A block is a group of events that collide with each other
    // If an event is a node of a graph and edges are between nodes that have an overlap in time
    // then a block contains all nodes that have a (not necessarily direct) path between them
    let current = 0
    while (current < sorted.length) {
        const block = []
        block.push(sorted[current])
        let next = current + 1
        let end = sorted[current].start + sorted[current].length
        while (next < sorted.length) {
            if (sorted[next].start >= end) {
                break
            }
            block.push(sorted[next])
            end = Math.max(end, sorted[next].start + sorted[next].length)
            next++
        }
        blocks.push(block)
        current = next
    }

    // Then take each block and arrange the events in them separately
    // and finally return an array of all the events in a single array
    return blocks
        .map((block) => arrangeBlock(createNodes(block)))
        .reduce((flattened, events) => flattened.concat(events), [])
}
