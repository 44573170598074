import { FC, useCallback, useMemo, useState } from 'react'
import { OccurrencePublicDto } from '../../store/events/types'
import { Route, useHistory, useParams } from 'react-router-dom'
import { GroupServicesDisplay } from '../../common/types/enums'
import { BusinessPublicDto } from '../../bookingpage/types/business'
import { useMatchMedia } from '../../hooks/use-match-media'
import { BookingPageServicesViewInMobile } from './BookingPageServicesViewInMobile'
import { Column } from '../../components/admin/layout/Column'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Flex } from '../../components/helpers/Flex'
import { AdminTheme } from '../../theme/theme'
import { NeutralIconButtonNavLink } from '../../components/ui-kit/button/NeutralIconButton'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Trans } from 'react-i18next'
import { SearchToggleInput } from '../../components/ui-kit/comopnents/InputWithIconsAndAnimation'
import { ServiceCategoryPublicDto } from '../../bookingpage/types/service-category'
import { NavBar } from '../../components/ui-kit/comopnents/NavBar'
import { classNames } from '../../services/class-names'
import { NavItem } from '../../components/ui-kit/comopnents/NavItem'
import { BookingPageServicesViewDesktop } from './BookingPageServicesViewDesktop'

const TitleContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        backgroundColor: theme.SurfaceColor,
        justifyContent: 'space-between',
        width: '100%',
        position: 'relative',
        minHeight: 42,

        '.title': {
            color: theme.ContentPrimary,
        },
    }))
)

const BoldNavItem = withTheme(
    styled(NavItem)(({ theme }: { theme: AdminTheme }) => ({
        'button.nav-item': {
            fontWeight: 600,
            color: theme.ContentPrimary,
        },
    }))
)

const HeaderContainer = withTheme(
    styled(Column)(({ theme }: { theme: AdminTheme }) => ({
        gap: `${theme.Spacing(2.5)} !important`,
        backgroundColor: theme.SurfaceColor,
        padding: `${theme.Spacing(2.5)} ${theme.Spacing(2.5)} 0`,
    }))
)

export type BookingPageNavBarServiceType = 'group-services' | '1-on-1-services'

const Title: FC<{
    business: BusinessPublicDto
    setTab: (tab: BookingPageNavBarServiceType) => void
    selectedCategory?: ServiceCategoryPublicDto
    searchTerm: string
    setSearchTerm: (search: string) => void
    hideSearch?: boolean
}> = ({ business, setTab, selectedCategory, searchTerm, setSearchTerm, hideSearch }) => {
    return (
        <TitleContainer>
            <Flex gap={1}>
                {selectedCategory ? (
                    <NeutralIconButtonNavLink
                        icon={faArrowLeft}
                        to={`/book/${business.urlHandle}`}
                        onClick={() => setTab('1-on-1-services')}
                    />
                ) : null}
                <h2 className="title">
                    {selectedCategory ? selectedCategory.name : <Trans ns="bookingpage">Book an appointment</Trans>}
                </h2>
            </Flex>
            {hideSearch ? null : <SearchToggleInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} fullWidth />}
        </TitleContainer>
    )
}

interface HeaderProps {
    tab: BookingPageNavBarServiceType
    business: BusinessPublicDto
    events: OccurrencePublicDto[]
    timetableView: boolean
    searchTerm: string
    setSearchTerm: (searchTerm: string) => void
    setTab: (tab: BookingPageNavBarServiceType) => void
}

const Header: FC<HeaderProps> = ({ tab, business, events, setTab, timetableView, searchTerm, setSearchTerm }) => {
    const history = useHistory()
    const { categoryId } = useParams<{ categoryId?: string }>()

    const selectedCategory = useMemo(() => {
        return business.serviceCategories.find((category) => category.id === categoryId)
    }, [business.serviceCategories, categoryId])

    const onChangeTab = useCallback(
        (value: BookingPageNavBarServiceType) => {
            setTab(value)
            if (selectedCategory) {
                history.push(`/book/${business.urlHandle}`)
            }
            setSearchTerm('')
        },
        [business.urlHandle, history, selectedCategory, setSearchTerm, setTab]
    )
    return (
        <HeaderContainer>
            {tab === 'group-services' ? (
                <Title
                    business={business}
                    setTab={setTab}
                    hideSearch={timetableView}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            ) : (
                <Title
                    business={business}
                    setTab={setTab}
                    selectedCategory={selectedCategory}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            )}
            {events.length && business.services.length ? (
                <NavBar className="inline-nav p0 primary">
                    {timetableView ? (
                        <BoldNavItem>
                            <button
                                type="button"
                                className={classNames('nav-item', tab === 'group-services' ? 'active' : null)}
                                onClick={() => {
                                    onChangeTab('group-services')
                                }}
                            >
                                <Trans ns="bookingpage" i18nKey="group-services-mobile-title" />
                            </button>
                        </BoldNavItem>
                    ) : null}
                    {
                        <BoldNavItem>
                            <button
                                type="button"
                                className={classNames('nav-item', tab === '1-on-1-services' ? 'active' : null)}
                                onClick={() => onChangeTab('1-on-1-services')}
                            >
                                <Trans ns="bookingpage" i18nKey="1-on-1-services-mobile-title" />
                            </button>
                        </BoldNavItem>
                    }
                    {timetableView ? null : (
                        <BoldNavItem>
                            <button
                                type="button"
                                className={classNames('nav-item', tab === 'group-services' ? 'active' : null)}
                                onClick={() => {
                                    onChangeTab('group-services')
                                }}
                            >
                                <Trans ns="bookingpage" i18nKey="group-services-mobile-title" />
                            </button>
                        </BoldNavItem>
                    )}
                </NavBar>
            ) : null}
        </HeaderContainer>
    )
}

export const BookingPageServicesView: FC<{
    business: BusinessPublicDto
    events: OccurrencePublicDto[]
}> = ({ business, events }) => {
    const timetableView = useMemo(
        () => business.groupServicesDisplay === GroupServicesDisplay.Timetable,
        [business.groupServicesDisplay]
    )
    const [tab, setTab] = useState<BookingPageNavBarServiceType>(() =>
        events.length && (!business.services.length || timetableView) ? 'group-services' : '1-on-1-services'
    )
    const [searchTerm, setSearchTerm] = useState('')
    const smallScreen = useMatchMedia('(max-width: 1129px)')

    return (
        <Route path={['/book/:handle', '/book/:handle/category/:categoryId']} exact>
            {smallScreen ? (
                <>
                    <Header
                        tab={tab}
                        business={business}
                        events={events}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        setTab={setTab}
                        timetableView={timetableView}
                    />
                    <BookingPageServicesViewInMobile
                        business={business}
                        events={events}
                        tab={tab}
                        search={searchTerm}
                        timetableView={timetableView}
                    />
                </>
            ) : (
                <BookingPageServicesViewDesktop events={events} business={business} timetableView={timetableView} />
            )}
        </Route>
    )
}
