import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme, fromTheme } from '../../../theme/theme'
import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { classNames } from '../../../services/class-names'
import { Flex } from '../../helpers/Flex'

export const CalendarEvent = withTheme(styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    border-radius: 2px;
    cursor: pointer;

    .before,
    .after {
        left: 0;
        right: 0;
        height: 5px;
        position: absolute;
        display: none;
        cursor: ns-resize;
        z-index: 2;
        &::before {
            display: block;
            position: absolute;
            background: white;
            border: 1px solid ${fromTheme('ContentDisabled')};
            border-radius: 6px;
            width: 12px;
            height: 12px;
            content: '';
            box-sizing: border-box;
            cursor: ns-resize;
        }
    }
    .before {
        top: -2px;
        &::before {
            right: 8px;
            top: -4px;
        }
    }
    .after {
        bottom: -2px;
        &::before {
            left: 8px;
            bottom: -5px;
        }
    }

    &.selected {
        .before,
        .after {
            display: block;
        }
    }

    & .header {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 4px;
        border-radius: 2px 0 0 2px;
    }

    & .title {
        ${fromTheme('CaptionSemibold')};
    }

    & .description {
        ${fromTheme('Caption')};
    }

    &.break,
    &.holiday {
        background-color: ${fromTheme('BackgroundColor')};
        & .header {
            background-color: ${fromTheme('ContentDisabled')};
        }
    }

    &.appointment.color-1,
    &.event.color-1 {
        background-color: ${fromTheme('ServiceColorVariant1Background')};
        & .header {
            background-color: ${fromTheme('ServiceColorVariant1Color')};
        }
    }

    &.appointment.color-2,
    &.event.color-2 {
        background-color: ${fromTheme('ServiceColorVariant2Background')};
        & .header {
            background-color: ${fromTheme('ServiceColorVariant2Color')};
        }
    }

    &.appointment.color-3,
    &.event.color-3 {
        background-color: ${fromTheme('ServiceColorVariant3Background')};
        & .header {
            background-color: ${fromTheme('ServiceColorVariant3Color')};
        }
    }

    &.appointment.color-4,
    &.event.color-4 {
        background-color: ${fromTheme('ServiceColorVariant4Background')};
        & .header {
            background-color: ${fromTheme('ServiceColorVariant4Color')};
        }
    }

    &.appointment.color-5,
    &.event.color-5 {
        background-color: ${fromTheme('ServiceColorVariant5Background')};
        & .header {
            background-color: ${fromTheme('ServiceColorVariant5Color')};
        }
    }

    &.appointment.color-6,
    &.event.color-6 {
        background-color: ${fromTheme('ServiceColorVariant6Background')};
        & .header {
            background-color: ${fromTheme('ServiceColorVariant6Color')};
        }
    }

    &.appointment.color-other {
        background-color: ${fromTheme('Primary_50')};
        & .header {
            background-color: ${fromTheme('ContentBrand')};
        }
    }

    &.appointment.color-google {
        background-color: ${fromTheme('BackgroundColor')};
        & .header {
            background-color: ${fromTheme('ContentDisabled')};
        }
    }

    &.appointment.status-0 {
        border: 1px dashed;
        & .header {
            display: none;
        }
        &.color-1 {
            border-color: ${fromTheme('ServiceColorVariant1Color')};
        }
        &.color-2 {
            border-color: ${fromTheme('ServiceColorVariant2Color')};
        }
        &.color-3 {
            border-color: ${fromTheme('ServiceColorVariant3Color')};
        }
        &.color-4 {
            border-color: ${fromTheme('ServiceColorVariant4Color')};
        }
        &.color-5 {
            border-color: ${fromTheme('ServiceColorVariant5Color')};
        }
        &.color-6 {
            border-color: ${fromTheme('ServiceColorVariant6Color')};
        }
    }

    &.edited {
        border: 1px dashed ${fromTheme('ContentBrand')};
        background-color: ${fromTheme('Opacity_10')};
        &::before,
        &::after {
            border-color: ${fromTheme('ContentBrand')};
        }
    }

    &.color-google.free {
        & .header {
            display: none;
        }
        border: 1px dashed ${fromTheme('ContentSecondary')};
        background-color: rgba(154, 168, 187, 0.1);
    }

    &:hover {
        z-index: 1;
        box-shadow: ${fromTheme('Elevation_100')};
    }

    &.selected {
        z-index: 1;
        box-shadow: ${fromTheme('Elevation_300')};
    }
`)

const CalendarEventContentContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        overflow: 'hidden',
        padding: `${theme.Spacing(0.25)} ${theme.Spacing(1)}`,
        [theme.BreakPoint]: {
            padding: `${theme.Spacing(0.25)} ${theme.Spacing(1)} ${theme.Spacing(0.25)} ${theme.Spacing(1.5)}`,
        },

        '> .details': {
            padding: `${theme.Spacing(0.25)} 0`,
            [theme.BreakPoint]: {
                padding: `${theme.Spacing(0.75)} 0`,
            },
            flexDirection: 'column',
            height: '100%',
            overflow: 'hidden',
            '> .title': {
                overflow: 'hidden',
                overflowWrap: 'anywhere',
                minHeight: 0,
                flexShrink: 1,
            },
            '> .description': {
                flexShrink: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textWrap: 'nowrap',
                maxWidth: '100%',
            },
        },

        '&.short': {
            display: 'flex',
            '> .details': {
                padding: 0,
                alignItems: 'center',
                flexDirection: 'row',
                overflow: 'hidden',
                '> .title': {
                    flex: '1 1 0',
                    minWidth: 0,
                },
                '> .title, > .description': {
                    marginRight: theme.Spacing(0.5),
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: theme.Spacing(2),
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                },
            },
        },
    }))
)

export const CalendarEventContent: FC<{ children: (short: boolean) => ReactNode }> = ({ children }) => {
    const ref = useRef<HTMLDivElement>()
    const [short, setShort] = useState(false)
    const [observe, setObserve] = useState(false)

    useEffect(() => {
        const element = ref.current
        if (element) {
            setShort(element.clientHeight < 42)
            setObserve(true)
        }
    }, [])

    useEffect(() => {
        if (observe) {
            const element = ref.current
            if (element) {
                const observer = new ResizeObserver((elems) => {
                    if (elems.length > 0) {
                        setShort(elems[0].target.clientHeight < 42)
                    }
                })
                observer.observe(element)
                return () => {
                    observer.unobserve(element)
                }
            }
        }
    }, [observe])

    return (
        <CalendarEventContentContainer className={classNames(short ? 'short' : null)}>
            <Flex ref={ref as any} className="details" alignItems="flex-start" justifyContent="flex-start">
                {children(short)}
            </Flex>
        </CalendarEventContentContainer>
    )
}
