import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme, fromTheme } from '../../../theme/theme'

export const ColumnHead = withTheme(styled.div`
    height: 68px;
    background: white;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
`)

export const DayHead = withTheme(styled(ColumnHead)`
    border-left: 1px solid ${fromTheme('Neutral_300')};
    overflow: hidden;

    &.today {
        color: ${fromTheme('Primary_700')};
        .small {
            font-weight: 600;
        }
        .date {
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background-color: ${fromTheme('Primary_700')};
            width: 32px;
            height: 32px;
            border-radius: 16px;
        }
    }
`)

export const DayContainer = withTheme(
    styled.div(
        ({ hourHeight, theme }: { hourHeight: number; theme: AdminTheme }) => `
    position: relative;
    height: ${24 * hourHeight}px;
    flex: 1;

    & .currentTime {
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        border-bottom: 1px dashed ${theme.Secondary4_700};
    }

    &.today .currentTime {
        border-bottom: 1px solid ${theme.Secondary4_700};
        &::before {
            box-sizing: border-box;
            content: '';
            display: block;
            position: absolute;
            top: -2px;
            left: 0;
            background-color: ${theme.Secondary4_700};
            width: 5px;
            height: 5px;
            border: none;
            border-radius: 2.5px;
        }
    }
`
    )
)

export const TimeScaleText = withTheme(styled.span`
    position: absolute;
    top: -10px;
    right: 8px;
    ${fromTheme('BodySmall')}
`)

export const Hour = withTheme(
    styled.div(
        ({ hourHeight, theme }: { hourHeight: number; theme: AdminTheme }) => `
    position: relative;
    height: ${hourHeight}px;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    &.day-hour {
        background-color: ${theme.Neutral_100};
        border-left: 1px solid ${theme.Neutral_300};
        &:not(:first-of-type) {
            border-top: 1px solid ${theme.Neutral_300};
        }
        .open {
            background-color: white;
        }
    }
`
    )
)

export const DayHalfHour = withTheme(styled.div`
    height: 50%;
    &:first-of-type {
        border-bottom: 1px dashed ${fromTheme('Neutral_300')};
    }
`)

export const PageFrame = withTheme(styled.div`
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;

    .calendar-nav {
        padding-left: 20px;
        ${fromTheme('BreakPoint')} {
            padding-left: 0;
        }
    }
`)
