import React, { useContext, useMemo } from 'react'
import { CalendarZoomContext, DecoratedCalendarEntryWithLayout } from '../../../components/ui-kit/calendar/constants'
import { StyledTooltip } from '../../../components/ui-kit/comopnents/StyledTooltip'
import { classNames } from '../../../services/class-names'
import { CalendarEvent, CalendarEventContent } from '../../../components/ui-kit/calendar/CalendarEvent'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { MultilineEllipsis } from '../../../components/ui-kit/comopnents/MultilineEllipsis'
import { asClickable } from '../../../components/helpers/as-clickable'

const ThemedCalendarEvent = withTheme(
    styled(CalendarEvent)(({ theme }: { theme: AdminTheme }) => ({
        color: theme.ContentPrimary,

        '&.event.not-available': {
            '.header': {
                backgroundColor: theme.BorderDisabled,
            },
            backgroundColor: theme.BackgroundNeutralSecondary,
            color: theme.ContentDisabled,
        },
    }))
)

export interface DayEventProps {
    event: DecoratedCalendarEntryWithLayout
    workingHoursLength: number
    onClick: (id: string) => void
}

const ClickableThemedCalendarEvent = asClickable(ThemedCalendarEvent)

export const DayEvent = React.forwardRef<any, DayEventProps>(({ event, workingHoursLength, onClick }, ref) => {
    const { hourHeight } = useContext(CalendarZoomContext)
    const top = useMemo(() => (event.start / 60.0) * hourHeight, [event.start, hourHeight])
    const bottom = useMemo(
        () => ((event.start + event.length) / 60.0) * hourHeight,
        [event.length, event.start, hourHeight]
    )

    return (
        <StyledTooltip dangerouslySetIsHtml text={event.tooltip} onlyBookingDesktop>
            <ClickableThemedCalendarEvent
                ref={ref}
                id={`appointment-${event.objectId}`}
                onClick={() => onClick(event.objectId)}
                role="button"
                aria-label={
                    event.fromDateTime &&
                    event.toDateTime &&
                    `event ${event.fromDateTime.format('HH:mm')} - ${event.toDateTime.format(
                        'HH:mm'
                    )}, ${event.fromDateTime.format('MMMM DD.')} ${event.title}`
                }
                className={classNames(
                    'calendar-event',
                    'event',
                    event.isPastEvent || event.maxSeats - event.bookedSeats <= 0 ? 'not-available' : null,
                    `color-${event.color}`
                )}
                style={{
                    top: `${top + 2}px`,
                    bottom: `${hourHeight * workingHoursLength - bottom + 1}px`,
                    left: `calc(${event.left * 100}% + 2px)`,
                    width: `calc(${event.width * 100}% - 3px)`,
                }}
            >
                {event && (
                    <CalendarEventContent>
                        {(short) => (
                            <>
                                <div className="header" />
                                <MultilineEllipsis
                                    className="title"
                                    lineHeight={16}
                                    disableClamp={short}
                                    reRender={hourHeight}
                                >
                                    {event.title}
                                </MultilineEllipsis>
                            </>
                        )}
                    </CalendarEventContent>
                )}
            </ClickableThemedCalendarEvent>
        </StyledTooltip>
    )
})
