import { Dispatch, ReactNode, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { NotificationActionTypes } from '../store/notifications/actions'
import { NotificationType } from '../store/notifications/types'
import { createAction } from '../store/utils/create-action'
import { ERROR_MESSAGE_SEPARATOR, useGetErrorMessage } from '../services/getErrorMessage'

type NotifiactionDispatcher = (
    title: string | ReactNode,
    message?: string | ReactNode,
    closeAfter?: number,
    id?: string,
    cta?: ReactNode,
    icon?: ReactNode
) => void

function createNotificationDispatcher(dispatch: Dispatch<any>, type: NotificationType): NotifiactionDispatcher {
    return (
        title: string | ReactNode,
        message?: string | ReactNode,
        closeAfter?: number,
        id?: string,
        cta?: ReactNode,
        icon?: ReactNode
    ) =>
        dispatch(
            createAction(NotificationActionTypes.CREATE_NOTIFICATION, {
                type,
                title,
                message,
                closeAfter,
                id,
                cta,
                icon,
            })
        )
}

function removeNotificationDispatcher(dispatch: Dispatch<any>) {
    return (id: string) => dispatch(createAction(NotificationActionTypes.REMOVE_NOTIFICATION, id))
}

function removeAllNotificationDispatcher(dispatch: Dispatch<any>) {
    return (prefix: string) => dispatch(createAction(NotificationActionTypes.REMOVE_ALL_NOTIFICATION, prefix))
}

export function useToast() {
    const dispatch = useDispatch()
    const getErrorMessage = useGetErrorMessage()

    return useMemo(() => {
        return {
            info: createNotificationDispatcher(dispatch, NotificationType.Info),
            success: createNotificationDispatcher(dispatch, NotificationType.Success),
            warning: createNotificationDispatcher(dispatch, NotificationType.Warning),
            error: createNotificationDispatcher(dispatch, NotificationType.Error),
            apiError: async (e: any, ...rest: any) => {
                const message = await getErrorMessage(e, ...rest)
                const dispacther = createNotificationDispatcher(dispatch, NotificationType.Error)
                const parts = message.split(ERROR_MESSAGE_SEPARATOR)
                dispacther(parts[0], parts[1] || undefined)
            },
            black: createNotificationDispatcher(dispatch, NotificationType.Black),
            remove: removeNotificationDispatcher(dispatch),
            removeAll: removeAllNotificationDispatcher(dispatch),
        }
    }, [dispatch, getErrorMessage])
}
