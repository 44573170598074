import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import { EventPublicDto } from '../../../store/events/types'
import { EventDetails } from './EventDetails'
import { EventDates } from './EventDates'
import { LoadingScreen } from '../../../components/ui-kit/comopnents/LoadingScreen'
import { Flex } from '../../../components/helpers/Flex'
import { EventContactInfo } from './EventContactInfo'
import { NeutralIconButton } from '../../../components/ui-kit/button/NeutralIconButton'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import styled from '@emotion/styled'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'
import { useTranslation } from 'react-i18next'
import { BookingPageEventCover } from '../components/BookingPageEventCover'
import { EventForm } from './EventForm'
import { getEventBookUrl } from './helpers/get-event-book-url'
import { ContactInfo } from '../components/ContactInfoScreen'
import { EventOverview } from './EventOverview'
import { MinupRibbon } from '../../../components/helpers/MinupRibbon'
import { NotFoundPage } from '../NotFound'
import { BusinessPublicDto } from '../../../bookingpage/types/business'
import { useToast } from '../../../hooks/use-toast'
import { useGetErrorMessage } from '../../../services/getErrorMessage'
import { captureException } from '../../../appInsights'
import { EventCoverImage } from '../components/EventCoverImage'

const BackButton = withTheme(
    styled(NeutralIconButton)(({ theme }: { theme: AdminTheme }) => ({
        position: 'absolute',
        top: 16,
        left: 16,
        color: `${theme.InteractiveCoverClose} !important`,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
        },
    }))
)

const PageCover: FC<{ event: EventPublicDto; goBack?: () => void; business: BusinessPublicDto }> = ({
    event,
    goBack,
    business,
}) => {
    const { t } = useTranslation('bookingpage')
    return (
        <BookingPageEventCover className="relative">
            {!business.hideMinupBranding ? <MinupRibbon /> : null}
            <EventCoverImage imagePublicId={event.imagePublicId} id="cover" width={740} />
            {goBack && <BackButton icon={faArrowLeft} type="button" onClick={goBack} aria-label={t('Back')} />}
        </BookingPageEventCover>
    )
}

export const EventPage: FC<{ business: BusinessPublicDto; hideBackButton?: boolean }> = ({
    business,
    hideBackButton,
}) => {
    const params = useParams<{ handle: string; eventId: string; occurrenceId?: string }>()
    const [event, setEvent] = useState<EventPublicDto | undefined>()
    const [loading, setLoading] = useState(true)
    const [contactInfo, setContactInfo] = useState<ContactInfo | undefined>(undefined)
    const [answers, setAnswers] = useState<string>('[]')
    const history = useHistory()
    const toast = useToast()
    const getErrorMessage = useGetErrorMessage()

    useEffect(() => {
        ;(async () => {
            try {
                setLoading(true)
                const response = await fetch(`/api/book/byhandle/${params.handle}/events/${params.eventId}`)
                if (response.ok) {
                    const event = await response.json()
                    setEvent(event)
                }
            } catch (e) {
                toast.error(await getErrorMessage(e))
                captureException(e)
            } finally {
                setLoading(false)
            }
        })()
    }, [getErrorMessage, params.eventId, params.handle, toast])

    const onFormSubmit = useCallback(
        (answers: string) => {
            setAnswers(answers)
            history.push(
                getEventBookUrl(
                    history.location.pathname.startsWith('/embed'),
                    params.handle,
                    event!,
                    params.occurrenceId!,
                    true
                )
            )
        },
        [event, history, params.handle, params.occurrenceId]
    )

    const hasBack = !hideBackButton || params.occurrenceId
    const back = useCallback(() => {
        window.history.back()
    }, [])
    const goBack = useMemo(() => (hasBack ? back : undefined), [back, hasBack])

    return (
        <>
            {loading ? (
                <Flex className="p" alignItems="center" justifyContent="center">
                    <LoadingScreen noBackground inline />
                </Flex>
            ) : event ? (
                <Switch>
                    <Route path="/book/:handle/events/:eventId" exact>
                        <PageCover
                            business={business}
                            event={event}
                            goBack={hasBack ? () => history.push(`/book/${params.handle}`) : undefined}
                        />
                        <EventDetails event={event} occurrences={event.occurrences} />
                    </Route>
                    <Route path="/book/:handle/events/:eventId/dates" exact>
                        <PageCover
                            business={business}
                            event={event}
                            goBack={
                                hasBack
                                    ? () => history.push(`/book/${params.handle}/events/${params.eventId}`)
                                    : undefined
                            }
                        />
                        <EventDates event={event} timezone={business.timezone} />
                    </Route>
                    <Route path="/book/:handle/events/:eventId/:occurrenceId/form" exact>
                        <PageCover business={business} event={event} goBack={goBack} />
                        <EventForm event={event} answers={answers} setAnswers={onFormSubmit} />
                    </Route>
                    <Route path="/book/:handle/events/:eventId/:occurrenceId/checkout" exact>
                        <EventContactInfo
                            business={business}
                            event={event}
                            answers={answers}
                            contactInfo={contactInfo!}
                            setContactInfo={setContactInfo}
                        />
                    </Route>
                    <Route path="/book/:handle/events/:eventId/:occurrenceId/overview" exact>
                        <EventOverview contactInfo={contactInfo!} answers={answers} event={event} business={business} />
                    </Route>
                    <Route path="/embed/:handle/events/:eventId" exact>
                        <PageCover
                            business={business}
                            event={event}
                            goBack={hasBack ? () => history.push(`/embed/${params.handle}/events`) : undefined}
                        />
                        <EventDetails event={event} occurrences={event.occurrences} isEmbed />
                    </Route>
                    <Route path="/embed/:handle/events/:eventId/dates" exact>
                        <PageCover
                            business={business}
                            event={event}
                            goBack={
                                hasBack
                                    ? () => history.push(`/embed/${params.handle}/events/${params.eventId}`)
                                    : undefined
                            }
                        />
                        <EventDates event={event} timezone={business.timezone} isEmbed />
                    </Route>
                    <Route path="/embed/:handle/events/:eventId/:occurrenceId/form" exact>
                        <PageCover business={business} event={event} goBack={goBack} />
                        <EventForm event={event} answers={answers} setAnswers={onFormSubmit} />
                    </Route>
                    <Route path="/embed/:handle/events/:eventId/:occurrenceId/checkout" exact>
                        <EventContactInfo
                            business={business}
                            event={event}
                            answers={answers}
                            isEmbed
                            contactInfo={contactInfo!}
                            setContactInfo={setContactInfo}
                        />
                    </Route>
                    <Route path="/embed/:handle/events/:eventId/:occurrenceId/overview" exact>
                        <EventOverview contactInfo={contactInfo!} answers={answers} event={event} business={business} />
                    </Route>
                </Switch>
            ) : (
                <NotFoundPage />
            )}
        </>
    )
}
