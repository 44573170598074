import { FC, useState } from 'react'
import { BusinessPublicDto } from '../../../bookingpage/types/business'
import { MinupRibbon } from '../../../components/helpers/MinupRibbon'
import { BookingPageTitle } from '../components/BookingPageTitle'
import { Content } from '../components/Content'
import { Trans } from 'react-i18next'
import { LanguageCodes } from '../../../i18n'
import { Column } from '../../../components/admin/layout/Column'
import { TextButton } from '../../../components/ui-kit/button/TextButton'

export interface Slot {
    from: number
    to: number
}

const Address: FC<{ business: BusinessPublicDto }> = ({ business }) => {
    return (
        <>
            <Trans ns="admin" i18nKey={`country_${business.companyCountryCode}`} /> {business.companyCity}{' '}
            {business.companyAddress} {business.companyZipCode}
        </>
    )
}

const ShowButton: FC<{ encoded: string }> = ({ encoded }) => {
    const [shown, setShown] = useState(false)
    return encoded ? (
        shown ? (
            <>{atob(encoded)}</>
        ) : (
            <TextButton className="inline" type="button" onClick={() => setShown(true)}>
                <Trans ns="bookingpage">Show</Trans>
            </TextButton>
        )
    ) : null
}

export const PrivacyPolicyPage: FC<{
    business: BusinessPublicDto
    hideTitle?: boolean
    directServiceLink?: boolean
}> = ({ business, hideTitle, directServiceLink }) => {
    return (
        <Content spacing={0}>
            {!business.hideMinupBranding ? <MinupRibbon /> : null}
            <BookingPageTitle title={<Trans ns="bookingpage">Privacy policy</Trans>} />
            {business.bookingPageLanguage === LanguageCodes.Hu ? (
                <Column>
                    <p>Hozzájárulásomat adom ahhoz, hogy</p>
                    <p>
                        Cégnév: {business.companyName}
                        <br />
                        Székhely: <Address business={business} />
                        <br />
                        Cégjegyzékszám: {business.companyRegistrationNumber}
                        <br />
                        E-mail: <ShowButton encoded={business.companyEmail} />
                        <br />
                        Telefonszám: <ShowButton encoded={business.companyPhone} />
                    </p>

                    <p>
                        mint Adatkezelő az időpontfoglalásom során megadott személyes adataimat, továbbá jelen
                        foglalásom időpontját, IP címemet kezelje, tárolja, aktualizálja és használja abból a célból,
                        hogy az általa nyújtott szolgáltatások elérhető időpontjairól tájékoztatást kapjak, az általam
                        választott szolgáltatást és időpontot Adatkezelő a foglalási rendszerében rögzíthesse, így a
                        szolgáltatás nyújtására a kiválasztott időpontban készen álljon, engem pedig, mint az adott
                        időpont foglalóját a többi ügyfelétől megkülönböztethessen, beazonosíthasson, Adatkezelő a
                        kiválasztott szolgáltatással és időponttal kapcsolatos valamennyi információval ellásson,
                        amennyiben a jövőben ismételten igénybe venném Adatkezelő szolgáltatásait, tudja hogy mikor és
                        milyen korábbi szolgáltatást vettem igénybe és ennek megfelelően járjon el.
                    </p>

                    <p>
                        Jelen hozzájárulás adatkezelőt személyes adataim kezelésére csak az online időpontfoglaló
                        rendszer használatával kapcsolatosan jogosítja. Adatkezelő Adatvédelmi Tájékoztatójában
                        foglaltakat megismertem és elfogadom. Hozzájárulok, hogy foglalásaim adatait a legutolsó
                        foglalásom dátumától számítva legfeljebb 1 évig a foglalási rendszerében megőrizze.
                    </p>

                    <p>
                        Tisztában vagyok azzal, hogy jogom van az adatkezelőtől kérni a személyes adataimhoz történő
                        hozzáférést, azok helyesbítését, törlését, azok kezelésének korlátozását és tiltakozni az ilyen
                        adatok kezelése ellen. Jogom van a hozzájárulás visszavonására, ez azonban nem érinti a korábbi
                        hozzájárulás alapján a visszavonásig végrehajtott adatkezelés jogszerűségét. Lehetőségem van
                        jogaim megsértése esetén a Nemzeti Adatvédelmi és Információszabadság Hatóságnál (NAIH) panaszt
                        benyújtani.
                    </p>

                    <p>
                        Tudomással bírok arról, hogy az adatszolgáltatás előfeltétele a szolgáltatás igénybevételének.
                    </p>

                    <p>
                        Tudomásul veszem, hogy adatkezelő személyes adataim kezelése során a Minup Informatikai Zrt.
                        (Székhely: 1074 Budapest, Csengery utca 24. 3 em. 28., Cégjegyzékszám: 01-10-142970, Képviselő:
                        Marjai Ádám, Adatvédelmi kapcsolattartó: Dudás Áron) adatfeldolgozó közreműködését veszi
                        igénybe. A Minup Informatikai Zrt. honlapján (
                        <a href="https://minup.io/hu" target="_blank" rel="noreferrer">
                            https://minup.io
                        </a>
                        ) elérhető{' '}
                        <a href="https://minup.io/hu/minup-adatkezelesi-tajekoztato/" target="_blank" rel="noreferrer">
                            Adatvédelmi Tájékoztatóban
                        </a>{' '}
                        foglaltakat megismertem és elfogadom.
                    </p>
                    {business.paymentSettings?.active ? (
                        <>
                            <h2>Adattovábbítási nyilatkozat</h2>
                            <p>
                                Tudomásul veszem, hogy a(z) {business.companyName} (<Address business={business} />)
                                adatkezelő által a(z) https://app.minup.io/book/{business.urlHandle}/ felhasználói
                                adatbázisában tárolt alábbi személyes adataim átadásra kerülnek az OTP Mobil Kft., mint
                                adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbi: név,
                                email cím, számlázási cím. Az adatfeldolgozó által végzett adatfeldolgozási tevékenység
                                jellege és célja a SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető
                                meg:{' '}
                                <a
                                    href="https://simplepay.hu/adatkezelesi-tajekoztatok/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    https://simplepay.hu/adatkezelesi-tajekoztatok/
                                </a>
                            </p>
                        </>
                    ) : null}
                </Column>
            ) : (
                <Column>
                    <p>I consent to the following:</p>

                    <p>
                        Company name: {business.companyName}
                        <br />
                        Company address: <Address business={business} />
                        <br />
                        Company registration number: {business.companyRegistrationNumber}
                        <br />
                        Email: <ShowButton encoded={business.companyEmail} />
                        <br />
                        Phone Number: <ShowButton encoded={business.companyPhone} />
                    </p>

                    <p>
                        as the data controller processes, stores, updates, and uses my personal data provided during the
                        appointment booking process, including the date and time of my booking and my IP address, for
                        the following purposes: To provide information about available appointment times for the
                        services offered. To record my selected service and appointment time in the booking system so
                        that the service is prepared for the selected time and I, as the individual booking the
                        appointment, can be distinguished and identified from other clients. To provide all necessary
                        information related to the selected service and appointment. If I choose to use the services of
                        the data controller again in the future, to recall the date and details of my previous services
                        for appropriate follow-up.
                    </p>

                    <p>
                        This consent authorizes the data controller to process my personal data solely in connection
                        with the use of the online appointment booking system. I have read and accepted the data
                        controller's Privacy Policy. I consent to the retention of my booking data in the booking system
                        for up to 1 year from the date of my last booking.
                    </p>

                    <p>
                        I am aware that I have the right to request access to my personal data, rectification, deletion,
                        restriction of processing, or object to the processing of such data by the data controller. I
                        also have the right to withdraw my consent at any time; however, this does not affect the
                        legality of data processing carried out based on prior consent before its withdrawal. I am
                        entitled to file a complaint with the National Authority for Data Protection and Freedom of
                        Information (NAIH) if my rights are violated.
                    </p>

                    <p>I acknowledge that providing my data is a prerequisite for using the service.</p>
                    <p>
                        I acknowledge that the data controller utilizes the services of Minup Informatikai Zrt.
                        (Registered Office: 1074 Budapest, Csengery utca 24. 3rd floor, 28., Company Registration
                        Number: 01-10-142970, Representative: Ádám Marjai, Data Protection Contact: Áron Dudás) as a
                        data processor. I have read and accepted the{' '}
                        <a href="https://minup.io/en/privacy-policy/" target="_blank" rel="noreferrer">
                            Privacy Policy
                        </a>{' '}
                        available on Minup Informatikai Zrt.'s website (
                        <a href="https://minup.io/" target="_blank" rel="noreferrer">
                            https://minup.io
                        </a>
                        ).
                    </p>

                    {business.paymentSettings?.active ? (
                        <>
                            <h2>Data Transfer Statement</h2>

                            <p>
                                I acknowledge the following personal data stored in the user account of{' '}
                                {business.companyName} (
                                <Address business={business} />) in the user database of https://app.minup.io/book/
                                {business.urlHandle}/ will be handed over to OTP Mobil Ltd. and is trusted as data
                                processor. The data transferred by the data controller are the following: name, email
                                address, billing address. The nature and purpose of the data processing activity
                                performed by the data processor in the SimplePay Privacy Policy can be found at the
                                following link:{' '}
                                <a
                                    href="https://simplepay.hu/adatkezelesi-tajekoztatok/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    https://simplepay.hu/adatkezelesi-tajekoztatok/
                                </a>
                            </p>
                        </>
                    ) : null}
                </Column>
            )}
        </Content>
    )
}
