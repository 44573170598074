import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'

export const InputGroup = withTheme(
    styled.div(
        ({
            theme,
            inline,
            minWidth = 0,
            maxWidth,
            grow,
            forCheckbox,
            noWidth,
            gridArea,
        }: {
            theme: AdminTheme
            inline?: boolean
            minWidth?: number
            maxWidth?: number
            grow?: number
            forCheckbox?: boolean
            noWidth?: boolean
            gridArea?: string
        }) => ({
            display: 'flex',
            flexDirection: forCheckbox ? 'row' : 'column',
            gap: theme.Spacing(0.5),
            width: noWidth ? undefined : '100%',
            minWidth,
            maxWidth,
            gridArea,

            ...(forCheckbox
                ? {
                      alignItems: 'center',
                      gap: theme.Spacing(1),
                  }
                : undefined),

            label: {
                ...theme._BodySmallSemibold,
                color: theme.ContentPrimary,
            },

            'label.bodymedium': {
                ...theme._BodyMedium,
                color: theme.ContentPrimary,
            },

            [theme.BreakPoint]: {
                ...(inline ? { flex: '1 1 0', width: '0' } : undefined),
                ...(grow !== undefined ? { flexGrow: grow } : undefined),
            },
        })
    )
)
