import DayPicker from 'react-day-picker'
import styled from '@emotion/styled'
import moment, { Moment } from 'moment'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { UtcTimestamp } from '../../../components/helpers/UtcTimestamp'
import { LoadingScreen } from '../../../components/ui-kit/comopnents/LoadingScreen'
import { Slot } from './ServicePage'
import { SummaryCard } from './SummaryCard'
import { TimeSelectorContainer, TimeSlotBox } from './TimeSlotSelectorComponents'
import MomentLocaleUtils from 'react-day-picker/moment'
import { TimezoneWarning } from '../TimezoneWarning'
import { useClientTimezone } from '../../../hooks/useClientTimezone'
import { classNames } from '../../../services/class-names'
import { Footer } from '../components/Footer'
import { PrimaryButton } from '../../../components/ui-kit/button/primary'
import { MinupRibbon } from '../../../components/helpers/MinupRibbon'
import { BookingPageTitle } from '../components/BookingPageTitle'
import { Content } from '../components/Content'
import { ServicePublicDto } from '../../../bookingpage/types/service'
import { BusinessPublicDto } from '../../../bookingpage/types/business'

const CalendarContainer = styled.div(() => ({
    width: '100%',
    maxWidth: 335,
    position: 'relative',
}))

export const ServiceDates: FC<{
    skipForm: boolean
    hideTitle?: boolean
    directServiceLink?: boolean
    service: ServicePublicDto
    additionalServices: ServicePublicDto[]
    business: BusinessPublicDto
    day: Moment
    setDay: (day: Moment) => void
    selectedSlot: Slot
    selectSlot: (slot?: Slot) => void
    isEmbed?: boolean
    fromCategory?: boolean
}> = ({
    skipForm,
    hideTitle,
    directServiceLink,
    service,
    additionalServices,
    business,
    day,
    setDay,
    selectedSlot,
    selectSlot,
    isEmbed,
    fromCategory,
}) => {
    const [monthLoading, setMonthLoading] = useState(false)
    const [slots, setSlots] = useState([] as Slot[])
    const [allSlots, setAllSlots] = useState([] as Slot[])
    const { handle, serviceId } = useParams<{ handle: string; serviceId: string }>()
    const { clientTimezone } = useClientTimezone()
    const canScheduleUntil = useMemo(() => {
        return moment().add(service.canScheduleUntil, 'days')
    }, [service.canScheduleUntil])
    const [nothingIsAvailable, setNothingIsAvailable] = useState(false)

    const [disabledDays, setDisabledDays] = useState([] as Date[])

    const selectDay = useCallback(
        (day: Moment, newSlots?: Slot[]) => {
            if (day && service) {
                selectSlot(undefined)
                setDay(day)
                setSlots((newSlots || allSlots).filter((s) => moment.unix(s.from).isSame(day, 'day')))
            }
        },
        [allSlots, selectSlot, service, setDay]
    )

    const onSlotsLoaded = useCallback(
        (month: Moment, slots: Slot[]) => {
            const today = moment()
            const m = moment(month)
            const disabledDays = []
            let d = moment(m).date(1)
            while (d.isSame(m, 'month')) {
                if (d.isBefore(today, 'day')) {
                    disabledDays.push(d.toDate())
                } else if (!slots.some((s: any) => moment.unix(s.from).isSame(d, 'day'))) {
                    disabledDays.push(d.toDate())
                }
                d.add(1, 'day')
            }
            setAllSlots(slots)
            setDisabledDays(disabledDays)
            setMonthLoading(false)
            if (slots.length > 0) {
                selectDay(moment.unix(slots[0].from), slots)
            } else {
                selectDay(today.isSame(month, 'month') ? moment(today) : moment(month).date(1))
            }
        },
        [selectDay]
    )

    const getNextAvailable = useCallback(
        async (month: Moment, lookAhead: number): Promise<boolean> => {
            if (lookAhead === -1 || month.isAfter(canScheduleUntil) || !service.id) {
                return false
            }

            const query = new URLSearchParams()
            query.append('fromDate', moment(month).format('YYYY-MM-DD'))
            query.append('toDate', moment(month).endOf('month').format('YYYY-MM-DD'))
            query.append('serviceId', service.id.toString())
            for (const additionalService of additionalServices) {
                query.append('additionalServiceIds', additionalService.id!.toString())
            }
            const response = await fetch(`/api/book/${handle}/getslots?${query.toString()}`)
            const slots: Slot[] = await response.json()
            if (slots.length > 0) {
                onSlotsLoaded(month, slots)
                return true
            } else {
                return getNextAvailable(moment(month).add(1, 'month'), lookAhead - 1)
            }
        },
        [additionalServices, canScheduleUntil, handle, onSlotsLoaded, service.id]
    )

    const onMonthChange = useCallback(
        async (month: Moment, first: boolean = false) => {
            if (!service) {
                return false
            }
            selectSlot(undefined)
            setMonthLoading(true)
            const found = await getNextAvailable(month, first ? 6 : 0)
            if (!found) {
                setNothingIsAvailable(first)
                onSlotsLoaded(month, [])
            }
        },
        [getNextAvailable, onSlotsLoaded, selectSlot, service]
    )

    // init disabled days
    useEffect(() => {
        onMonthChange(moment(day).date(1), true)
        // Run only on component init
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const history = useHistory()

    const back = useCallback(() => {
        let category = ''
        if (fromCategory && isEmbed && service.categoryId) {
            category = `/category/${service.categoryId}`
        }
        history.push(`/${isEmbed ? 'embed' : 'book'}/${handle}${category}`)
    }, [fromCategory, handle, history, isEmbed, service.categoryId])

    const onContinue = useCallback(() => {
        if (skipForm) {
            history.push(`/${isEmbed ? 'embed' : 'book'}/${handle}/service/${serviceId}/checkout`)
        } else {
            history.push(`/${isEmbed ? 'embed' : 'book'}/${handle}/service/${serviceId}/form`)
        }
    }, [history, isEmbed, handle, serviceId, skipForm])

    return (
        <>
            <Content spacing={0}>
                {!business.hideMinupBranding ? <MinupRibbon /> : null}
                <BookingPageTitle
                    title={<Trans ns="bookingpage">Choose date and time</Trans>}
                    hideButton={directServiceLink}
                    hideTitle={hideTitle}
                    back={back}
                />
                <SummaryCard
                    className="mt"
                    service={service}
                    additionalServices={additionalServices}
                    day={day}
                    slot={selectedSlot}
                    business={business}
                />
                <TimezoneWarning business={business} className="mt mb" />
                <TimeSelectorContainer className="date-select mtm">
                    <CalendarContainer>
                        <DayPicker
                            canChangeMonth={!monthLoading}
                            disabledDays={disabledDays}
                            onMonthChange={(month) => onMonthChange(moment(month))}
                            locale={business.bookingPageLanguage}
                            selectedDays={day?.toDate()}
                            onDayClick={(day) => selectDay(moment(day))}
                            localeUtils={MomentLocaleUtils}
                            month={day.toDate()}
                        />
                        {monthLoading ? <LoadingScreen noBackground /> : null}
                    </CalendarContainer>
                    <div className="slots-container">
                        {day && (
                            <div className="slots">
                                <span className="slots-title">{day.format('dddd, MMMM D.')}</span>
                                <div className={slots && slots.length > 0 ? 'slot-list' : 'no-slot-list'}>
                                    {slots && slots.length ? (
                                        slots.map((slot, index) => {
                                            return (
                                                <TimeSlotBox
                                                    className={classNames(
                                                        slot === selectedSlot ? 'current-slot' : null
                                                    )}
                                                    key={index}
                                                    type="button"
                                                    onClick={() => {
                                                        if (slot !== selectedSlot) {
                                                            selectSlot(slot)
                                                        } else {
                                                            selectSlot(undefined)
                                                        }
                                                    }}
                                                >
                                                    <UtcTimestamp
                                                        tz={clientTimezone}
                                                        format="HH:mm"
                                                        timestamp={slot.from}
                                                    />
                                                </TimeSlotBox>
                                            )
                                        })
                                    ) : nothingIsAvailable ? (
                                        service.canScheduleUntil >= 180 ? (
                                            <p className="mts">
                                                <Trans ns="bookingpage">
                                                    There aren't any available time slots for the next 6 month.
                                                </Trans>
                                            </p>
                                        ) : (
                                            <p className="mts">
                                                <Trans ns="bookingpage">
                                                    There aren't any available time slots for the next{' '}
                                                    {{ canScheduleUntil: service.canScheduleUntil }} days.
                                                </Trans>
                                            </p>
                                        )
                                    ) : canScheduleUntil.isBefore(day) ? (
                                        <p className="mts">
                                            <Trans ns="bookingpage">
                                                You can’t book appointments this far ahead. Please come back later and
                                                try again.
                                            </Trans>
                                        </p>
                                    ) : (
                                        <p className="mts">
                                            <Trans ns="bookingpage">
                                                No times available. Please select an other date!
                                            </Trans>
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </TimeSelectorContainer>
            </Content>
            <Footer
                nextButton={
                    <PrimaryButton className="continue" disabled={selectedSlot === undefined} onClick={onContinue}>
                        <Trans ns="bookingpage">Continue</Trans>
                    </PrimaryButton>
                }
                hideBackButton={directServiceLink}
                back={back}
            />
        </>
    )
}
