import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ReactNode } from 'react'

export const EMPTY_PROGRESS: GettingStartedProgress = {
    activeTask: '',
    progress: 0,
    tasks: [],
    messageId: '',
}

export function createEmptyTaskProgress(taskId: string): TaskProgress {
    return {
        taskId,
        done: false,
        skipped: false,
        completedSubTasks: [],
    }
}

export const INITIAL_GETTING_STARTED_STATE: GettingStartedState = {
    messages: [],
}

export interface Guide {
    id: string
    title: string | ReactNode
    icon: IconProp
    tasks: Task[]
}

export interface SubTask {
    id: string
    title: string | ReactNode
}

export interface Task {
    id: string
    number: number
    title: string | ReactNode
    subTasks: SubTask[]
    note?: string | ReactNode
    percentage: number
    linkTo: string
    autocomplete?: boolean
    skipText?: ReactNode | string
}

export interface TaskProgress {
    taskId: string
    done: boolean
    skipped: boolean
    completedSubTasks: string[]
}

export interface GettingStartedProgress {
    activeTask: string
    progress: number
    tasks: TaskProgress[]
    messageId: string
}

export interface GettingStartedState {
    currentGuide?: Guide
    progress?: GettingStartedProgress
    messages: InAppMessage[]
    currentMessage?: InAppMessage
}

export interface InAppMessage {
    id: string
    businessId: number
    type: 'modal' | 'getting-started' | 'custom'
    messageId: string
    viewed: boolean
    viewedAt: string
    progress: string
}
