import { useDispatch, useSelector } from 'react-redux'
import { GettingStartedState, InAppMessage } from './types'
import { useCallback, useEffect } from 'react'
import { createAction, createAction2 } from '../utils/create-action'
import { GettingStartedActionTypes } from './actions'

export function useGettingStartedState() {
    return useSelector((state: { gettingStarted: GettingStartedState }) => state.gettingStarted)
}

export function useTaskProgress(taskId: string) {
    return useSelector(
        (state: { gettingStarted: GettingStartedState }) =>
            state.gettingStarted.progress?.tasks.find((task) => task.taskId === taskId) || {
                taskId,
                done: false,
                skipped: false,
                completedSubTasks: [],
            }
    )
}

export function useSetActiveStep() {
    const dispatch = useDispatch()
    return useCallback(
        (taskId: string) => {
            dispatch(createAction(GettingStartedActionTypes.SET_ACTIVE_TASK, taskId))
        },
        [dispatch]
    )
}

export function useSkipTask() {
    const dispatch = useDispatch()
    return useCallback(
        (taskId: string) => {
            dispatch(createAction(GettingStartedActionTypes.SKIP_TASK, taskId))
        },
        [dispatch]
    )
}

export function useFinishTask() {
    const dispatch = useDispatch()
    return useCallback(
        (taskId: string) => {
            dispatch(createAction(GettingStartedActionTypes.FINISH_TASK, taskId))
        },
        [dispatch]
    )
}

export function useFinishSubTask() {
    const dispatch = useDispatch()
    return useCallback(
        (taskId: string, subTaskIds: string[]) => {
            dispatch(createAction(GettingStartedActionTypes.FINISH_SUBTASK, { taskId, subTaskIds }))
        },
        [dispatch]
    )
}

export function useFinisSubtaskOnLoading(taskId: string, subTaskId: string) {
    const finishSubTask = useFinishSubTask()
    useEffect(() => {
        finishSubTask(taskId, [subTaskId])
    }, [finishSubTask, subTaskId, taskId])
}

export function useInAppMessages() {
    return useSelector((state: { gettingStarted: GettingStartedState }) => state.gettingStarted.messages)
}

export function useCurrentMessage() {
    return useSelector((state: { gettingStarted: GettingStartedState }) => state.gettingStarted.currentMessage)
}

export function useSetInAppMessages() {
    const dispatch = useDispatch()
    return useCallback(
        (messages: InAppMessage[]) => {
            dispatch(createAction2({ type: GettingStartedActionTypes.SET_MESSAGES, payload: messages }))
        },
        [dispatch]
    )
}

export function useSetInAppMessageViewed() {
    const dispatch = useDispatch()
    return useCallback(
        (messageId: string, sync: boolean) => {
            dispatch(
                createAction2({ type: GettingStartedActionTypes.SET_MESSAGE_VIEWED, payload: { messageId, sync } })
            )
        },
        [dispatch]
    )
}
