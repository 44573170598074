import { FC } from 'react'
import { Trans } from 'react-i18next'

export const CantCancelCancelPageText: FC<{ canCancelUntil: number }> = ({ canCancelUntil }) => {
    const time = canCancelUntil % 24 === 0 ? canCancelUntil / 24 : canCancelUntil
    const isDay = canCancelUntil % 24 === 0

    return (
        <>
            {isDay ? (
                <Trans ns="bookingpage" count={time}>
                    You can't cancel the appointment less than {{ canCancelUntil: time }} day before the start!
                </Trans>
            ) : (
                <Trans ns="bookingpage" count={time}>
                    You can't cancel the appointment less than {{ canCancelUntil: time }} hour before the start!
                </Trans>
            )}
        </>
    )
}
