import { faRocket } from '@fortawesome/free-solid-svg-icons'
import { Trans } from 'react-i18next'
import { GUIDE_IDS, FINISH_SETUP_TASK_IDS, FINISH_SETUP_SUB_TASK_IDS } from './constants'
import { NavLink } from 'react-router-dom'
import { useBusiness } from '../../../../hooks/use-business'
import { FC } from 'react'

const BookingPageLink: FC = ({ children }) => {
    const business = useBusiness()

    return <NavLink to={`/book/${encodeURIComponent(business?.urlHandle || '')}`}>{children}</NavLink>
}

export const FinishSetupGuide = () => ({
    id: GUIDE_IDS.FINISH_SETUP,
    icon: faRocket,
    title: <Trans ns="admin">Finish setup</Trans>,
    tasks: [
        {
            id: FINISH_SETUP_TASK_IDS.BOOK_AN_APPOINTMENT,
            number: 1,
            title: <Trans ns="admin">Book an appointment with yourself to see what your clients see</Trans>,
            subTasks: [
                {
                    id: FINISH_SETUP_SUB_TASK_IDS.GO_TO_BOOKING_PAGE,
                    title: (
                        <Trans ns="admin">
                            Open your <BookingPageLink>Booking page</BookingPageLink>
                        </Trans>
                    ),
                },
                {
                    id: FINISH_SETUP_SUB_TASK_IDS.SELECT_AND_BOOK_AN_APPOINTMENT,
                    title: <Trans ns="admin">Select a service and book an appointment.</Trans>,
                },
            ],
            note: <Trans ns="admin">Check the emails as well!</Trans>,
            percentage: 25,
            linkTo: 'bookingpage',
        },
        {
            id: FINISH_SETUP_TASK_IDS.VIEW_BOOKED_APPOINTMENT,
            number: 2,
            title: <Trans ns="admin">View your booked appointment</Trans>,
            subTasks: [
                {
                    id: FINISH_SETUP_SUB_TASK_IDS.GO_TO_YOUR_CALENDAR,
                    title: (
                        <Trans ns="admin">
                            Go to your <NavLink to="/admin/calendar">Calendar</NavLink>
                        </Trans>
                    ),
                },
                {
                    id: FINISH_SETUP_SUB_TASK_IDS.OPEN_BOOKED_APPOINTMENT,
                    title: <Trans ns="admin">Open the appointment you just booked.</Trans>,
                },
            ],
            percentage: 25,
            linkTo: '/admin/calendar',
        },
        {
            id: FINISH_SETUP_TASK_IDS.REVIEW_SCHEDULING_RULES,
            number: 3,
            title: <Trans ns="admin">Set your scheduling rules</Trans>,
            subTasks: [
                {
                    id: FINISH_SETUP_SUB_TASK_IDS.GO_TO_SCHEDULING_RULES,
                    title: (
                        <Trans ns="admin">
                            Go to Settings <NavLink to="/admin/booking/scheduling-rules">Scheduling rules</NavLink>
                        </Trans>
                    ),
                },
                {
                    id: FINISH_SETUP_SUB_TASK_IDS.DEFINE_SCHEDULING_RULES,
                    title: <Trans ns="admin">Define your rules for online scheduling</Trans>,
                },
            ],
            note: <Trans ns="admin">Save when you’re done!</Trans>,
            percentage: 25,
            linkTo: '/admin/booking/scheduling-rules',
        },
        {
            id: FINISH_SETUP_TASK_IDS.SHARE_YOUR_BOOKINGPAGE,
            number: 4,
            title: <Trans ns="admin">Share your booking page with clients</Trans>,
            subTasks: [
                {
                    id: FINISH_SETUP_SUB_TASK_IDS.GO_TO_BOOKING_PAGE_SHARE,
                    title: (
                        <Trans ns="admin">
                            Open your <BookingPageLink>Booking page</BookingPageLink>
                        </Trans>
                    ),
                },

                {
                    id: FINISH_SETUP_SUB_TASK_IDS.CLICK_ON_SHARE,
                    title: <Trans ns="admin">Click on the "Share" button</Trans>,
                },
            ],
            percentage: 25,
            linkTo: 'bookingpage',
        },
    ],
})
