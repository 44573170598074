import { forwardRef, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useModal } from '../../../hooks/use-modal'
import { Column } from '../../admin/layout/Column'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faGraduationCap, faImage, faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { Flex } from '../../helpers/Flex'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { withTheme } from '@emotion/react'
import { PrimaryButton } from '../button/primary'
import { Icon } from './Icon'
import { useToast } from '../../../hooks/use-toast'
import { copyToClipboard } from '../../../services/copy-to-clipboard'
import { getImageUrl } from '../../../services/get-image-url'
import { Business } from '../../../store/auth/types'
import { useDispatch } from 'react-redux'
import { createAction2 } from '../../../store/utils/create-action'
import { BookingPageActionTypes } from '../../../store/booking-page/actions'
import { NotificationDot } from './NotificationDot'
import { useShowHideNotificationIndicator } from '../../../hooks/admin/use-show-hide-notification-indicator'
import { useAdminState } from '../../../hooks/admin/use-admin-state'
import { NeutralIconButton } from '../button/NeutralIconButton'

const ShareButtonContainer = withTheme(
    styled(Column)(({ theme }: { theme: AdminTheme }) => ({
        width: 72,
        alignItems: 'center',
        gap: `${theme.Spacing(0.5)} !important`,
        color: theme.ContentSecondary,
    }))
)

const ImagePreview = withTheme(
    styled.img(({ theme }: { theme: AdminTheme }) => ({
        width: 600,
        aspectRatio: '1200/630',
        maxWidth: '100%',
        border: '1px solid',
        borderColor: theme.BorderPrimary,
        borderRadius: theme.Spacing(1),
    }))
)

const LinkContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        alignItems: 'center',
        borderRadius: theme.Spacing(1),
        padding: theme.Spacing(1),
        gap: theme.Spacing(2),
        backgroundColor: theme.BackgroundSecondary,
        width: 600,
        maxWidth: '100%',
        '.url': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            flex: '1 1 auto',
        },
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.BreakPoint]: {
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            '.url': {
                flex: '1 1 0',
            },
        },
    }))
)

const NotificationDotContainer = styled.div(() => ({
    lineHeight: '100%',
    position: 'absolute',
    top: -5,
    right: -5,
}))

export const ADMIN_BAR_SHARE_BUTTON_INDICATOR_ID = 'admin-bar-share-button'

interface ShareButtonProps {
    business: Business
    url: string
}

export const ShareButton = forwardRef<HTMLButtonElement, ShareButtonProps>(({ business, url }, ref) => {
    const modal = useModal()
    const toast = useToast()
    const { t } = useTranslation('admin')
    const copyLink = useCallback(
        (resolve: (result?: any) => void) => {
            copyToClipboard(url)
            resolve()
            toast.success(t('Link copied to clipboard!'), '', 3)
        },
        [url, toast, t]
    )
    const showHideNotificatonIndicator = useShowHideNotificationIndicator()
    const { showNotificatonIndicator } = useAdminState()

    const dispatch = useDispatch()
    const doShare = useCallback(() => {
        showHideNotificatonIndicator(ADMIN_BAR_SHARE_BUTTON_INDICATOR_ID, false)
        // const seo = await fetch(`/api/book/seo/${encodeURIComponent(handle)}`)
        dispatch(createAction2({ type: BookingPageActionTypes.SHARE }))
        if (typeof window.navigator.share === 'function') {
            window.navigator.share({ url })
        } else {
            modal.show({
                title: <Trans ns="admin">Share your booking page</Trans>,
                dismissable: true,
                render: (resolve) => (
                    <Column spacing={3}>
                        <ImagePreview
                            src={getImageUrl(
                                business.ogImagePublicId,
                                'social',
                                `/thumbnails/thumbnail-${business.languageCode}.png`
                            )}
                            alt=""
                        />
                        <LinkContainer>
                            <span className="url">{url}</span>
                            <PrimaryButton type="button" onClick={() => copyLink(resolve)}>
                                <Icon icon={faCopy} />
                                <Trans ns="admin">Copy link</Trans>
                            </PrimaryButton>
                        </LinkContainer>
                        <Flex justifyContent="flex-start" gap={1}>
                            <FontAwesomeIcon className="fa-w-20 primary" icon={faGraduationCap} />
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a
                                className="underlined"
                                target="_blank"
                                href="https://minup.io/hu/legjobb-helyek-az-idopontfoglalo-oldalad-megosztasara/"
                            >
                                <Trans ns="admin">Tips for sharing your booking page</Trans>
                            </a>
                        </Flex>
                        <Flex justifyContent="flex-start" gap={1}>
                            <FontAwesomeIcon className="fa-w-20 primary" icon={faImage} />
                            <a className="underlined" href="/admin/booking/appearance#thumbnail">
                                <Trans ns="admin">Change thumbnail</Trans>
                            </a>
                        </Flex>
                    </Column>
                ),
                hasFooter: false,
                containerStyles: {
                    width: 'auto',
                },
                forceDefaultTheme: true,
            })
        }
    }, [business.languageCode, business.ogImagePublicId, copyLink, dispatch, modal, showHideNotificatonIndicator, url])

    return (
        <ShareButtonContainer>
            <div className="relative">
                <NeutralIconButton
                    ref={ref}
                    aria-labelledby="share-button"
                    onClick={doShare}
                    className="outlined medium"
                    icon={faShareAlt}
                    type="button"
                />
                {showNotificatonIndicator?.includes(ADMIN_BAR_SHARE_BUTTON_INDICATOR_ID) && (
                    <NotificationDotContainer>
                        <NotificationDot />
                    </NotificationDotContainer>
                )}
            </div>
            <label id="share-button">
                <Trans ns="bookingpage">Share</Trans>
            </label>
        </ShareButtonContainer>
    )
})
