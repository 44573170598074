import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { Input } from '../../../../components/ui-kit/comopnents/Input'
import { InputGroup } from '../../../../components/ui-kit/comopnents/InputGroup'
import { RequiredIndicator } from '../../../../components/ui-kit/comopnents/RequiredIndicator'
import { AdminTheme } from '../../../../theme/theme'
import { CountryCodeSelector } from '../../../../components/ui-kit/comopnents/CountryCodeSelector'
import { InputWithAddon } from '../../../../components/ui-kit/comopnents/InputWithAddon'

export interface BillingDetails {
    id?: number
    billingName: string
    country: string
    city: string
    streetAddress: string
    zipCode: string
    taxID?: string
}

interface BillingDetailsFormProps {
    value: BillingDetails
    onChange: (value: BillingDetails) => void
}

const Container = withTheme(
    styled('div')(({ theme }: { theme: AdminTheme }) => ({
        display: 'grid',
        gridTemplateAreas: `
            'billingName'
            'country'
            'city'
            'streetAddress'
            'zipCode'
            'taxId'
        `,
        gap: theme.Spacing(2),
        rowGap: theme.Spacing(3),
        [theme.BreakPoint]: {
            gridTemplateColumns: '2fr 1fr 1fr 2fr',
            gridTemplateAreas: `
                'billingName billingName billingName billingName'
                'country country city city'
                'streetAddress streetAddress streetAddress zipCode'
                'taxId taxId taxId taxId'
            `,
        },
    }))
)

function getTaxIdPlaceHolder(countryCode: string) {
    switch (countryCode) {
        case 'HU':
            return '12355678-1-12'
        case 'RO':
            return '1234567890'
        case 'DE':
            return '123456789'
        case 'AT':
            return 'U12345678'
        case 'GR':
            return '123456789'
        case 'HR':
            return '12345678901'
        default:
            return '000000000'
    }
}

export const BillingDetailsForm: FC<BillingDetailsFormProps> = ({ value, onChange }) => {
    const onChangeCb = useCallback(
        (val: string, property: keyof BillingDetails) => {
            onChange({
                ...value,
                [property]: val,
            })
        },
        [onChange, value]
    )

    return (
        <Container>
            <InputGroup style={{ gridArea: 'billingName' }}>
                <label htmlFor="billingName">
                    <Trans ns="admin">Billing name</Trans>
                    <RequiredIndicator />
                </label>
                <Input
                    id="billingName"
                    value={value.billingName}
                    required
                    onChange={(e) => onChangeCb(e.currentTarget.value, 'billingName')}
                />
            </InputGroup>
            <InputGroup style={{ gridArea: 'country' }}>
                <label id="country-label">
                    <Trans ns="admin">Country</Trans>
                    <RequiredIndicator />
                </label>
                <CountryCodeSelector
                    id="country"
                    aria-labelledby="country-label"
                    value={value.country}
                    onChange={(value) => onChangeCb(value, 'country')}
                />
            </InputGroup>
            <InputGroup style={{ gridArea: 'city' }}>
                <label htmlFor="city">
                    <Trans ns="admin">City</Trans>
                    <RequiredIndicator />
                </label>
                <Input
                    id="city"
                    value={value.city}
                    required
                    onChange={(e) => onChangeCb(e.currentTarget.value, 'city')}
                />
            </InputGroup>
            <InputGroup style={{ gridArea: 'streetAddress' }}>
                <label htmlFor="streetAddress">
                    <Trans ns="admin">Street address</Trans>
                    <RequiredIndicator />
                </label>
                <Input
                    id="streetAddress"
                    value={value.streetAddress}
                    required
                    onChange={(e) => onChangeCb(e.currentTarget.value, 'streetAddress')}
                />
            </InputGroup>
            <InputGroup style={{ gridArea: 'zipCode' }}>
                <label htmlFor="zipCode">
                    <Trans ns="admin">Zip code</Trans>
                    <RequiredIndicator />
                </label>
                <Input
                    id="zipCode"
                    value={value.zipCode}
                    required
                    onChange={(e) => onChangeCb(e.currentTarget.value, 'zipCode')}
                />
            </InputGroup>
            <InputGroup style={{ gridArea: 'taxId' }}>
                <label htmlFor="taxId">
                    <Trans ns="admin">Tax ID</Trans>
                </label>
                <InputWithAddon
                    leftAddon={value.country || 'HU'}
                    id="taxId"
                    value={value.taxID}
                    onChange={(e) => onChangeCb(e.currentTarget.value, 'taxID')}
                    placeholder={getTaxIdPlaceHolder(value.country)}
                />
                <span className="small subtle">
                    <Trans ns="admin">Required for businesses</Trans>
                </span>
            </InputGroup>
        </Container>
    )
}
