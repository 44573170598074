import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FieldProps } from 'formik'
import React, { FunctionComponent, HtmlHTMLAttributes } from 'react'
import { fromTheme } from '../../../theme/theme'

export const Textarea = withTheme(styled.textarea`
    border-radius: 8px;
    border: 1px solid ${fromTheme('BorderSecondary')};
    margin: 4px 0px;
    padding: 8px 12px;

    width: auto !important;

    ${fromTheme('DefaultFontFamily')};
    ${fromTheme('BodyMedium')}

    color: ${fromTheme('ContentPrimary')};
    background-color: ${fromTheme('SurfaceColor')};

    flex-grow: 1;
    flex-shrink: 1;

    min-width: 0;

    &:focus-visible {
        border: 1px solid ${fromTheme('ThemeColor')};
        outline: none;
        box-shadow: 0 0 0 4px ${fromTheme('FocusOutline')};
    }

    &:disabled {
        border: 1px solid ${fromTheme('Neutral_500')};
        background: ${fromTheme('Neutral_0')};
    }

    ::placeholder {
        color: ${fromTheme('Neutral_500')};
    }
`)

export const FormikTextarea: FunctionComponent<FieldProps & HtmlHTMLAttributes<HTMLTextAreaElement>> = ({
    field,
    form,
    ...props
}) => <Textarea {...field} {...props} />
