import { FC, ReactNode, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AdminMain } from '../../../../components/admin/layout/AdminMain'
import { AdminPageContent } from '../../../../components/admin/layout/AdminPageContent'
import { AdminPageHeader } from '../../../../components/admin/layout/AdminPageHeader'
import { CardContainer } from '../../../../components/ui-kit/card/CardContainer'
import { Column } from '../../../../components/admin/layout/Column'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../../theme/theme'
import { NavLink } from 'react-router-dom'
import { useSubscriptionInfo } from '../../../../hooks/use-subscription-info'
import GroupEventsIllustration from '../../../../icons/pro-features/group-event.webp'
import TwoWaySyncIllustration from '../../../../icons/pro-features/2way-sync.webp'
import CrmIllustration from '../../../../icons/pro-features/crm.webp'
import CustomTimeIllustration from '../../../../icons/pro-features/custom-time.webp'
import EmailTemplatesIllustration from '../../../../icons/pro-features/email_templates.webp'
import FormsIllustration from '../../../../icons/pro-features/forms.webp'
import DateOverridesIllustration from '../../../../icons/pro-features/date_overrides.webp'
import { UpgradeButton } from '../../../../components/admin/subscription/UpgradeButton'

export type ProFeatureId =
    | 'two-way-sync'
    | 'group-events'
    | 'email-templates'
    | 'more-email-reminders'
    | 'forms'
    | 'crm'
    | 'blocklist'
    | 'custom-working-hours'
    | 'custom-location'
    | 'private-services'
    | 'custom-time'
    | 'even-odd-weeks'
    | 'custom-time'
    | 'date-overrides'
    | 'custom-theme'
    | 'hide-minup-logo'
    | 'invoicing'
    | 'custom-scheduling-rules'
    | 'online-payment'

export type ProFeature = {
    id: ProFeatureId
    illustration?: string
    title: ReactNode
    description?: ReactNode
    link?: ReactNode
    cancellationWarning: ReactNode
}

export function useProFeatures(): ProFeature[] {
    const { t } = useTranslation('admin')
    return useMemo<ProFeature[]>(
        () => [
            {
                id: 'two-way-sync',
                illustration: TwoWaySyncIllustration,
                title: t('Two-way Google Calendar sync'),
                description: (
                    <Trans ns="admin">
                        Connect your Google Calendar with Minup, so your events can sync back-and-forth automatically.
                    </Trans>
                ),
                link: (
                    <NavLink to={'/admin/integration'}>
                        <Trans ns="admin">Connect your Google Calendar </Trans>
                    </NavLink>
                ),
                cancellationWarning: <Trans ns="admin">Two-way Google calendar sync will be disconnected.</Trans>,
            },
            {
                id: 'group-events',
                illustration: GroupEventsIllustration,
                title: t('Group services'),
                description: (
                    <Trans ns="admin">Create services that can be booked by multiple people at the same time.</Trans>
                ),
                link: (
                    <NavLink to={'/admin/booking/events'}>
                        <Trans ns="admin">Create a group service</Trans>
                    </NavLink>
                ),
                cancellationWarning: <Trans ns="admin">Your group services will not be available for booking.</Trans>,
            },
            {
                id: 'email-templates',
                illustration: EmailTemplatesIllustration,
                title: t('Custom email templates'),
                description: (
                    <Trans ns="admin">Make your emails more personal! Add custom text to your outgoing emails.</Trans>
                ),
                link: (
                    <NavLink to={'/admin/email-templates'}>
                        <Trans ns="admin">Customize your automated emails</Trans>
                    </NavLink>
                ),
                cancellationWarning: (
                    <Trans ns="admin">
                        Instead of your custom email templates, your clients will receive the default emails.
                    </Trans>
                ),
            },
            {
                id: 'more-email-reminders',
                title: t('Extra email reminder'),
                cancellationWarning: <Trans ns="admin">Second email reminders won’t be available.</Trans>,
            },
            {
                id: 'forms',
                illustration: FormsIllustration,
                title: t('Forms'),
                description: (
                    <Trans ns="admin">Ask your clients extra questions before they book appointments with you.</Trans>
                ),
                link: (
                    <NavLink to={'/admin/forms'}>
                        <Trans ns="admin">Create a form</Trans>
                    </NavLink>
                ),
                cancellationWarning: <Trans ns="admin">Your forms will not be visible on your booking page.</Trans>,
            },
            {
                id: 'crm',
                illustration: CrmIllustration,
                title: t('Client management'),
                description: (
                    <Trans ns="admin">
                        Your clients receive their own profile page where you can see their data and note down the most
                        important things.
                    </Trans>
                ),
                link: (
                    <NavLink to={'/admin/crm'}>
                        <Trans ns="admin">View your clients</Trans>
                    </NavLink>
                ),
                cancellationWarning: <Trans ns="admin">Your client list will not be accessible.</Trans>,
            },
            {
                id: 'blocklist',
                title: t('Blocklist'),
                cancellationWarning: <Trans ns="admin">Blocked clients will be able to book appointments again.</Trans>,
            },
            {
                id: 'custom-working-hours',
                illustration: CustomTimeIllustration,
                title: t('Custom working hours'),
                description: (
                    <Trans ns="admin">No matter how hectic your work schedule, you can set it all up here.</Trans>
                ),
                link: (
                    <NavLink to={'/admin/booking/services'}>
                        <Trans ns="admin">Set custom date and time for your services</Trans>
                    </NavLink>
                ),
                cancellationWarning: (
                    <Trans ns="admin">
                        Services with custom working hours and specific dates and times will be disabled.
                    </Trans>
                ),
            },
            {
                id: 'custom-time',
                title: t('Custom time increments'),
                cancellationWarning: (
                    <Trans ns="admin">
                        Time slots on your booking page will appear in default time increments instead of custom time
                        increments.
                    </Trans>
                ),
            },
            {
                id: 'custom-location',
                title: t('Custom location'),
                cancellationWarning: <Trans ns="admin">Services with custom location will be disabled.</Trans>,
            },
            {
                id: 'private-services',
                title: t('Private services'),
                cancellationWarning: <Trans ns="admin">Private services will be disabled.</Trans>,
            },
            {
                id: 'even-odd-weeks',
                title: t('Even/odd weeks'),
                cancellationWarning: <Trans ns="admin">Even and odd weeks will be disabled</Trans>,
            },
            {
                id: 'date-overrides',
                illustration: DateOverridesIllustration,
                title: t('Date overrides'),
                description: <Trans ns="admin">You can override your default working hours on specific dates.</Trans>,
                link: (
                    <NavLink to={'/admin/booking/working-hours'}>
                        <Trans ns="admin">Override your working hours</Trans>
                    </NavLink>
                ),
                cancellationWarning: <Trans ns="admin">Your date overrides will not be available.</Trans>,
            },
            {
                id: 'custom-theme',
                title: t('Custom theme'),
                cancellationWarning: <Trans ns="admin">You won't be able to use your custom theme.</Trans>,
            },
            {
                id: 'hide-minup-logo',
                title: t('Hide Minup logo'),
                cancellationWarning: <Trans ns="admin">Minup logo will be visible on your booking page.</Trans>,
            },
            {
                id: 'invoicing',
                title: t('Invoicing'),
                cancellationWarning: <Trans ns="admin">You will not be able to issue invoices.</Trans>,
            },
            {
                id: 'custom-scheduling-rules',
                title: t('Custom scheduling rules'),
                cancellationWarning: <Trans ns="admin">Services with custom scheduling rules will be disabled.</Trans>,
            },
            {
                id: 'online-payment',
                title: t('Online payment'),
                cancellationWarning: (
                    <Trans ns="admin">Your clients will not be able to pay online when booking.</Trans>
                ),
            },
        ],
        [t]
    )
}

const ProFeatureListItemContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        gap: theme.Spacing(3),
        alignItems: 'center',
        justifyContent: 'flex-start',
        img: {
            borderRadius: theme.Spacing(1),
        },
    }))
)

const ProFeatureListItem: FC<{ feature: ProFeature; showLink: boolean }> = ({ feature, showLink }) => {
    return (
        <ProFeatureListItemContainer>
            <img src={feature.illustration} alt="" width={143} height={143} />
            <Column spacing={2}>
                <Column spacing={1}>
                    <h4>{feature.title}</h4>
                    <p className="small">{feature.description}</p>
                </Column>
                {showLink && feature.link}
            </Column>
        </ProFeatureListItemContainer>
    )
}

const ProFeatureGrid = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'grid',
        margin: theme.Spacing(4),
        gap: theme.Spacing(4),
        gridTemplateColumns: '1fr',
        '@media (min-width: 1200px)': {
            gridTemplateColumns: '1fr 1fr',
        },
    }))
)

export const ProFeaturesList: FC = () => {
    const { subscriptionTier } = useSubscriptionInfo()
    const features = useProFeatures()

    return (
        <AdminMain className="admin-main">
            <AdminPageHeader
                title={<Trans ns="admin">Pro features</Trans>}
                backLink="/admin/subscription"
                extra={
                    subscriptionTier === 'free' ? (
                        <UpgradeButton trialtext={<Trans ns="admin">Start Free Trial</Trans>} straigthToCheckout />
                    ) : null
                }
                wrap
            />
            <AdminPageContent style={{ paddingTop: 0 }}>
                <CardContainer>
                    <ProFeatureGrid>
                        {features
                            .filter((feature) => feature.illustration)
                            .map((feature, idx) => (
                                <ProFeatureListItem
                                    key={idx}
                                    feature={feature}
                                    showLink={subscriptionTier !== 'free'}
                                />
                            ))}
                    </ProFeatureGrid>
                </CardContainer>
            </AdminPageContent>
        </AdminMain>
    )
}
