import { forwardRef, ReactNode } from 'react'
import { Flex } from '../../../components/helpers/Flex'
import { NeutralIconButton } from '../../../components/ui-kit/button/NeutralIconButton'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

export const BookingPageTitle = forwardRef<
    HTMLElement,
    {
        hideButton?: boolean
        hideTitle?: boolean
        title: ReactNode
        back?: () => void
    }
>(({ hideButton, hideTitle, title, back }, ref) => {
    const { t } = useTranslation('bookingpage')
    return (
        <Flex justifyContent="center" className="relative" ref={ref as any}>
            {!hideButton ? (
                <NeutralIconButton
                    className="only-booking-mobile"
                    aria-label={t('Back')}
                    onClick={back ? back : () => window.history.back()}
                    icon={faArrowLeft}
                    style={{ position: 'absolute', left: 0 }}
                />
            ) : null}
            {!hideTitle && !hideButton ? <h2>{title}</h2> : null}
        </Flex>
    )
})
