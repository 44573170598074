import { Moment } from 'moment-timezone'
import { FC, useCallback, useEffect } from 'react'
import { ContactInfo, ContactInfoScreen } from '../components/ContactInfoScreen'
import { SummaryCard } from './SummaryCard'
import { useHistory, useParams } from 'react-router-dom'
import { Slot } from './ServicePage'
import { useBeforeUnload } from '../../../hooks/use-before-unload'
import { BusinessPublicDto } from '../../../bookingpage/types/business'
import { ServicePublicDto } from '../../../bookingpage/types/service'

export const ServiceContactInfo: FC<{
    service: ServicePublicDto
    additionalServices: ServicePublicDto[]
    contactInfo: ContactInfo
    setContactInfo: (value: ContactInfo) => void
    day: Moment
    selectedSlot: Slot
    business: BusinessPublicDto
    isEmbed?: boolean
}> = ({ contactInfo, setContactInfo, service, additionalServices, day, selectedSlot, business, isEmbed }) => {
    const { handle, serviceId } = useParams<{ handle: string; serviceId: string }>()
    const history = useHistory()
    useBeforeUnload()

    useEffect(() => {
        if (!selectedSlot) {
            history.push(`/${isEmbed ? 'embed' : 'book'}/${handle}/service/${serviceId}`)
        }
    }, [handle, history, isEmbed, selectedSlot, serviceId])

    const onSubmit = useCallback(
        async (clientInfo) => {
            setContactInfo(clientInfo)
            history.push(`/${isEmbed ? 'embed' : 'book'}/${handle}/service/${serviceId}/overview`)
        },
        [handle, history, isEmbed, serviceId, setContactInfo]
    )

    return (
        <ContactInfoScreen
            onSubmit={onSubmit}
            phoneRequired={service.phoneRequired}
            contactInfo={contactInfo}
            business={business}
            summaryCard={
                <SummaryCard
                    service={service}
                    additionalServices={additionalServices}
                    day={day}
                    slot={selectedSlot}
                    business={business}
                />
            }
            maxSeatsPerBooking={1}
        />
    )
}
