import { Reducer } from 'react'
import { NotificationActionTypes, NotificationsAction } from './actions'
import { NotificationsState } from './types'

import { v4 as uuid } from 'uuid'

const INITIAL_NOTIFICATIONS_STATE: NotificationsState = {
    notifications: [],
    removeIds: [],
}

export const notificationsReducer: Reducer<NotificationsState, NotificationsAction> = (state, action) => {
    let nextState = state ? state : INITIAL_NOTIFICATIONS_STATE

    switch (action.type) {
        case NotificationActionTypes.CREATE_NOTIFICATION: {
            let new_id = action.payload.id
            let notifications = nextState.notifications
            let removeIds = nextState.removeIds
            if (new_id && removeIds.includes(new_id)) {
                removeIds = removeIds.filter((id) => id !== new_id)
            } else {
                new_id = new_id || uuid()
                notifications = [
                    ...notifications.filter((notification) => notification.id !== new_id),
                    {
                        ...action.payload,
                        id: new_id,
                    },
                ]
            }
            return {
                ...nextState,
                notifications,
                removeIds,
            }
        }
        case NotificationActionTypes.REMOVE_NOTIFICATION: {
            const id = action.payload
            let notifications = nextState.notifications
            let removeIds = nextState.removeIds
            if (notifications.some((n) => n.id === id)) {
                notifications = notifications.filter((notification) => notification.id !== id)
            } else {
                removeIds.push(id)
            }
            return {
                ...nextState,
                notifications,
                removeIds,
            }
        }
        case NotificationActionTypes.REMOVE_ALL_NOTIFICATION: {
            const prefix = action.payload
            let notifications = nextState.notifications.filter((notification) => !notification.id.startsWith(prefix))
            return {
                ...nextState,
                notifications,
            }
        }
        default:
            return nextState
    }
}
