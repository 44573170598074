import { useCallback } from 'react'
import { getAndRemoveRedirectUri, getLockInstance } from '../../services/lock'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AuthActionTypes } from '../../store/auth/actions'
import { createAction } from '../../store/utils/create-action'

export function useCheckAccessToken() {
    const dispatch = useDispatch()
    const history = useHistory()

    return useCallback(
        async (authResult: AuthResult, register?: boolean) => {
            let redirectUri = undefined
            // Use the token in authResult to getUserInfo() and save it if necessary
            const lock = await getLockInstance()
            lock.getUserInfo(authResult.accessToken, function (error) {
                if (error) {
                    dispatch(createAction(AuthActionTypes.SET_LOADING, false))
                    dispatch(createAction(AuthActionTypes.SET_ACCESS_TOKEN, undefined))
                    return
                }

                dispatch(createAction(AuthActionTypes.SET_ACCESS_TOKEN, authResult))
                dispatch(createAction(AuthActionTypes.SET_LOADING, false))
                if (!register) {
                    dispatch(createAction(AuthActionTypes.AUTH_FINISHED, undefined))
                }

                if (register) {
                    history.push('/admin')
                } else if ((redirectUri = getAndRemoveRedirectUri())) {
                    history.push(redirectUri)
                }
            })
        },
        [dispatch, history]
    )
}
