export type SubscriptionTier = 'free' | 'pro-trial' | 'pro'
export type BillingCycle = 'monthly' | 'annual'

export enum MigrationState {
    None = 0,
    Waiting = 1,
    Running = 2,
    InProgress = 3,
    Done = 10,
    Failed = 20,
}

export enum LocationType {
    Default = 0,
    InPerson = 1,
    Online = 2,
    Hybrid = 3,
}

export enum ButtonStyle {
    Default = 0,
    Square = 1,
    Round = 2,
}

export enum TimezoneMode {
    Local = 0,
    Automatic = 1,
}

export enum PaymentMethod {
    None = 0,
    SimplePay = 1,
    OnSite = 10,
}

export enum ServiceColor {
    // Custom theme not available yet
    // Custom = 0,
    Color1 = 1,
    Color2 = 2,
    Color3 = 3,
    Color4 = 4,
    Color5 = 5,
    Color6 = 6,
}

export enum GroupServicesDisplay {
    Card = 0,
    Timetable = 1,
}
