import { Event } from '../events/types'
import { Action, ActionWithoutPayload } from '../utils/create-action'
import { CalendarEntry } from './types'

export enum CalendarActionTypes {
    PATCH = '[calendar] patch',
    SET_ENTRIES = '[calendar] set entries',
    SET_EVENTS = '[calendar] set events',
    CREATE = '[calendar] create',
    UPDATE = '[calendar] update',
    DELETE = '[calendar] delete',
    SET_IS_LOADING = '[calendar] set is loading',
    SET_IS_LOADING_EVENTS = '[calendar] set is loading events',
    ENTRY_OPENED = '[calendar] entry opened',
}

export type PatchCalendarEntryAction = Action<
    typeof CalendarActionTypes.PATCH,
    { id: string | number; patch: Partial<CalendarEntry> }
>
type SetCalendarEntriesAction = Action<typeof CalendarActionTypes.SET_ENTRIES, CalendarEntry[]>
type SetCalendarEventsAction = Action<typeof CalendarActionTypes.SET_EVENTS, Event[]>
type CreateCalendarEntryAction = Action<typeof CalendarActionTypes.CREATE, CalendarEntry | CalendarEntry[]>
type UpdateCalendarEntryAction = Action<typeof CalendarActionTypes.UPDATE, CalendarEntry>
type DeleteCalendarEntryAction = Action<typeof CalendarActionTypes.DELETE, CalendarEntry['objectId']>
type SetIsLoadingAction = Action<typeof CalendarActionTypes.SET_IS_LOADING, boolean>
type SetIsLoadingEventsAction = Action<typeof CalendarActionTypes.SET_IS_LOADING_EVENTS, boolean>
type CalendarEntryOpenedAction = ActionWithoutPayload<typeof CalendarActionTypes.ENTRY_OPENED>

export type CalendarAction =
    | PatchCalendarEntryAction
    | SetCalendarEventsAction
    | CreateCalendarEntryAction
    | UpdateCalendarEntryAction
    | DeleteCalendarEntryAction
    | SetCalendarEntriesAction
    | SetIsLoadingAction
    | SetIsLoadingEventsAction
    | CalendarEntryOpenedAction
