import { Moment } from 'moment'
import { AppointmentStatus, Appointment, EventType } from '../../../store/appointments/types'
import { CalendarEntry } from '../../../store/calendar/types'
import { createContext } from 'react'

export interface DecoratedCalendarEntry extends CalendarEntry {
    tooltip: string
    start: number
    length: number
    fromDateTime: Moment
    toDateTime: Moment
}

export interface DecoratedCalendarEntryWithLayout extends DecoratedCalendarEntry {
    width: number
    left: number
}

export const hours = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
]

export const newAppointment: Appointment = {
    type: EventType.Appointment,
    title: '',
    from: '',
    to: '',
    clientEmail: '',
    clientName: '',
    clientPhone: '',
    serviceName: '',
    note: '',
    noteToSelf: '',
    status: AppointmentStatus.Approved,
    breakAfter: 0,
    source: '',
    allDay: false,
    free: false,
    location: '',
    description: '',
    priceText: '',
    inSeries: false,
    services: [],
    invoiceRequested: false,
    billingName: '',
    billingCountry: '',
    billingCountryCode: '',
    billingCity: '',
    billingZipCode: '',
    billingAddress: '',
    taxID: '',
    isBusiness: false,
    repeatRules: null,
    transactions: null,
    releaseAfter: null,
}

export const DEFAULT_HOUR_HEIGHT = 54.0

const HOUR_HEIGHT_KEY = 'hourHeight'

export function getStoredHourHeight() {
    return parseFloat(localStorage.getItem(HOUR_HEIGHT_KEY) || `${DEFAULT_HOUR_HEIGHT}`)
}

export function setStoredHourHeight(height: number) {
    localStorage.setItem(HOUR_HEIGHT_KEY, height.toString())
}

export const CalendarZoomContext = createContext({
    hourHeight: DEFAULT_HOUR_HEIGHT,
    setHourHeight: (height: number) => {},
})
