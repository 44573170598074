import { ComponentProps, FC } from 'react'
import { ReactComponent as Cover } from '../../../icons/EventCover.svg'
import { getImageUrl } from '../../../services/get-image-url'

export const EventCoverImage: FC<{ imagePublicId?: string } & ComponentProps<typeof Cover>> = ({
    imagePublicId,
    id,
    width,
    style,
    ...rest
}) => {
    return imagePublicId && !imagePublicId.startsWith('icon::') && !imagePublicId.startsWith('emoji::') ? (
        <img
            id={id}
            width={width}
            role="presentation"
            src={
                imagePublicId.startsWith('https://') || imagePublicId.startsWith('data:')
                    ? imagePublicId
                    : getImageUrl(imagePublicId, 'bookingpageeventcoverdesktop')
            }
            alt=""
            style={style}
        />
    ) : (
        <Cover id={id} width={width} style={style} {...rest} />
    )
}
