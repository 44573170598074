import { Action } from '../utils/create-action'
import { Notification } from './types'

export enum NotificationActionTypes {
    CREATE_NOTIFICATION = '[notifications] create',
    REMOVE_NOTIFICATION = '[notifications] remove',
    REMOVE_ALL_NOTIFICATION = '[notifications] remove all',
}

type CreateNotificationAction = Action<
    typeof NotificationActionTypes.CREATE_NOTIFICATION,
    Omit<Notification, 'id'> & Notification
>
type RemoveNotificationAction = Action<typeof NotificationActionTypes.REMOVE_NOTIFICATION, string>
type RemoveAllNotificationAction = Action<typeof NotificationActionTypes.REMOVE_ALL_NOTIFICATION, string>

export type NotificationsAction = CreateNotificationAction | RemoveNotificationAction | RemoveAllNotificationAction
