import styled from '@emotion/styled'
import { withTheme } from '@emotion/react'
import { fromTheme } from '../../../theme/theme'

export const NavItem = withTheme(styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    jsutify-content: center;

    a,
    .nav-item {
        white-space: nowrap;
        display: inline-block;

        position: relative;
        text-decoration: none;

        &,
        &:link,
        &:visited {
            color: ${fromTheme('Neutral_700')};
        }

        ${fromTheme('BodyMediumSemibold')}

        padding: 0 0 8px 0;

        &.active,
        &:hover {
            color: ${fromTheme('Primary_700')};
        }

        &.active {
            ::before {
                content: ' ';
                display: inline-block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 5px;
                margin-bottom: -6px;
                border-top: 4px solid #3772ff;
                border-radius: 4px;
            }
        }
    }
    button.nav-item {
        cursor: pointer;
        border: none;
        background: none;
        font-weight: 500;
    }
`)
