import styled from '@emotion/styled'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import React, {
    ComponentType,
    createContext,
    CSSProperties,
    FC,
    ReactNode,
    Ref,
    useCallback,
    useRef,
    useState,
} from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useOnClickOutside } from '../../../hooks/use-on-click-outside'
import { NeutralButton } from '../button/neutral'
import { NeutralIconButton } from '../button/NeutralIconButton'
import { PrimaryButton } from '../button/primary'
import { Flex } from '../../helpers/Flex'
import { keyframes, withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'
import { ThemeProviderContainer } from '../../../theme/ThemeProvider'

const PopupBackdrop = withTheme(
    styled.div(
        ({ theme }: { theme: AdminTheme }) => `
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(37, 40, 45, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    ${theme.BreakPoint} {
        padding: 24px;
    }
`
    )
)

const AppearAnimation = keyframes`
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
`

const PopupContainer = withTheme(
    styled.div(({ theme, width }: { theme: AdminTheme; width: number | string }) => ({
        position: 'relative',
        width: width,
        maxWidth: '100%',
        maxHeight: '100%',
        margin: theme.Spacing(2),
        boxShadow: theme.Elevation_300,
        background: 'white',
        borderRadius: theme.Spacing(1.5),
        display: 'flex',
        overflowY: 'auto',
        overflowX: 'hidden',
        flexDirection: 'column',
        outline: 'none',
        boxSizing: 'border-box',
        animationName: AppearAnimation,
        animationDuration: '0.3s',
        animationFillMode: 'forwards',
    }))
)

interface IConfirmationPopupContext {
    show: <T = boolean>(props: ConfirmationPopupOptions) => Promise<T | boolean>
    dismiss: () => void
}

export const ConfirmationPopupContext = createContext<IConfirmationPopupContext>({
    show: () => Promise.reject(),
    dismiss: () => {},
})

export type ConfirmationPopupForm = ComponentType<{ formRef: Ref<HTMLFormElement | undefined> }>

export interface ConfirmationOption {
    value: any
    text: string | ReactNode
}

interface CommonProps {
    title: string | ReactNode
    text?: string | ReactNode
    render?: (resolve: (value: any) => void, reject: (reason: any) => void) => ReactNode
    header?: ReactNode
    dismissable?: boolean
    hideDismissButton?: boolean
    containerStyles?: CSSProperties
    options?: ConfirmationOption[]
    FormComponent?: ConfirmationPopupForm
    forceDefaultTheme?: boolean
    width?: number | string
}

interface ConfirmationOptions extends CommonProps {
    hasFooter?: true
    primaryButtonComponent?: FC<any>
    primaryButtonText: string | ReactNode
    cancelButtonText?: string | ReactNode
    primaryButtonClass?: string
    primaryButtonDisabled?: boolean
    cancelButtonComponent?: FC<any>
    cancelButtonClass?: string
    cancelButtonStyle?: CSSProperties
    wrapFooter?: boolean
}

interface ModalOptions extends CommonProps {
    hasFooter: false
    primaryButtonComponent?: undefined
    primaryButtonText?: undefined
    cancelButtonText?: undefined
    primaryButtonClass?: undefined
    primaryButtonDisabled?: undefined
    cancelButtonComponent?: undefined
    cancelButtonClass?: undefined
    cancelButtonStyle?: undefined
    wrapFooter?: undefined
}

interface ConfirmationPromiseProps {
    resolve: (selectedOption: any) => any
    reject: () => any
}

export type ConfirmationPopupOptions = ConfirmationOptions | ModalOptions
export type ConfirmationPopupProps = ConfirmationPromiseProps & ConfirmationPopupOptions

const HeaderContainer = withTheme(
    styled.h2(({ theme, withCloseButton }: { theme: AdminTheme; withCloseButton?: boolean }) => ({
        padding: `${theme.Spacing(3)} ${theme.Spacing(withCloseButton ? 7 : 3)} ${theme.Spacing(3)} ${theme.Spacing(
            3
        )}`,
    }))
)

const ContentBlock = styled.div`
    padding: 24px;
    padding-top: 0;
`

const FooterContainer = withTheme(
    styled.div(({ theme, wrap }: { theme: AdminTheme; wrap?: boolean }) => ({
        padding: theme.Spacing(3),
        paddingTop: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexWrap: wrap ? 'wrap' : undefined,
        gap: theme.Spacing(1),
        ...(wrap
            ? {
                  button: {
                      width: '100%',
                      flexGrow: 1,
                  },
                  flexDirection: 'column-reverse',
                  [theme.BreakPoint]: {
                      button: {
                          width: 'auto',
                      },
                      flexDirection: 'row',
                  },
              }
            : undefined),
    }))
)

const DismissButtonContainer = styled.div`
    position: absolute;
    top: 24px;
    right: 24px;
`

export const ConfirmationPopup: FC<ConfirmationPopupProps> = ({
    title,
    text,
    render,
    primaryButtonComponent,
    primaryButtonText,
    cancelButtonText,
    primaryButtonClass,
    primaryButtonDisabled,
    cancelButtonComponent,
    cancelButtonClass,
    cancelButtonStyle,
    header,
    dismissable,
    hideDismissButton,
    containerStyles,
    options,
    FormComponent,
    hasFooter,
    width,
    forceDefaultTheme,
    wrapFooter,
    resolve,
    reject,
}) => {
    const popupRef = useRef<HTMLDivElement>(null)
    const { t } = useTranslation('admin')
    const [selectedOption, setSelectedOption] = useState(options?.length ? options[0].value : undefined)

    useEffect(() => {
        if (popupRef.current) {
            popupRef.current?.focus()
        }
        const listener = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                reject()
                e.stopPropagation()
                e.preventDefault()
            }
        }
        window.addEventListener('keyup', listener)
        return () => window.removeEventListener('keyup', listener)
    }, [reject])
    const dismiss = useCallback(() => {
        if (dismissable) {
            reject()
        }
    }, [dismissable, reject])
    useOnClickOutside(popupRef, dismiss)
    const formRef = useRef<HTMLFormElement>()

    useEffect(() => {
        if (formRef.current) {
            const onSubmit = (e: any) => {
                e.preventDefault()
                const formData = new FormData(e.currentTarget as any)
                const values = Object.fromEntries(formData.entries())
                resolve(values)
            }

            formRef.current.addEventListener('submit', onSubmit)

            // eslint-disable-next-line react-hooks/exhaustive-deps
            return () => formRef.current?.removeEventListener('submit', onSubmit)
        }
    }, [resolve])

    const confirm = useCallback(() => {
        if (formRef.current) {
            formRef.current.requestSubmit()
        } else {
            resolve(selectedOption)
        }
    }, [resolve, selectedOption])

    const ThemeWrapper = forceDefaultTheme ? ThemeProviderContainer : React.Fragment

    return (
        <ThemeWrapper>
            <PopupBackdrop>
                <PopupContainer
                    width={width || 480}
                    tabIndex={-1}
                    ref={popupRef}
                    role="dialog"
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-content"
                    style={containerStyles}
                >
                    {header}
                    <HeaderContainer withCloseButton={dismissable && !hideDismissButton} id="dialog-title">
                        {title}
                    </HeaderContainer>
                    {text && <ContentBlock id="dialog-content">{text}</ContentBlock>}
                    {render && <ContentBlock id="dialog-content">{render(resolve, reject)}</ContentBlock>}
                    {FormComponent && (
                        <ContentBlock id="dialog-content">
                            <FormComponent formRef={formRef} />
                        </ContentBlock>
                    )}
                    {options && (
                        <ContentBlock id="dialog-content" className="mtm">
                            {options.map((option) => (
                                <Flex key={option.value} justifyContent="flex-start" className="pbs">
                                    <label>
                                        <input
                                            id={option.value}
                                            name="confirmation-option"
                                            type="radio"
                                            value={option.value}
                                            checked={option.value === selectedOption}
                                            onChange={() => setSelectedOption(option.value)}
                                        />
                                        {option.text}
                                    </label>
                                </Flex>
                            ))}
                        </ContentBlock>
                    )}
                    {cancelButtonText || primaryButtonText ? (
                        <FooterContainer wrap={wrapFooter}>
                            {cancelButtonText &&
                                (cancelButtonComponent ? (
                                    React.createElement(cancelButtonComponent, {
                                        className: cancelButtonClass,
                                        onClick: () => reject(),
                                        children: cancelButtonText,
                                    })
                                ) : (
                                    <NeutralButton
                                        className={cancelButtonClass}
                                        onClick={() => reject()}
                                        style={cancelButtonStyle}
                                    >
                                        {cancelButtonText}
                                    </NeutralButton>
                                ))}
                            {primaryButtonComponent ? (
                                React.createElement(primaryButtonComponent, {
                                    className: primaryButtonClass,
                                    onClick: confirm,
                                    children: primaryButtonText,
                                    disabled: primaryButtonDisabled,
                                })
                            ) : (
                                <PrimaryButton
                                    className={primaryButtonClass}
                                    onClick={confirm}
                                    disabled={primaryButtonDisabled}
                                >
                                    {primaryButtonText}
                                </PrimaryButton>
                            )}
                        </FooterContainer>
                    ) : null}
                    {dismissable && !hideDismissButton ? (
                        <DismissButtonContainer>
                            <NeutralIconButton
                                aria-label={t('Close')}
                                className="medium"
                                type="button"
                                onClick={dismiss}
                                icon={faTimes}
                            />
                        </DismissButtonContainer>
                    ) : null}
                </PopupContainer>
            </PopupBackdrop>
        </ThemeWrapper>
    )
}
