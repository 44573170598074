import { FC } from 'react'
import { BusinessPublicDto } from '../../bookingpage/types/business'
import { OccurrencePublicDto } from '../../store/events/types'
import { BookingPageWithStickyFooter } from './components/BookingPageLayout'
import { ServiceSelector } from './ServiceSelector'
import { GroupServicesTimetable } from './timetable-view/GroupServicesTimetable'
import { BookingPageNavBarServiceType } from './BookingPageServicesView'
import { EventSelector } from './events/EventSelector'

export const BookingPageServicesViewInMobile: FC<{
    business: BusinessPublicDto
    events: OccurrencePublicDto[]
    tab: BookingPageNavBarServiceType
    search: string
    timetableView: boolean
}> = ({ business, events, tab, search, timetableView }) => {
    return (
        <>
            {tab === 'group-services' ? (
                <>
                    {timetableView ? (
                        <GroupServicesTimetable business={business} events={events} hideTitle />
                    ) : (
                        <EventSelector business={business} events={events} hideTitle search={search} />
                    )}
                </>
            ) : (
                <BookingPageWithStickyFooter noHeight>
                    <ServiceSelector hideTitle business={business} search={search} />
                </BookingPageWithStickyFooter>
            )}
        </>
    )
}
